<template>
  <div class="mt-3 summary-by-time">
    <table cellspacing="0" cellpadding="0" class="summary-by-time-table">
      <tr>
        <th class="header-date">Date</th>
        <th class="header-time">Time</th>
        <th
          v-for="(vehicleGroup, vehicleGroupIndex) in tripVehicleGroups"
          :key="vehicleGroupIndex"
          class="header-vehicle"
        >
          {{ vehicleGroup.name || 'All Vehicle Groups' }}
        </th>
      </tr>
      <tr v-if="Object.keys(summaryByTime).length === 0">
        <td colspan="100%" class="no-stops-description pt-2 pb-2">
          No stops added
        </td>
      </tr>
      <tr
        v-for="(dateTime, dateTimeIndex) in getSortedDateTimes(
          Object.keys(summaryByTime)
        )"
        :key="dateTimeIndex"
        :style="`background-color: ${
          dateTimeIndex % 2 === 0 ? $cr.theme.grayLight : 'white'
        }`"
      >
        <td>{{ getDate(dateTime) }}</td>
        <td>{{ getTime(dateTime) }}</td>
        <td
          v-for="(vehicleGroup, vehicleGroupIndex) in tripVehicleGroups"
          :key="vehicleGroupIndex"
        >
          {{
            `${
              isFinalDropoffDate(vehicleGroup, dateTime) ? 'Final DO: ' : ''
            }${getAddressForHash(
              summaryByTime[dateTime][vehicleGroup.tripVehicleGroupHash]
            )}`
          }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import {
  yearMonthDayToMonthDayYear,
  twentyFourHourToTwelveHourTime,
} from '@/utils/time'

export default {
  props: {
    tripAddresses: { type: Array, default: () => [] },
    tripVehicleGroups: { type: Array, default: () => [] },
  },
  computed: {
    summaryByTime() {
      return this.$store.getters['quoteForm/getSummaryByTime']
    },
  },
  methods: {
    getSortedDateTimes(arr) {
      return arr.sort((a, b) => {
        return a.localeCompare(b)
      })
    },
    getAddressForHash(addressHash) {
      if (addressHash) {
        const tripAddress = this.tripAddresses.find(
          (tripAddress) => tripAddress.tripAddressHash === addressHash
        )
        return (
          tripAddress?.address?.title ||
          tripAddress?.address?.addressName ||
          `Address #${tripAddress.orderIndex + 1}`
        )
      } else {
        return '--'
      }
    },
    getDate(dateTime) {
      return yearMonthDayToMonthDayYear(dateTime?.split('T')?.[0])
    },
    getTime(dateTime) {
      return twentyFourHourToTwelveHourTime(dateTime?.split('T')?.[1])
    },
    isFinalDropoffDate(tripVehicleGroup, dateTime) {
      return (
        tripVehicleGroup?.finalDropoffDate?.date ===
          dateTime?.split('T')?.[0] &&
        tripVehicleGroup?.finalDropoffDate?.time ===
          dateTime?.split('T')?.[1]?.substring(0, 5)
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-by-time {
  overflow-x: scroll;
}
.summary-by-time-table {
  width: 100%;
}
.no-stops-description {
  font-size: 13px;
}
td {
  text-align: center;
}
th {
  font-weight: normal;
  padding: 6px 0px;
  &.header-date,
  &.header-time {
    min-width: 150px;
  }
  &.header-vehicle {
    min-width: 175px;
  }
}
</style>
