import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Charge, Lead } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCharges(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Charge>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/charges?${query}`
    return httpService.get(url)
  },
  addReservationCharges(params: Charge[]): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charge/addReservationCharges`

    return httpService.post(url, params)
  },
  addReferralCharges(params: Charge[]): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charge/addReferralCharges`

    return httpService.post(url, params)
  },
  reduceReservationCharges(params: Charge[]): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charge/reduceReservationCharges`

    return httpService.post(url, params)
  },
  reduceReferralCharges(params: Charge[]): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charge/reduceReferralCharges`

    return httpService.post(url, params)
  },
  changeChargeDueDate(params: Charge): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charge/changeDueDate`

    return httpService.post(url, params)
  },
  addChargeForConversion(params: Charge): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charge/addReservationChargeForConversion`

    return httpService.post(url, {
      ...params,
    })
  },
  addChargeForReferralCreation(params: Charge): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/charge/addReferralChargeOnReferralCreation`

    return httpService.post(url, {
      ...params,
    })
  },
}
