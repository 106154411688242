<template>
  <v-layout padded wrap>
    <v-flex md12 lg6>
      <v-layout padded>
        <v-flex xs6>
          <div class="mb-1">
            <label>Trip Type</label>
          </div>
          <v-select
            id="quote-form-trip-type-selector"
            flat
            solo
            append-icon="keyboard_arrow_down"
            placeholder="e.g. Round Trip, One Way, etc."
            browser-autocomplete="off"
            :items="tripTypes"
            item-text="label"
            item-value="id"
            :disabled="isModeView"
            :value="tripTypeId"
            @change="setTripTypeId($event)"
          />
        </v-flex>
        <v-flex xs3>
          <CRInput
            :id="`quote-form-passenger-count`"
            v-model="passengerCount"
            label="Passengers"
            type="number"
            min="1"
            placeholder="e.g. 10"
            browser-autocomplete="off"
            :disabled="isModeView"
            @input="setPassengerCount($event)"
          />
        </v-flex>
        <v-flex xs3>
          <CRInput
            :id="`quote-form-required-drivers`"
            v-model="requiredDrivers"
            label="Drivers"
            type="number"
            min="1"
            placeholder="e.g. 1"
            browser-autocomplete="off"
            :disabled="isModeView"
            @input="setRequiredDrivers($event)"
          />
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex v-if="!requiredVehicles.length && !isModeView" align-self-center>
      <p class="mb-1">
        <a
          id="quote-form-required-vehicle-add-button"
          @click="openTripVehicleSidebar"
        >
          + Add Vehicles to First Group
        </a>
      </p>
    </v-flex>
    <v-flex md12 lg6>
      <div
        v-for="(vehicle, vehicleIndex) in requiredVehicles"
        :key="`quote-form-vehicle-type-row-${vehicleIndex}`"
      >
        <v-layout v-if="vehicle.quantity" justify-space-between padded>
          <v-flex xs10>
            <div class="mb-1">
              <label>Vehicle Type</label>
            </div>
            <v-select
              :id="`quote-form-required-vehicle-type-selector-${vehicleIndex}`"
              flat
              solo
              :value="vehicle.vehicleTypeId"
              :items="vehicleTypes"
              append-icon=""
              item-value="id"
              item-text="label"
              disabled
            />
          </v-flex>
          <v-flex xs3 pl-2>
            <CRInput
              :id="`quote-form-required-vehicle-quantity-${vehicleIndex}`"
              :value="vehicle.quantity"
              label="Vehicles"
              type="number"
              min="1"
              placeholder="e.g. 1"
              tabindex="0"
              disabled
              browser-autocomplete="off"
            />
          </v-flex>
          <div>&nbsp;</div>
        </v-layout>
      </div>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
export default {
  name: 'QuoteFormTripVehicleSelector',
  props: {},
  data() {
    return { tripTypes: [] }
  },
  computed: {
    mode() {
      return this.$store.getters['quoteForm/getMode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    vehicleTypes() {
      return this.$store.getters['types/vehicleTypes']
    },
    trip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    currentTripIndex() {
      return this.$store.getters['quoteForm/getCurrentTripIndex']
    },
    tripTypeId() {
      return this.trip?.tripTypeId || null
    },
    passengerCount() {
      return this.trip?.passengerCount || null
    },
    requiredDrivers() {
      return this.trip?.requiredDrivers || null
    },
    requiredVehicles() {
      return this.trip?.requiredVehicles || []
    },
  },
  async mounted() {
    const tripTypes = await this.$store
      .dispatch('types/getTripTypes')
      .then((res) => res.data)
    this.tripTypes = tripTypes
  },
  methods: {
    ...mapActions({
      setTripTypeIdModule: 'quoteForm/setTripTypeId',
      setPassengerCountModule: 'quoteForm/setPassengerCount',
      setRequiredDriversModule: 'quoteForm/setRequiredDrivers',
    }),
    setTripTypeId(tripTypeId, tripIndex = this.currentTripIndex) {
      this.setTripTypeIdModule({ tripIndex, tripTypeId })
      EventBus.$emit('get-estimations')
    },
    setPassengerCount(passengerCount, tripIndex = this.currentTripIndex) {
      this.setPassengerCountModule({
        tripIndex,
        passengerCount: parseInt(passengerCount),
      })
      EventBus.$emit('get-estimations')
    },
    setRequiredDrivers(requiredDrivers, tripIndex = this.currentTripIndex) {
      this.setRequiredDriversModule({
        tripIndex,
        requiredDrivers: parseInt(requiredDrivers),
      })
      EventBus.$emit('get-estimations')
    },
    openTripVehicleSidebar() {
      const component = () => import('@/components/TripVehicleSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Edit Group',
          mode: 'edit',
          tripVehicleGroupIndex: 0,
        },
        component,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-vehicle-icon {
  margin-top: 26px !important;
  color: $gray-mid-light !important;

  &:hover {
    color: $error !important;
  }
}
</style>
