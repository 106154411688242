import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { LeadSource } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getLeadSources(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<LeadSource>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    const host = baseUrl()
    let query = `page=${page}&pageSize=${pageSize}`

    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    return httpService.get(`https://${host}/tables/lead-sources?${query}`)
  },
  getLeadSource(leadSourceId: number): Promise<AxiosResponse<LeadSource>>  {
    const host = baseUrl()
    const url = `https://${host}/leadSources/${leadSourceId}`

    return httpService.get(url)
  },
  updateLeadSource(params: { id: number, payload: LeadSource }): Promise<AxiosResponse>  {
    const host = baseUrl()
    const url = `https://${host}/leadSources/${params.id}`

    return httpService.patch(url, params.payload)
  },
  createLeadSource(params: { id: number, payload: LeadSource }): Promise<AxiosResponse<LeadSource>>  {
    const host = baseUrl()
    const url = `https://${host}/leadSources`

    return httpService.post(url, params.payload)
  },
  deleteLeadSource(leadSourceId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/leadSources/${leadSourceId}`

    return httpService.delete(url)
  },
  getLeadSourceFromPhone(phone: string): Promise<AxiosResponse<LeadSource>> {
    const host = baseUrl()
    const url = `https://${host}/leadSources/phone/${phone}`

    return httpService.get(url)
  },
}
