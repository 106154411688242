<template>
  <v-dialog v-model="dialog" :fullscreen="$cr.breakpoint.xs" max-width="600">
    <template #activator="{ on }">
      <v-btn
        id="quotes-automated-pricing-table-pricing-logs-button-open"
        :disabled="!pricingLogs || pricingLogs.length === 0"
        block
        color="primary"
        class="mt-2"
        v-on="on"
      >
        Pricing Logs
      </v-btn>
    </template>
    <v-card class="cr-modal">
      <v-card-title>
        <v-layout row align-center>
          <v-flex grow>
            <h2>Pricing Logs</h2>
          </v-flex>
          <v-flex shrink>
            <v-btn
              id="quotes-automated-pricing-table-pricing-logs-button-close"
              icon
              @click="dialog = false"
            >
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text class="card-content">
        <template v-for="(log, index) in pricingLogs">
          <div v-if="log" :key="index" style="border-bottom: 1px solid gray">
            <v-list-tile-content>
              {{ log }}
            </v-list-tile-content>
          </div>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    pricingLogs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialog: false,
      headers: {},
    }
  },
}
</script>

<style lang="scss" scoped>
.card-content {
  height: 400px;
  overflow: scroll;
}
</style>
