<template>
  <v-layout
    row
    wrap
    align-center
    class="container pt-0 pl-0 pr-0"
    style="align-items: flex-start"
  >
    <v-layout
      v-for="(time, timeIndex) in times"
      :key="`pickup-times-${timeIndex}`"
      column
      style="flex-grow: 0"
    >
      <div class="mb-1" style="height: 21px">
        {{ timeIndex === 0 ? 'Pickup' : '' }}
      </div>
      <v-layout
        class="time-label pl-1 pr-1 pt-2 pb-2 mb-2 mr-2"
        :style="cssVars"
        row
        @mouseover="showCloseByIndex = timeIndex"
        @mouseout="showCloseByIndex = null"
      >
        <div class="ml-3">
          {{ formatTime(time) }}
        </div>
        <v-flex class="delete-button-container d-flex justify-center">
          <div
            v-show="showCloseByIndex === timeIndex && !isModeView"
            class="delete-button"
            @click="onRemoveTime(timeIndex)"
          >
            X
          </div>
        </v-flex>
      </v-layout>
    </v-layout>
    <v-layout v-if="!isModeView" row wrap class="add-time-component">
      <div
        v-if="!intervalView"
        class="d-flex mt-4"
        style="align-items: center; flex-wrap: wrap"
      >
        <CRInput
          v-model="newTime"
          class="time-input mb-2 mr-2"
          hide-details
          type="time"
        />
        <div class="d-flex" style="align-items: center">
          <CRButton
            color="secondary"
            text-color="primary"
            class="mr-2"
            style="margin-bottom: 10px"
            @click="onAddTime"
          >
            Add Time
          </CRButton>
          <v-menu
            v-if="!isModeView"
            bottom
            offset-y
            transition="slide-x-transition"
          >
            <template #activator="{ on: on }">
              <div class="d-flex cursor-pointer" v-on="on">
                <CRIcon
                  id="more-actions-vert"
                  class="mb-2"
                  color="primary"
                  :view-box="'0 0 24 24'"
                >
                  more_vert
                </CRIcon>
              </div>
            </template>
            <v-list class="list">
              <v-list-tile id="add-interval-times" class="cursor-pointer">
                <v-list-tile-title @click="enableIntervalView">
                  <span>Add Interval Times</span>
                </v-list-tile-title>
              </v-list-tile>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div v-else class="d-flex no-grow" style="flex-wrap: wrap">
        <CRInput
          v-model="startTime"
          label="Start Time"
          class="time-input mb-2 mr-2"
          hide-details
          type="time"
        />
        <CRInput
          v-model="timeInterval"
          label="Interval (minutes)"
          type="number"
          min="1"
          placeholder="e.g. 1"
          browser-autocomplete="off"
          class="mr-2 w-120"
          hide-details
        />
        <CRInput
          v-model="endTime"
          label="End Time"
          class="time-input mb-2 mr-2"
          hide-details
          type="time"
        />
        <div class="d-flex no-grow" style="align-items: center">
          <CRButton
            color="secondary"
            text-color="primary"
            class="mr-2 margin-t-4"
            @click="addIntervalTimes"
          >
            Add Time(s)
          </CRButton>
          <CRIcon
            id="close-interval-view"
            class="mb-2"
            style="margin-top: 25px"
            color="primary"
            :view-box="'0 0 24 24'"
            @click="disableIntervalView"
          >
            close
          </CRIcon>
        </div>
      </div>
    </v-layout>
  </v-layout>
</template>

<script>
import CRColor from '@/cr/color/CRColor'
import { twentyFourHourToTwelveHourTime } from '@/utils/time'
import { DateTime } from 'luxon'

export default {
  props: {
    times: { type: Array, default: () => {} },
  },
  data() {
    return {
      newTime: '',
      showCloseByIndex: null,
      intervalView: false,
      startTime: '',
      endTime: '',
      timeInterval: null,
    }
  },
  computed: {
    mode() {
      return this.$store.getters['quoteForm/getMode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    cssVars() {
      return {
        '--color': this.color,
        '--background-color': this.backgroundColor,
        '--cursor': this.isModeView ? 'default' : 'pointer',
      }
    },
    backgroundColor() {
      return new CRColor(this.color).lighten(30)
    },
    color() {
      return this.$store.getters['quoteForm/getCurrentColor']
    },
  },
  mounted() {},
  methods: {
    enableIntervalView() {
      this.intervalView = true
    },
    disableIntervalView() {
      this.intervalView = false
    },
    formatTime(time) {
      return twentyFourHourToTwelveHourTime(time)
    },
    onAddTime() {
      if (!this.newTime) {
        return
      }
      if (this.times.includes(this.newTime)) {
        this.newTime = ''
        return
      }
      this.$emit('on-add-time', this.newTime)
      this.newTime = ''
    },
    addIntervalTimes() {
      if (!this.startTime || !this.endTime || !this.timeInterval) {
        return
      }
      const startTime = new DateTime.local().set({
        hour: this.startTime.split(':')[0],
        minute: this.startTime.split(':')[1],
      })
      const endTime = new DateTime.local().set({
        hour: this.endTime.split(':')[0],
        minute: this.endTime.split(':')[1],
      })

      const difference = Math.round(
        endTime.diff(startTime, 'minutes').toObject().minutes
      )
      const timeInterval = parseInt(this.timeInterval)
      if (!difference || difference <= 0) {
        return
      }
      for (let i = 0; i <= difference; i += timeInterval) {
        const newTime = startTime.plus({ minutes: i }).toFormat('HH:mm')
        if (this.times.includes(newTime)) {
          continue
        }
        this.$emit('on-add-time', newTime)
      }
      this.startTime = ''
      this.endTime = ''
      this.timeInterval = null
    },
    onRemoveTime(timeIndex) {
      this.$emit('on-remove-time', timeIndex)
      this.showCloseByIndex = null
    },
  },
}
</script>

<style lang="scss" scoped>
.time-label {
  background: var(--background-color);
  border: 1px var(--color) solid;
  border-radius: 5px;
  max-width: 105px;
  min-width: 105px;
  color: $gray-dark;
  font-size: 15px;

  &:hover {
    cursor: var(--cursor);
  }
}
.delete-button-container {
  max-width: 20px;
}
.delete-button {
  max-width: 10px;
  height: fit-content;
  cursor: pointer;
  color: $gray-medium-dark;
}
.time-input {
  width: 120px;
}
.add-time-component {
  align-items: center;
}
::v-deep .v-text-field {
  .v-input__control {
    min-height: 40px;
  }
}
</style>
