<template>
  <div>
    <div class="market-pricing-title">Please select a price for Base Fare</div>
    <v-data-table
      id="pricing-table"
      :headers="headers"
      :items="items"
      hide-actions
      class="pricing"
    >
      <template #headers="heads">
        <th
          v-for="(headerItem, headerIndex) in heads.headers"
          :key="headerIndex"
          role="columnheader"
          scope="col"
          :class="headerItem.class"
        >
          <div>{{ headerItem.text1 }}</div>
          <div>{{ headerItem.text2 }}</div>
        </th>
      </template>
      <template #items="props">
        <td align="center">
          <div class="flex-center">
            <v-btn
              :id="`quote-pricing-table-tripindex-${tripIndex}-select-${
                props.item.pricingMethod
              }-option-${props.item.present ? 'rate-present' : 'rate-absent'}`"
              color="primary"
              outlined
              :disabled="!props.item.present"
              @click="
                (evt) =>
                  suggestPrice(props.item.rateValue, props.item.pricingMethod)
              "
            >
              Select
            </v-btn>
            <div class="to-the-right">
              {{ props.item.type }}
            </div>
          </div>
        </td>

        <template v-if="props.item.present">
          <td
            v-for="(rate, rateIndex) in props.item.rates"
            :key="rateIndex"
            align="center"
            :class="rate.class"
          >
            <div
              :style="`display: flex; ${
                Array.isArray(rate.tooltip) && rate.tooltip.length > 0
                  ? 'justify-content: flex-end; width: 80px;'
                  : 'justify-content: center;'
              }`"
            >
              <div
                :id="`quote-pricing-table-tripindex-${tripIndex}-price-${props.item.pricingMethod}`"
                :style="rate.autoselect ? 'font-weight: bold;' : ''"
              >
                {{ currencyFilter(rate.price) }}
              </div>
              <v-tooltip
                v-if="Array.isArray(rate.tooltip) && rate.tooltip.length > 0"
                top
              >
                <template #activator="{ on }">
                  <v-icon
                    :id="`markup-tool-tip-icon-${rateIndex}`"
                    class="markup-tool-tip"
                    v-on="on"
                  >
                    info
                  </v-icon>
                </template>
                <div style="display: flex; flex-direction: column">
                  <div
                    v-for="(markup, i) in rate.tooltip"
                    :id="`markup-tool-tip-tooltip-${i}`"
                    :key="i"
                  >
                    {{ `${markup.name} - ${markup.markupPercentage}%` }}
                  </div>
                </div>
              </v-tooltip>
            </div>
          </td>
        </template>
        <td v-else>No Data Available. Select Pricing Method.</td>
      </template>
    </v-data-table>
    <QuotesAutomatedPricingTableLogModal
      v-if="showPricingLogs"
      :pricing-logs="pricingLogs"
    />
  </div>
</template>
<script>
import { deepClone } from '@/utils/deepClone'
import { mapActions, mapGetters } from 'vuex'
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import { scrubQuoteForAPI, shouldAutoPriceQuote } from '@/utils/quoteUtils'

import QuotesAutomatedPricingTableLogModal from '@/components/QuotesAutomatedPricingTablePricingLogModal.vue'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    QuotesAutomatedPricingTableLogModal,
  },
  props: {
    tripIndex: {
      type: Number,
      default: 0,
    },
    toggleSelected: {
      type: Function,
      default: null,
    },
    quote: {
      type: Object,
      default: () => ({}),
    },
    setFare: {
      type: Function,
      default: null,
    },
    clearPricingTable: {
      type: Boolean,
    },
    useTable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers: [],
      items: [],
      pricingDebounce: null,
      currentMarket: null,
      currentGarage: null,
      pricingLogs: [],
      pricingData: [],
      isAdmin: false,
      isDevAndProviderAdmin: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      marketStoreChanges: 'quotes/getSelectedMarketChanges',
      garageStoreChanges: 'quotes/getGarageChanges',
      recalculatePricing: 'quotes/getShouldRecalculatePricing',
      isShuttleCreateQuoteEnabled: 'featureToggles/isShuttleCreateQuoteEnabled',
    }),
    showPricingLogs() {
      const roles = this.currentUserProfile?.roles || []
      return roles.find((r) => r.roleName === 'can_view_pricing_logs')
    },
  },
  watch: {
    quote: {
      deep: true,
      handler: function () {
        this.getPricing()
      },
    },
    marketStoreChanges: {
      deep: true,
      handler: function () {
        if (!this.selected) {
          this.currentMarket = this.getSelectedMarket()(this.tripIndex)
          this.getPricing()
          return
        }

        if (
          this.getSelectedMarket()(this.tripIndex) &&
          this.getSelectedMarket()(this.tripIndex).marketId !==
            this.currentMarket.marketId
        ) {
          this.currentMarket = this.getSelectedMarket()(this.tripIndex)
          this.getPricing()
        }
      },
    },
    garageStoreChanges: {
      deep: true,
      handler: function () {
        if (!this.selected) {
          this.currentGarage = this.getSelectedGarage()(this.tripIndex)
          this.getPricing()
          return
        }

        if (
          this.getSelectedGarage()(this.tripIndex) &&
          this.getSelectedGarage()(this.tripIndex).garageId !==
            this.currentGarage.garageId
        ) {
          this.currentGarage = this.getSelectedGarage()(this.tripIndex)
          this.getPricing()
        }
      },
    },
    clearPricingTable(clearPricingTable) {
      if (this.clearPricingTable === true) {
        this.items = []
        this.$emit('pricing-table-cleared')
      }
    },
    pricingData() {
      this.$emit('pricing-data', this.pricingData)
    },
    recalculatePricing() {
      this.getPricing()
    },
  },
  async mounted() {
    this.getPricing()
    const roles = this.currentUserProfile.roles
    const host = window.location.host
    this.isDevAndProviderAdmin =
      (host.includes('localhost') || host.includes('dev')) &&
      !!roles.find((role) => role.roleName === 'is_paid_admin')
    this.isAdmin = !!roles.find((role) => role.roleName === 'is_broker_admin')
  },
  methods: {
    currencyFilter,
    ...mapActions({
      getQuotePricing: 'quotes/getQuotePricing',
      setShouldSplitTrip: 'quotes/setShouldSplitTrip',
    }),
    ...mapGetters({
      getSelectedMarket: 'quotes/getSelectedMarket',
      getIsManuallySelectedPricingMarket:
        'quotes/getIsManuallySelectedPricingMarket',
      getSelectedGarage: 'quotes/getSelectedGarage',
    }),
    async getPricing() {
      if (!this.useTable || !shouldAutoPriceQuote(this.quote)) {
        return
      }
      if (typeof this.pricingDebounce !== 'undefined') {
        clearTimeout(this.pricingDebounce)
      }

      this.pricingDebounce = setTimeout(async () => {
        this.toggleSelected(false)

        let quoteClone = deepClone(this.quote)
        quoteClone = scrubQuoteForAPI(quoteClone, true)
        quoteClone.pricingMarket = this.getSelectedMarket()(
          this.tripIndex
        )?.marketId

        //When viewing or editing the quote,
        //don't override existing trip pricing markets
        quoteClone.trips.forEach((trip) => {
          if (!!trip.active) {
            trip.isManuallySelectedPricingMarket =
          ['view', 'edit'].includes(this.mode) || trip.isManuallySelectedPricingMarket
          }
        })

        quoteClone.pricingGarageId = this.getSelectedGarage()(
          this.tripIndex
        )?.garageId
        const {
          pricings: pricingData,
          pricingLogs,
          quoteShouldBeSplitIntoMultipleTrips,
        } = await this.getQuotePricing(quoteClone)
          .then((data) => data.data)
          .catch((e) => e)
        const splitTrips = !!quoteShouldBeSplitIntoMultipleTrips
        const vehicleNeededEntireTrip = this.quote.trips[this.tripIndex]
          .vehicleNeededEntireTrip
        this.setShouldSplitTrip(splitTrips && !vehicleNeededEntireTrip)
        this.pricingLogs = pricingLogs
        this.pricingData = pricingData
        this.generateTable(pricingData)
        this.$forceUpdate()
      }, 1000)
    },
    suggestPrice(price, pricingMethod) {
      this.quote.trips[this.tripIndex].pricingMethod = pricingMethod
      if (this.isShuttleCreateQuoteEnabled) {
        EventBus.$emit('set-pricing-method', pricingMethod)
      }
      this.toggleSelected(true)
      this.setFare(price ? price.toFixed(2) : null, this.tripIndex)
    },
    async generateTable(pricingData) {
      const typeMap = {
        transfer: 'Transfer',
        hourly_rate: 'Hourly',
        mileage: 'Mileage',
        daily_rate: 'Daily',
      }

      if (!Array.isArray(pricingData) || pricingData.length === 0) {
        this.items = []
        this.headers = [
          {
            text1: 'Pricing',
            text2: 'Type',
          },
          {},
        ]

        for (const prop in typeMap) {
          this.items.push({
            type: typeMap[prop],
            pricingMethod: prop,
            rateValue: null,
            present: false,
          })
        }

        return
      }

      const onlyCurrentIndexPricingData = pricingData.filter(
        (pricing) => pricing.tripId === this.tripIndex && pricing.pricingMethod
      )

      const reduceToRelevantTripTable = onlyCurrentIndexPricingData.reduce(
        (acc, rate) => {
          if (!acc[rate.pricingMethod]) {
            acc[rate.pricingMethod] = {
              type: typeMap[rate.pricingMethod],
              pricingMethod: rate.pricingMethod,
              rates: [],
              rateValue: null,
              present: true,
            }
          }

          if (rate.companyId === this.quote.leadSource?.partnerCompanyId) {
            acc[rate.pricingMethod].rateValue = rate.priceHigh
          }

          acc[rate.pricingMethod].rates.push({
            price: rate.priceHigh,
            company: rate.companyName,
            id: rate.companyId,
            pricingMethod: rate.pricingMethod,
            autoselect: rate.autoSelected,
          })

          acc[rate.pricingMethod].rates.sort((a, b) => {
            let valA = 0
            let valB = 0
            if (a.id === this.quote.leadSource?.partnerCompanyId) {
              valA = 1
            }
            if (b.id === this.quote.leadSource?.partnerCompanyId) {
              valB = 1
            }

            return valB - valA
          })

          return acc
        },
        {}
      )

      this.items = []
      this.headers = []

      for (const prop in reduceToRelevantTripTable) {
        this.items.push(reduceToRelevantTripTable[prop])
      }

      this.headers = [
        {
          text1: 'Pricing',
          text2: 'Method',
        },
      ]

      for (const prop in reduceToRelevantTripTable) {
        reduceToRelevantTripTable[prop].rates.forEach((rate, idx) => {
          if (idx === 0) {
            this.headers.push({
              text1: 'Lead Source',
              text2: rate.company,
            })
          } else {
            this.headers.push({
              text1: 'Automatic Bid',
              text2: rate.company,
            })
          }
        })
        break
      }

      this.headers.push(
        {
          text1: 'Base Fare',
        },
        {
          text1: 'Markup',
        },
        {
          text1: 'Total',
          class: 'total-header',
        }
      )

      if (this.items.length === 0) {
        this.headers = [
          {
            text1: 'Pricing',
            text2: 'Type',
          },
          {},
        ]
        for (const prop in typeMap) {
          this.items.push({
            type: typeMap[prop],
            pricingMethod: prop,
            rateValue: null,
            present: false,
          })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.total-header {
  background-color: $gray-lighter;
  color: $gray !important;
  font-weight: bold;
  font-size: 14px;
}

.total-cell {
  background-color: $gray-lighter;
  color: $blue;
}

.markup-tool-tip {
  left: 5px;
  position: relative;
  bottom: 3px;
}

.market-pricing-title {
  margin-bottom: 40px;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.35em;
  letter-spacing: normal;
}

.pricing {
  border: 2px solid $border-gray;
  border-radius: 2px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.to-the-right {
  min-width: 100px;
  max-width: 100px;
  margin-left: 10px;
  line-height: 3.4;
}

.done-check {
  margin-right: 5px;
  font-size: 24px;
  line-height: 0.7;
  color: $blue;
}

::v-deep.pricing.v-data-table {
  font-size: 16px;

  &__wrapper {
    border-color: $border-gray;
    border-style: solid;
    border-width: 1px;
    border-radius: 5px;
  }

  .v-data-footer {
    border-top: none;
  }

  table {
    thead {
      th {
        font-size: 16px;
        font-weight: normal;
        color: $gray !important;
      }

      tr {
        &:last-child th {
          border-bottom: thin solid $border-gray;
        }
      }
    }

    tbody {
      tr {
        border-top-color: $border-gray;
        border-right-color: $border-gray;
        border-bottom-color: $border-gray;
        border-left-color: $border-gray;

        &:hover {
          background-color: $white;
        }

        td {
          font-size: 16px;
        }

        &:not(:last-child) td:not(.v-data-table__mobile-row) {
          border-bottom: thin solid $border-gray;
        }
      }

      .v-data-table__mobile-row__header {
        color: $gray;
      }
    }
  }

  .v-data-table__selected {
    background-color: $blue-pale !important;
  }

  .v-data-footer__select {
    visibility: hidden;
  }

  .v-data-table-header {
    background-color: $header-gray;
  }

  .header-box {
    font-family: 'Airbnb Cereal App Medium', sans-serif;
    color: $gray !important;
  }

  .v-datatable__progress {
    visibility: hidden;
  }

  .padding-left-40 {
    padding-left: 44px;
  }
}

.pricing.provider-table ::v-deep.theme--light.v-table tbody tr:not(:last-child),
.theme--light.v-table thead tr:first-child {
  border-bottom: none;
}

.pricing.provider-table ::v-deep.theme--light.v-table thead tr:first-child {
  td:not(:last-child) {
    border-bottom-style: 1px solid $gray-light;
  }
}

.pricing.provider-table
  ::v-deep.theme--light.v-table
  tbody
  tr:not(:first-child) {
  border-top: none;
  border-bottom: none;
  td:not(:last-child) {
    border-top: 1px solid $gray-light;
  }
  td:last-child {
    background-color: $gray-lighter;
  }
}
</style>
