
import { Vue, Prop, Component } from 'vue-property-decorator'
import { capitalize } from '@/utils/string'
import { PaymentGatewayTypeKey, CheckoutPageId } from '@/utils/enum'
import { CustomerPaymentProfile } from '@/models/dto/CustomerPaymentProfile'

@Component({})
export default class CreditCardSelectorCard extends Vue {
  @Prop({ type: Object, required: true }) card: CustomerPaymentProfile
  @Prop({ type: Boolean }) selected: boolean
  @Prop({ type: Boolean, default: true }) showGatewayType: boolean
  @Prop({ type: Boolean, default: false }) isCardDefaultSelected: boolean

  expanded = false
  capitalize = capitalize

  get paymentGatewayLabel(): string {
    const typeKey = this.card.companyPaymentGateway?.paymentGatewayTypeKey
    switch (typeKey) {
      case PaymentGatewayTypeKey.AuthorizeNet:
        return 'Authorize.net'
      case PaymentGatewayTypeKey.Finix:
        return 'Finix'
      default:
        return typeKey
    }
  }

  get merchantAccountName(): string {
    const checkoutPageId = this.card.companyPaymentGateway?.checkoutPageId
    switch (checkoutPageId) {
      case CheckoutPageId.GOGO:
        return 'GOGO Charters'
      case CheckoutPageId.National:
        return 'National Charter Bus'
      case CheckoutPageId.Shofur:
        return 'Shofur'
      default:
        return 'CharterUP'
    }
  }

  mounted(): void {
    if (this.isCardDefaultSelected) {
      this.$emit('card-selected')
    }
  }
}
