var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0 mt-2 mb-2",attrs:{"id":"shuttle-quote-form-stop-detail","fluid":""}},[_c('v-layout',{staticClass:"pt-2 pb-2 pl-3 pr-3 address-detail",attrs:{"xs12":"","row":"","wrap":""}},[_c('v-flex',{attrs:{"xs12":"","lg4":""}},[_c('v-layout',{attrs:{"column":""}},[_c('v-flex',{staticClass:"mr-2",attrs:{"xs12":""}},[_c('div',{staticStyle:{"overflow":"hidden","white-space":"nowrap","text-overflow":"ellipsis","padding-right":"30px"}},[_vm._v(" "+_vm._s(`Address #${_vm.addressIndex + 1} ${ _vm.tripAddress.address.title ? `- ${_vm.tripAddress.address.title}` : '' }`)+" ")]),_c('div',{staticClass:"d-flex align-center"},[(!_vm.tripAddress.address.addressName)?_c('div',[(_vm.isKnownAddressEditor)?_c('CRAddressSelectorV2',{staticClass:"mt-1",attrs:{"placeholder":"Type to search","disabled":_vm.isModeView,"legacy-border":""},on:{"change:place":function($event){return _vm.editAddress($event.place, $event.defaultAddressTitle)}}}):_c('CRAddressSelector',{staticClass:"mt-1",attrs:{"prepend-inner-icon":"location_on","placeholder":"Type to search","disabled":_vm.isModeView},on:{"place-selected":function($event){return _vm.editAddress($event.place, $event.defaultAddressTitle)}}})],1):_c('div',[_c('CRInput',{staticClass:"mt-1 no-grow w-full",attrs:{"value":_vm.tripAddress.address.addressName,"type":"text","clear-icon":"replay","clearable":!_vm.isModeView,"readonly":true,"disabled":_vm.isModeView,"prepend-inner-icon":"location_on"},on:{"click:clear":_vm.clearAddress}})],1),(
                !_vm.isModeView &&
                _vm.isKnownAddressEditor &&
                _vm.tripAddress &&
                _vm.tripAddress.address &&
                _vm.tripAddress.address.addressName
              )?_c('CRIcon',{key:`known-address-icon-${_vm.addressIndex}-${_vm.tripAddress.address.knownAddressId}`,staticClass:"max-w-24 ml-2 mb-4",attrs:{"color":"primary","view-box":"0 0 24 24"},nativeOn:{"click":function($event){return _vm.editKnownAddress.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.tripAddress.address.knownAddressId ? 'place_filled' : 'place_outline')+" ")]):_vm._e()],1)]),_c('v-flex',{staticClass:"pb-3",attrs:{"xs12":"","order-xs5":"","order-sm4":"","order-lg3":"","align-self-start":""}},[(
              !_vm.isModeView &&
              (!_vm.tripAddress.tripAddressNotes ||
                !_vm.tripAddress.tripAddressNotes[0] ||
                (!_vm.tripAddress.tripAddressNotes[0].html &&
                  !_vm.tripAddress.tripAddressNotes[0].note))
            )?_c('v-btn',{staticClass:"ml-0",attrs:{"color":"primary","flat":"","small":""},on:{"click":function($event){_vm.isSidebarActive = true}}},[_vm._v(" Add Notes ")]):_c('v-flex',{staticClass:"mt-2 ml-1",attrs:{"xs12":""}},[_c('p',{staticClass:"mb-1"},[_vm._v(" Notes "),(!_vm.isModeView)?_c('span',{staticClass:"edit-button pa-2",on:{"click":function($event){_vm.isSidebarActive = true}}},[_vm._v(" Edit ")]):_vm._e()]),_c('div',[(_vm.tripAddress.tripAddressNotes[0])?_c('p',{domProps:{"innerHTML":_vm._s(
                  _vm.tripAddress.tripAddressNotes[0].html ||
                  _vm.tripAddress.tripAddressNotes[0].note
                )}}):_vm._e()])])],1)],1)],1),_c('v-flex',{attrs:{"xs12":"","lg8":""}},[_c('v-layout',{attrs:{"column":""}},[_c('QuoteFormAddressDetailDateSelector',{attrs:{"trip-address":_vm.tripAddress}})],1)],1),_c('CRSidebarDialog',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',[_vm._v("Address Notes")])]},proxy:true}]),model:{value:(_vm.isSidebarActive),callback:function ($$v) {_vm.isSidebarActive=$$v},expression:"isSidebarActive"}},[(_vm.isSidebarActive)?_c('StopInfoSidebar',{key:`add-stop-note-sidebar-${_vm.addressIndex}`,attrs:{"active-stop":_vm.activeStop,"note-type":"address"},on:{"edit-address-note":function($event){return _vm.editAddressNote({
            addressIndex: _vm.addressIndex,
            note: $event,
          })},"close":function($event){_vm.isSidebarActive = false}}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }