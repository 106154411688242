var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.column.displayType === 'action-list')?_c('div',[_c('div',{key:`quotes-actionable-column-${_vm.row.reservationId}-${
        _vm.column.t_id ? _vm.column.t_id : ''
      }`},_vm._l((_vm.actionList),function(item,key){return _c('div',{key:`quotes-actionable-${key}-${_vm.row.reservationId}-`,attrs:{"id":`quotes-actionable-column-action-item-${key}-handler`}},[_c('a',{on:{"click":() => _vm.handleAction(_vm.action, _vm.rowProps, item)}},[_vm._v(" "+_vm._s(_vm.op(item, _vm.column.listProp))+" ")])])}),0)]):_vm._e(),(_vm.column.displayType === 'res-link')?_c('div',_vm._l((_vm.row.reservations),function(item,key){return _c('div',{key:`res-link-${key}-${_vm.row.quoteId ? _vm.row.quoteId : ''}`},[_c('router-link',{attrs:{"to":{ path: _vm.reservationViewPath(_vm.row) }}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),0):_vm._e(),(_vm.column.displayType === 'res-multi')?_c('div',_vm._l((_vm.row.reservationIds),function(item,key){return _c('div',{key:`res-multi-${key}-${_vm.row.quoteId ? _vm.row.quoteId : ''}`},[_c('router-link',{attrs:{"to":{ path: `/reservations/${item}` }}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),0):_vm._e(),(_vm.column.displayType === 'trip-type-multi')?_c('div',[_vm._v(" "+_vm._s(_vm.row.tripTypes)+" ")]):_vm._e(),(_vm.column.displayType === 'payment-type-multi')?_c('div',[_vm._v(" "+_vm._s(_vm.row.paymentTypes)+" ")]):_vm._e(),(_vm.column.displayType === 'payment-method-type-multi')?_c('div',[_vm._v(" "+_vm._s(_vm.row.paymentMethodTypes)+" ")]):_vm._e(),(_vm.column.displayType === 'clickable-link')?_c('div',[_c('div',{key:`clickable-link-${_vm.row.reservationId}`},[_c('router-link',{staticClass:"no-underline",attrs:{"target":"_blank","to":{
          name: 'quotes.view',
          params: {
            id: _vm.row.quoteId,
          },
        }},on:{"click":(event) => event.stopPropagation()}},[_vm._v(" "+_vm._s(_vm.row.quoteId)+" ")])],1)]):_vm._e(),(_vm.column.displayType === 'clickable-link-new')?_c('div',[_c('div',{key:`clickable-link-${_vm.row.reservationId}`},[_c('router-link',{staticClass:"no-underline",attrs:{"target":"_blank","to":{
          name: _vm.row.contractName ? 'contracts.view' : 'quotes.view',
          params: {
            id: _vm.row.quoteId,
          },
        }},on:{"click":(event) => event.stopPropagation()}},[_vm._v(" "+_vm._s(_vm.row.reservationId ? '' : _vm.row.managedId || _vm.row.quoteId)+" ")])],1)]):_vm._e(),(_vm.column.displayType === 'clickable-link-res-quote-id')?_c('div',[_c('div',{key:`clickable-link-${_vm.row.reservationId}`},[_c('router-link',{staticClass:"no-underline",attrs:{"target":"_blank","to":{
          name: _vm.row.contractId ? 'contracts.view' : 'quotes.view',
          params: {
            id: _vm.row.quoteId,
          },
        }},on:{"click":(event) => event.stopPropagation()}},[_vm._v(" "+_vm._s(_vm.row.reservationId ? '' : _vm.row.managedId || _vm.row.quoteId)+" ")])],1)]):_vm._e(),(_vm.column.displayType === 'action-item')?_c('div',[_c('div',{key:`action-item-${_vm.row.reservationId}-${
        _vm.row.quoteId ? _vm.row.quoteId : ''
      }`},[_c('a',{on:{"click":() => _vm.handleAction(_vm.action, _vm.rowProps, undefined)}},[_vm._v(" "+_vm._s(_vm.columnDisplay(_vm.row, _vm.column))+" ")])])]):_vm._e(),(_vm.column.displayType === 'action-item-black')?_c('div',[_c('div',{key:`action-item-${_vm.row.reservationId}-${
        _vm.row.quoteId ? _vm.row.quoteId : ''
      }`},[_c('div',{staticClass:"action-item-black",on:{"click":() => _vm.handleAction(_vm.action, _vm.rowProps, undefined)}},[_vm._v(" "+_vm._s(_vm.columnDisplay(_vm.row, _vm.column))+" ")])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }