<template>
  <div>
    <v-form ref="trip-recurrence-input-form">
      <v-checkbox
        id="quote-form-trip-recurrences-activator"
        v-model="areRecurrencesActive"
        background-color="white"
        solo
        :disabled="disabled"
        color="primary"
        label="Turn on recurrence for this trip"
        @change="updateValue"
      />
      <v-layout
        v-for="(recurrence, recurrenceIndex) in recurrences"
        :key="`recurrence-input-${recurrenceIndex}`"
        align-center
      >
        <v-flex shrink class="recurrance-form-field">
          <CRInput
            :id="`recurrences-${recurrenceIndex}-form-start-date`"
            :value="
              originalRecurrenceEvent.startDatetime
                ? DateTime.fromISO(
                    originalRecurrenceEvent.startDatetime
                  ).toISODate()
                : null
            "
            type="date"
            label="Starts On"
            disabled
            @change="updateValue"
          />
        </v-flex>

        <v-flex shrink class="recurrance-form-field">
          <CRInput
            :id="`recurrences-${recurrenceIndex}-form-end-date`"
            v-model="recurrence.endDate"
            type="date"
            label="Ends On"
            :disabled="shouldDisableForm"
            :rules="
              areRecurrencesActive
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'End Date Is Required',
                      error: 'End Date Is Required',
                    }),
                  ]
                : []
            "
            @change="updateValue"
          />
        </v-flex>

        <v-flex shrink class="recurrance-form-field">
          <label :for="`recurrence-type-select-${recurrenceIndex}`">
            Repeat On
          </label>
          <CRSelect
            :id="`recurrences-${recurrenceIndex}-form-recurrence-type`"
            v-model="recurrence.recurrenceType"
            :items="recurrenceTypes"
            item-text="label"
            item-value="key"
            return-object
            flat
            solo
            :disabled="shouldDisableForm"
            :rules="
              areRecurrencesActive
                ? [
                    isRequired(true, isNotEmpty, {
                      req: 'Recurrence Type Is Required',
                      error: 'Recurrence Type Is Required',
                    }),
                  ]
                : []
            "
            @change="updateValue"
          />
        </v-flex>

        <v-flex v-show="recurrence.recurrenceType.key === 'custom'">
          <v-btn-toggle
            v-model="recurrence._repeatOnValues"
            class="weekday-repeat-switches"
            active-class="weekday-repeat-switch--active"
            multiple
            :disabled="shouldDisableForm"
            @change="updateValue"
          >
            <v-btn
              :id="`recurrences-${recurrenceIndex}-custom-day-toggle-sunday`"
              :disabled="shouldDisableForm"
              class="weekday-repeat-switch"
              value="repeatOnSunday"
              fab
              small
              color="primary"
            >
              S
            </v-btn>

            <v-btn
              :id="`recurrences-${recurrenceIndex}-custom-day-toggle-monday`"
              :disabled="shouldDisableForm"
              class="weekday-repeat-switch"
              value="repeatOnMonday"
              fab
              small
              color="primary"
            >
              M
            </v-btn>

            <v-btn
              :id="`recurrences-${recurrenceIndex}-custom-day-toggle-tuesday`"
              :disabled="shouldDisableForm"
              class="weekday-repeat-switch"
              value="repeatOnTuesday"
              fab
              small
              color="primary"
            >
              T
            </v-btn>

            <v-btn
              :id="`recurrences-${recurrenceIndex}-custom-day-toggle-wednesday`"
              :disabled="shouldDisableForm"
              class="weekday-repeat-switch"
              value="repeatOnWednesday"
              fab
              small
              color="primary"
            >
              W
            </v-btn>

            <v-btn
              :id="`recurrences-${recurrenceIndex}-custom-day-toggle-thursday`"
              :disabled="shouldDisableForm"
              class="weekday-repeat-switch"
              value="repeatOnThursday"
              fab
              small
              color="primary"
            >
              T
            </v-btn>

            <v-btn
              :id="`recurrences-${recurrenceIndex}-custom-day-toggle-friday`"
              :disabled="shouldDisableForm"
              class="weekday-repeat-switch"
              value="repeatOnFriday"
              fab
              small
              color="primary"
            >
              F
            </v-btn>

            <v-btn
              :id="`recurrences-${recurrenceIndex}-custom-day-toggle-sunday`"
              :disabled="shouldDisableForm"
              class="weekday-repeat-switch"
              value="repeatOnSaturday"
              fab
              small
              color="primary"
            >
              S
            </v-btn>
          </v-btn-toggle>
        </v-flex>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapActions } from 'vuex'
import {
  isNotEmpty,
  isRequired,
  validateTripFormGroups,
} from '@/utils/validators'

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    originalRecurrenceEvent: {
      type: Object,
      default: null,
    },
    timeZone: {
      type: String,
      default: () => DateTime.local().zoneName,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      DateTime,
      isNotEmpty,
      isRequired,
      areRecurrencesActive: false,
      recurrences: null,
      recurrenceTypes: [],
      repeatOnKeys: [
        'repeatOnSunday',
        'repeatOnMonday',
        'repeatOnTuesday',
        'repeatOnWednesday',
        'repeatOnThursday',
        'repeatOnFriday',
        'repeatOnSaturday',
      ],
    }
  },
  computed: {
    shouldDisableForm() {
      return (
        this.disabled ||
        !this.areRecurrencesActive ||
        !this.originalRecurrenceEvent?.startDatetime
      )
    },
  },
  watch: {
    value: {
      handler: 'updateLocalRecurrenceData',
    },
    originalRecurrenceEvent: {
      immediate: true,
      handler() {
        this.updateLocalRecurrenceData(this.value)
        this.updateValue()
      },
    },
  },
  async mounted() {
    try {
      const recurrenceTypesResponse = await this.getRecurrenceTypes()
      this.recurrenceTypes = recurrenceTypesResponse.data.recurrenceTypes
    } catch (error) {
      this.$store.dispatch('app/showAlert', {
        message: 'There was an error loading recurrence types.',
        type: 'error',
      })
    }
  },
  methods: {
    ...mapActions({
      getRecurrenceTypes: 'types/getRecurrenceTypes',
    }),
    updateLocalRecurrenceData(value, previousValue) {
      if (!previousValue && this.value.length) {
        this.areRecurrencesActive = true
      }
      if (!value.length) {
        this.recurrences = [
          {
            startDate: this.originalRecurrenceEvent?.startDatetime
              ? DateTime.fromISO(
                  this.originalRecurrenceEvent.startDatetime
                ).toISODate()
              : null,
            recurrenceExclusions: [],
            recurrenceType: {
              recurrenceTypeId: null,
              key: null,
              label: null,
            },
            recurrenceTypeId: null,
            endDate: null,
            repeatOnSunday: false,
            repeatOnMonday: false,
            repeatOnTuesday: false,
            repeatOnWednesday: false,
            repeatOnThursday: false,
            repeatOnFriday: false,
            repeatOnSaturday: false,
            _repeatOnValues: [],
          },
        ]
        return
      }

      // TODO: use this code for shifting below (see other TODOs)
      // First, we need to offset the days of the week if needed

      // const date1 = DateTime.fromISO(
      //   this.originalRecurrenceEvent.startDatetime,
      //   {
      //     zone: this.timeZone,
      //   }
      // )

      // // this one is to just see what the weekday diff would be
      // const date2 = DateTime.fromISO(
      //   this.originalRecurrenceEvent.startDatetime,
      //   {
      //     zone: this.timeZone,
      //   }
      // ).setZone('utc', {
      //   keepLocalTime: true,
      // })

      // console.log(
      //   'weekday offset',
      //   date1.weekday,
      //   date2.weekday,
      //   date2.weekday - date1.weekday
      // )

      this.recurrences = value.map((recurrence) => {
        const _repeatOnValues = []
        // TODO: Initial weekday shift from UTC happens here
        this.repeatOnKeys.forEach((key) => {
          if (recurrence[key]) {
            _repeatOnValues.push(key)
          }
        })

        return {
          ...recurrence,
          recurrenceTypeId: recurrence.recurrenceType.recurrenceTypeId,
          startDate: this.originalRecurrenceEvent?.startDatetime
            ? DateTime.fromISO(
                this.originalRecurrenceEvent.startDatetime
              ).toISODate()
            : null,
          endDate: DateTime.fromISO(recurrence.endDate, {
            zone: this.timeZone,
          }).toISODate(),
          _repeatOnValues,
        }
      })
    },
    updateValue() {
      const recurrences = this.recurrences.map((recurrence) => {
        if (!this.areRecurrencesActive) {
          this.$emit('input', [])
          return
        }
        let repeatOnValuesAsObject
        // TODO: The second weekday shift happens here, back to UTC
        switch (recurrence.recurrenceType.key) {
          case 'daily':
            repeatOnValuesAsObject = {
              repeatOnSunday: true,
              repeatOnMonday: true,
              repeatOnTuesday: true,
              repeatOnWednesday: true,
              repeatOnThursday: true,
              repeatOnFriday: true,
              repeatOnSaturday: true,
            }
            break
          case 'every_weekday':
            repeatOnValuesAsObject = {
              repeatOnSunday: false,
              repeatOnMonday: true,
              repeatOnTuesday: true,
              repeatOnWednesday: true,
              repeatOnThursday: true,
              repeatOnFriday: true,
              repeatOnSaturday: false,
            }
            break
          default:
            repeatOnValuesAsObject = {}
            this.repeatOnKeys.forEach((key) => {
              repeatOnValuesAsObject[key] = recurrence._repeatOnValues.includes(
                key
              )
            })
        }

        const mappedRecurrence = Object.assign(
          {},
          recurrence,
          repeatOnValuesAsObject,
          {
            recurrenceTypeId: recurrence.recurrenceType.recurrenceTypeId,
            startDate: this.originalRecurrenceEvent?.startDatetime
              ? DateTime.fromISO(this.originalRecurrenceEvent.startDatetime, {
                  zone: this.timeZone,
                }).toISO()
              : null,
            endDate: DateTime.fromISO(recurrence.endDate, {
              zone: this.timeZone,
            }).toISO(),
          }
        )
        delete mappedRecurrence._repeatOnValues
        return mappedRecurrence
      })

      const hasInvalidRecurrences = recurrences?.some((recurrence) => {
        return (
          !recurrence ||
          !recurrence.startDate ||
          !recurrence.endDate ||
          !recurrence.recurrenceType?.recurrenceTypeId
        )
      })

      if (hasInvalidRecurrences) {
        return
      }

      this.$emit('input', recurrences)
    },
  },
  validateForms() {
    validateTripFormGroups.call(
      this,
      ['trip-recurrence-input-form'],
      'recurrence'
    )
  },
}
</script>

<style lang="scss" scoped>
.recurrance-form-field {
  margin-right: 16px;
}
.weekday-repeat-switches {
  ::v-deep .v-btn {
    &.weekday-repeat-switch {
      height: 40px;
      width: 40px;
      border-radius: 50% !important;
      margin: 0 4px;
      font-size: 16px;
      &:not(.v-btn--active) {
        background: none !important;
        border: 1px solid $gray !important;
        .v-btn__content {
          color: $gray !important;
        }
      }
    }
  }
}
.v-btn-toggle {
  box-shadow: none;
}
</style>
