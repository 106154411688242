<template>
  <div class="reservation-add-stop-note">
    <div class="reservation-add-stop-note--content">
      <div
        v-show="isInDispatchSidebar"
        class="reservation-add-stop-note--back-arrow"
      >
        <a class="arrow_back" @click="clickBackStopNote">
          <CRIcon view-box="0 0 24 24" :width="20" :height="20">
            arrow_back
          </CRIcon>
          Back To Reservation
        </a>
      </div>
      <div v-if="isAirportStop && !isLastStop" class="margin-t-n4">
        <v-layout row align-center>
          <CRIcon
            view-box="0 -960 960 960"
            class="cursor-default"
            width="25"
            height="25"
          >
            flight
          </CRIcon>
          <p class="font-12 margin-t-3 margin-l-2">
            Looks like this stop is an airport pickup. Flight information is
            required.
          </p>
        </v-layout>
        <CRInput
          v-model="activeFlightNumber"
          class="margin-t-2"
          label="Flight Number"
          placeholder="e.g. AA1776"
          :rules="[
            (v) => validateLength(v, 10) || 'Must be less than 10 characters',
          ]"
        />
        <CRInput
          v-model="activeAirline"
          class="margin-t-n2"
          label="Airline"
          placeholder="e.g. American Airlines"
          :rules="[
            (v) => validateLength(v, 25) || 'Must be less than 25 characters',
          ]"
        />
        Stop Notes
      </div>
      <CRRichText
        id="reservation-add-stop-note-form-text-area-stop-note"
        :note="previousStopNote"
        placeholder="Stop notes for you, the customer, and the driver."
        :rows="4"
        auto-grow
        hide-details
        @htmlchange="updateStopNoteHtml"
        @textchange="updateStopNoteText"
      />
    </div>
    <div class="reservation-add-stop-note--btn-spacer"></div>
    <v-btn
      v-if="stopNoteExists"
      :id="`quote-form-trip-${resActiveStop.stopId}-delete-stop-notes-dialog-button`"
      color="error"
      outline
      style="margin: 0 0 71px 0; border-radius: 0"
      class="reservation-add-stop-note--action-btn reservation-add-stop-note--delete-btn"
      @click="deleteStopNote"
    >
      Delete
    </v-btn>
    <v-btn
      :id="`quote-form-trip-${resActiveStop.stopId}-set-stop-notes-button`"
      class="reservation-add-stop-note--action-btn"
      color="primary"
      style="margin: 0; border-radius: 0"
      @click="addStopNote"
    >
      Save
    </v-btn>
  </div>
</template>
<script>
import { authComputed } from '@/state/helpers'
import { updateStopNotes, updateFlightInformation } from '@/services/stops'
import { mapGetters, mapActions } from 'vuex'
import { EventBus } from '@/utils/event-bus'
import { validateLength } from '@/utils/validators'
import { RiskTypeId } from '@/utils/enum'

const INTERNAL_NOTE_TYPE = 1

export default {
  props: {
    reservationId: {
      type: Number,
      default: 0,
    },
    activeStop: { type: Object, default: () => {} },
    reservationType: {
      type: Number,
      default: null,
    },
    noteType: { type: String, default: null },
    isAirportStop: { type: Boolean, default: false },
    isLastStop: { type: Boolean, default: false },
  },
  data() {
    return {
      activeStopNote: '',
      activeStopNoteHtml: '',
      previousStopNote: '',
      loading: false,
      sendEmail: true,
      currentStopId: null,
      resActiveStop: {},
      activeFlightNumber: '',
      activeAirline: '',
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      showAddStopNote: 'dispatchNew/showAddStopNote',
      dispatchActiveStop: 'dispatchNew/dispatchActiveStop',
    }),
    stopNoteExists() {
      return (
        this.resActiveStop.stopNotes?.[0]?.html?.length > 0 ||
        this.resActiveStop.stopNotes?.[0]?.note?.length > 0 ||
        this.resActiveStop.notes?.length > 0 ||
        this.resActiveStop?.flightInformation?.airline ||
        this.resActiveStop?.flightInformation?.flightNumber
      )
    },
    isInDispatchSidebar() {
      return this.showAddStopNote
    },
  },
  mounted() {
    if (this.isInDispatchSidebar) {
      this.resActiveStop = this.dispatchActiveStop
    } else {
      this.resActiveStop = this.activeStop
    }
    if (this.resActiveStop.stopNotes && this.resActiveStop.stopNotes[0]) {
      this.activeStopNoteHtml = this.resActiveStop.stopNotes[0].html
      this.activeStopNote = this.resActiveStop.stopNotes[0].note
    } else {
      this.activeStopNote = this.resActiveStop.notes || ''
    }
    if (this.isAirportStop && this.resActiveStop.flightInformation) {
      this.activeFlightNumber = this.resActiveStop.flightInformation?.flightNumber
      this.activeAirline = this.resActiveStop.flightInformation?.airline
    }
    this.previousStopNote =
      this.resActiveStop.stopNotes?.[0]?.html ||
      this.resActiveStop.stopNotes?.[0]?.note ||
      this.resActiveStop.notes
  },
  methods: {
    validateLength,
    ...mapActions({
      setShowAddStopNote: 'dispatchNew/setShowAddStopNote',
      setDispatchActiveStop: 'dispatchNew/setDispatchActiveStop',
    }),
    clickBackStopNote() {
      this.setShowAddStopNote(false)
      this.setDispatchActiveStop({})
    },
    updateStopNoteText(value) {
      this.activeStopNote = value
    },
    updateStopNoteHtml(value) {
      this.activeStopNoteHtml = value
    },
    async submitStopNoteUpdate() {
      const payload = {
        stopId: this.currentStopId,
        notes: this.activeStopNote,
        stopNotes: this.resActiveStop.stopNotes,
      }
      if (payload.stopNotes?.[0]) {
        payload.stopNotes[0].note = this.activeStopNote
        payload.stopNotes[0].html = this.activeStopNoteHtml
      } else {
        payload.stopNotes = [
          {
            note: this.activeStopNote,
            html: this.activeStopNoteHtml,
          },
        ]
      }
      if (this.reservationId !== 0) {
        await updateStopNotes(this.currentStopId, payload)
      }
      this.$forceUpdate()
    },
    async submitFlightInformationUpdate() {
      const payload = {
        stopId: this.currentStopId,
        flightInformation: this.resActiveStop.flightInformation,
      }
      if (payload.flightInformation) {
        payload.flightInformation.flightNumber = this.activeFlightNumber
        payload.flightInformation.airline = this.activeAirline
        this.resActiveStop.flightInformation.flightNumber = this.activeFlightNumber
        this.resActiveStop.flightInformation.airline = this.activeAirline
      } else {
        payload.flightInformation = {
          flightNumber: this.activeFlightNumber,
          airline: this.activeAirline,
        }
        this.resActiveStop.flightInformation = payload.flightInformation
      }
      if (this.resActiveStop.risks) {
        if (this.activeFlightNumber && this.activeAirline) {
          this.removeFlightRisk()
        } else {
          this.addTemporaryFlightRisk()
        }
      }
      if (this.reservationId !== 0) {
        await updateFlightInformation(this.currentStopId, payload)
      }
      this.$forceUpdate()
    },
    async addStopNote() {
      if (this.noteType === 'address') {
        this.$emit('edit-address-note', {
          note: this.activeStopNote,
          html: this.activeStopNoteHtml,
          createdBy: this?.currentUser?.userId,
          noteType: INTERNAL_NOTE_TYPE,
        })
        this.$emit('close')
        return
      }
      this.currentStopId = this.resActiveStop.stopId
      this.$set(this.resActiveStop, 'notes', this.activeStopNote)
      EventBus.$emit(
        'reservation-itinerary-stop-note-update',
        this.resActiveStop,
        this.activeStopNote,
        this.activeStopNoteHtml
      )
      if (this.resActiveStop?.stopNotes?.[0]) {
        this.resActiveStop.stopNotes[0].note = this.activeStopNote
        this.resActiveStop.stopNotes[0].html = this.activeStopNoteHtml
      } else {
        EventBus.$emit(
          'add-new-stop-note',
          this.resActiveStop,
          this.activeStopNote,
          this.activeStopNoteHtml
        )
      }
      this.submitStopNoteUpdate()
      if (this.isAirportStop) {
        await this.submitFlightInformationUpdate()
        EventBus.$emit('refresh-detail')
      }
      if (this.isInDispatchSidebar) {
        this.setShowAddStopNote(false)
      } else {
        this.close()
      }
    },
    async deleteStopNote() {
      if (this.noteType === 'address') {
        this.$emit('edit-address-note', { note: null, html: null })
        this.$emit('close')
        return
      }
      this.currentStopId = this.resActiveStop.stopId
      this.activeStopNote = ''
      this.activeStopNoteHtml = ''
      this.$set(this.resActiveStop, 'notes', this.activeStopNote)
      if (this.resActiveStop.stopNotes?.[0]) {
        this.resActiveStop.stopNotes[0].note = this.activeStopNote
        this.resActiveStop.stopNotes[0].html = this.activeStopNoteHtml
      } else {
        EventBus.$emit(
          'add-new-stop-note',
          this.resActiveStop,
          this.activeStopNote,
          this.activeStopNoteHtml
        )
      }
      this.submitStopNoteUpdate()
      this.activeFlightNumber = ''
      this.activeAirline = ''
      if (this.isAirportStop) {
        await this.submitFlightInformationUpdate()
        EventBus.$emit('refresh-detail')
      }
      if (this.isInDispatchSidebar) {
        this.setShowAddStopNote(false)
      } else {
        this.close()
      }
    },
    close() {
      this.$store.dispatch('app/closeDialog')
    },
    removeFlightRisk() {
      // flight risks not surfaced on quote form
      this.resActiveStop.risks = this.resActiveStop.risks.filter(
        (risk) => risk.riskTypeId !== RiskTypeId.FlightRisk
      )
    },
    addTemporaryFlightRisk() {
      if (
        !this.resActiveStop.risks.find(
          (risk) => risk.riskTypeId === RiskTypeId.FlightRisk
        )
      ) {
        this.resActiveStop.risks = [
          ...this.resActiveStop.risks,
          {
            riskTypeId: RiskTypeId.FlightRisk,
            riskTypeLabel: 'Missing Flight Information',
            resolved: false,
            active: true,
          },
        ]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-add-stop-note {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--back-arrow {
    a {
      display: flex;
      align-items: center;

      .cr-icon {
        margin-left: 4px;
        margin-right: 18px !important;
      }
    }
    margin-top: -10px;
    margin-bottom: 15px;
  }

  &--btn-spacer {
    min-height: 71px;
    width: 100%;
  }

  &--delete-btn {
    border: none;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
  }
}

.arrow_back {
  color: $black;
  height: 24px;
  margin-right: 8px;
}
</style>
