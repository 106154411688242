import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { store } from '@/state/store'
import { CheckoutPage } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCheckoutPages(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<CheckoutPage>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()

    return store
      .dispatch('auth/getCurrentUser', {}, { root: true })
      .then((resp) => {
        const companyId = resp?.data?.user?.companyId
        const url = `https://${host}/tables/companies/${companyId}/checkout-pages?${query}`

        return httpService.get(url)
      })
  },
  postCheckoutBranding(params: { companyCheckoutId: number, companyId: number, type: string, payload: any}): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/companies/${
      params.companyId
    }/checkout-pages/${params.companyCheckoutId}/logo/${params.type}`

    return httpService.post(url, params.payload)
  },
}
