import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Invoice } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getInvoices(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Invoice>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const url = `https://${baseUrl()}/tables/invoices?${query}`
    return httpService.get(url)
  },
  sendInvoiceEmail(params: number[]): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/v2/notifications/sendInvoiceEmail`
    return httpService.post(url, {
      reservationIds: params,
    })
  },
  getEmailCustomizations(): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/v2/invoice/email`
    return httpService.get(url)
  },
  saveEmailCustomizations(payload): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/v2/invoice/email`
    return httpService.patch(url, payload)
  },
  createEmailCustomizations(payload): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/v2/invoice/email`
    return httpService.post(url, payload)
  },
  getReservationInvoice(payload): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/v2/invoice/reservations`
    return httpService.post(url, payload)
  },
}
