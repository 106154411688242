<template>
  <v-data-table
    hide-actions
    disable-pagination
    no-data-text="No stops added"
    :headers="tableHeaders"
    :items="tableItems"
    class="mt-3"
  >
    <template #headers="heads">
      <tr>
        <th
          v-for="(headerItem, headerIndex) in heads.headers"
          :key="headerIndex"
          class="header"
          :class="{ leftText: headerIndex <= 1 }"
        >
          {{ headerItem.text }}
        </th>
      </tr>
    </template>
    <template #items="props">
      <tr
        :style="{ 'background-color': lightenColor(props.item.color) }"
        class="row"
      >
        <td>
          <v-layout row align-center>
            <div class="pill" :style="{ background: props.item.color }"></div>
            <div class="vehicleGroupCell">{{ props.item.vehicle }}</div>
          </v-layout>
        </td>
        <td>{{ formatDate(props.item.date) }}</td>
        <td
          v-for="header in addressHeaders"
          :key="header.value"
          class="timeCell"
        >
          {{
            `${
              isFinalDropoffDate(header.value, props.item) ? 'Final DO: ' : ''
            }${formatTime(props.item[header.value])}`
          }}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import {
  yearMonthDayToMonthDayYear,
  twentyFourHourToTwelveHourTime,
} from '@/utils/time'

import CRColor from '@/cr/color/CRColor'

import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'

export default {
  components: {},
  props: {
    tripAddresses: { type: Array, default: () => [] },
    tripVehicleGroups: { type: Array, default: () => [] },
  },
  data() {
    return {
      baseSummaryHeaders: [
        {
          text: 'Vehicle',
          value: 'vehicle',
          sortable: false,
        },
        {
          text: 'Date',
          value: 'date',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    addressesByHash() {
      return this.tripAddresses.reduce((byHashObject, address) => {
        byHashObject[address.tripAddressHash] = address
        return byHashObject
      }, {})
    },
    addressHeaders() {
      return this.tripAddresses.reduce((addressHeaders, tripAddress) => {
        addressHeaders.push({
          text:
            tripAddress.address?.title ||
            tripAddress.address?.addressName ||
            `Address #${tripAddress.orderIndex + 1}`,
          value:
            tripAddress.address?.title ||
            tripAddress.address?.addressName ||
            `Address #${tripAddress.orderIndex + 1}`,
          sortable: false,
        })
        return addressHeaders
      }, [])
    },
    tableHeaders() {
      return this.baseSummaryHeaders.concat(this.addressHeaders)
    },
    tableItems() {
      const tableItems = []
      for (const vehicleGroup of this.tripVehicleGroups) {
        const vehicleGroupTableItemsByDate = {}
        let tripDates = deepClone(vehicleGroup.tripDates)
        const finalDropoffDate = vehicleGroup?.finalDropoffDate
        if (finalDropoffDate?.tripAddressHash) {
          const foundDate = tripDates.find(
            (td) =>
              td.date === finalDropoffDate.date &&
              td.tripAddressHash === finalDropoffDate.tripAddressHash
          )
          if (foundDate) {
            foundDate.times.push(finalDropoffDate.time)
          } else {
            const fdd = deepClone(finalDropoffDate)
            fdd.times = [fdd.time]
            tripDates.push(fdd)
          }
        }
        for (const tripDate of tripDates) {
          const tripAddress = this.addressesByHash[tripDate.tripAddressHash]
          const addressName =
            tripAddress?.address?.title ||
            tripAddress?.address?.addressName ||
            `Address #${tripAddress.orderIndex + 1}`
          if (!vehicleGroupTableItemsByDate[tripDate.date]) {
            vehicleGroupTableItemsByDate[tripDate.date] = {}
          }
          for (const [index, time] of tripDate.times.entries()) {
            let tableItem
            if (vehicleGroupTableItemsByDate[tripDate.date][index]) {
              tableItem = vehicleGroupTableItemsByDate[tripDate.date][index]
            } else {
              tableItem = {
                vehicle: vehicleGroup.name || 'All Vehicle Groups',
                date: tripDate.date,
                color: vehicleGroup.color,
              }
              vehicleGroupTableItemsByDate[tripDate.date][index] = tableItem
              tableItems.push(tableItem)
            }
            tableItem[addressName] = time
          }
        }
      }

      return tableItems.toSorted(this.compareTableItems)
    },
  },
  methods: {
    formatDate(dateString) {
      return yearMonthDayToMonthDayYear(dateString)
    },
    formatTime(timeString) {
      if (timeString) {
        return twentyFourHourToTwelveHourTime(timeString)
      }

      return '--'
    },
    lightenColor(color) {
      return new CRColor(color).lighten(35)
    },
    tableItemToDateTime(tableItem) {
      let firstPickupTime = null
      for (const addressHeader of this.addressHeaders) {
        if (tableItem[addressHeader.value]) {
          firstPickupTime = tableItem[addressHeader.value]
          break
        }
      }
      const isoString = firstPickupTime
        ? `${tableItem.date}T${firstPickupTime}`
        : tableItem.date
      return DateTime.fromISO(isoString)
    },
    compareTableItems(tableItemA, tableItemB) {
      const firstPickupDateTimeA = this.tableItemToDateTime(tableItemA)
      const firstPickupDateTimeB = this.tableItemToDateTime(tableItemB)

      if (firstPickupDateTimeA === firstPickupDateTimeB) {
        return 0
      } else if (firstPickupDateTimeA > firstPickupDateTimeB) {
        return 1
      } else {
        return -1
      }
    },
    isFinalDropoffDate(addressTitle, tableItem) {
      const finalDropoffDates = this.tripVehicleGroups
        .map((tvg) => {
          return { ...tvg.finalDropoffDate, color: tvg.color }
        })
        .map((fdd) => {
          if (fdd.tripAddressHash) {
            const foundTripAddress = this.tripAddresses.find(
              (ta) => ta.tripAddressHash === fdd.tripAddressHash
            )
            if (foundTripAddress) {
              fdd.addressTitle = foundTripAddress.address.title
            }
          }
          return fdd
        })

      const foundFinalDropoffDate = finalDropoffDates.find(
        (fdd) =>
          fdd.addressTitle === addressTitle &&
          fdd.date === tableItem.date &&
          fdd.time === tableItem[addressTitle] &&
          fdd.color === tableItem.color
      )
      return foundFinalDropoffDate
    },
  },
}
</script>

<style lang="scss" scoped>
.pill {
  width: 10px;
  height: 25px;
  border-radius: 40px;
  margin-right: 5px;
}

th.header {
  font-size: 14px;
  font-weight: normal;
  color: $gray-dark !important;
}

.leftText {
  text-align: left;
}

tr.row {
  border-top: 2px solid $white !important;
  & > td {
    height: auto !important;
  }
}

.timeCell {
  text-align: center;
}

.vehicleGroupCell {
  white-space: nowrap;
}
</style>
