<template>
  <BaseTableWrapper
    :key="`quote-past-quotes-table-${currentRefresh}`"
    :show-table-header="false"
    :initial-filters="initialFilters"
    :table-init="tableInit"
    @duplicate-quote="duplicateQuote"
  />
</template>

<script>
import { DateTime } from 'luxon'
import { deepClone } from '@/utils/deepClone'
import BaseTableWrapper from '@/components/BaseTableWrapper.vue'
import QuotesActionableColumn from '@/components/QuotesActionableColumn.vue'
import QuotesTableActions from '@/components/QuotesTableActions.vue'
import quotesPastQuotesActionsWrapper from '@/components/QuotesPastQuotesActionsWrapper'

const wrappedActions = quotesPastQuotesActionsWrapper(QuotesTableActions)

const quoteConversionStatusMap = [
  { text: 'Unconverted', value: 'false' },
  { text: 'Converted', value: 'true' },
]
const categories = [
  {
    _t_id: '1711fa30',
    text: 'Quote Status',
    prop: 'isConverted',
    values: deepClone(quoteConversionStatusMap),
    type: 'text',
    method: 'or',
  },
]

const columns = [
  {
    _t_id: 'quote_customer_id_filter',
    filterType: 'eq',
    prop: 'trips.customer.customerId',
    filterPlaceholder: true,
  },
  {
    _t_id: 'f4dfd342',
    prop: '/',
    text: 'Actions',
    component: wrappedActions,
    sort: false,
    filter: false,
    detail: false,
    type: 'actions',
  },
  {
    _t_id: 'quoteId',
    prop: 'quoteId',
    text: 'ID',
    sort: true,
    filter: false,
    type: 'number',
    filterType: 'eq',
    sortProp: 'id',
    defaultSort: true,
  },
  {
    _t_id: '67efe745',
    prop: '/',
    text: 'Status',
    sort: true,
    filter: false,
    computedText: (item) => (item.isConverted ? 'Converted' : 'Unconverted'),
    type: 'text',
    filterType: 'eq',
    sortProp: 'isConverted',
    defaultSort: true,
  },
  {
    _t_id: '49e304c6',
    prop: 'tripTypes',
    text: 'Trip Types',
    sort: false,
    filter: false,
    component: QuotesActionableColumn,
    detail: false,
    type: 'text',
    displayType: 'trip-type-multi',
    sortProp: 'tripTypes/key',
    filterProp: 'tripTypes/key',
    filterType: 'contains',
  },
  {
    _t_id: 'cc700992',
    prop: 'createdOn',
    text: 'Created',
    computedText: (item) =>
      DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'contains',
  },
  {
    _t_id: 'ccba919c',
    prop: ['createdByName'],
    text: 'Sent By',
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'contains',
    sortProp: 'createdByName',
  },
  {
    _t_id: 'df1c54e9',
    prop: 'pickupDate',
    text: 'Pickup Date',
    computedText: (item) =>
      DateTime.fromISO(item).toLocaleString(DateTime.DATE_SHORT),
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'equals',
  },
  {
    _t_id: '60eee659',
    prop: 'pickupLocation',
    text: 'Pickup Location',
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'equals',
  },
  {
    _t_id: 'a509a4d3',
    prop: 'checkoutPage/label',
    text: 'Checkout Page',
    sort: false,
    filter: false,
    type: 'text',
    filterType: 'equals',
  },
]

export default {
  components: {
    BaseTableWrapper,
  },
  props: {
    customer: {
      type: Object,
      default: () => undefined,
    },
    lastQuoteId: {
      type: Number,
      default: undefined,
    },
    showLastQuote: {
      type: Boolean,
    },
  },
  data() {
    return {
      isAdmin: false,
      initialFilters: [],
      currentRefresh: 0,
      tableInit: {
        initialFilters: [],
        isDetailed: false,
        addNewEnabled: false,
        enableFilterRow: false,
        enableExport: false,
        enableColumnConfig: false,
        enableSavedViews: false,
        enableStatusFilterToggle: false,
        hideActions: this.showLastQuote,
        tableId: 'quotes_past_reservation_table_view',
        currentPage: 1,
        perPage: 10,
        columns,
        categories: categories,
        collection: 'quotes',
        vuexAction: 'quotes/quotesListView',
        additionalQueries: 'viewingCustomerPastQuotes=true',
      },
    }
  },
  watch: {
    showLastQuote: {
      immediate: true,
      handler: function (shouldDisplayLastQuote) {
        if (shouldDisplayLastQuote && this.lastQuoteId) {
          const lastQuoteFilter = {
            column: columns.find((column) => column._t_id === 'quoteId'),
            value: this.lastQuoteId,
            hideOnFilterBar: true,
          }
          this.tableInit.categories = null
          this.tableInit.hideActions = true
          this.initialFilters.push(lastQuoteFilter)
        } else {
          this.tableInit.categories = categories
          this.tableInit.hideActions = false

          let i = this.initialFilters.findIndex(
            (filter) => filter.column.prop === 'managedId'
          )
          if (i >= 0) {
            this.initialFilters.splice(i, 1)
          }
        }

        // Force the table to refresh by changing its key
        this.currentRefresh++
      },
    },
  },
  mounted() {
    if (this.customer && this.customer.id) {
      const customerFilter = {
        column: columns.find(
          (column) => column._t_id === 'quote_customer_id_filter'
        ),
        value: this.customer.id,
        hideOnFilterBar: true,
      }
      this.initialFilters.push(customerFilter)
    }
  },
  methods: {
    async duplicateQuote(actionProps) {
      const quoteId = actionProps?.props?.item?.quoteId
      const fullQuote = await this.$store.dispatch('quotes/getV2Quote', quoteId)
      const quote = fullQuote?.data?.quote
      const { trips = [] } = quote || {}
      if (trips.length) {
        this.$emit('duplicate-trips', trips)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep.status-filters .status-filter-select {
  max-width: 350px !important;
}
</style>
