<template>
  <v-layout column>
    <div v-if="errorMessage" class="error-message-trip">
      Pricing method must be selected
    </div>
    <v-layout row>
      <QuotesAutomatedPricingMarket :quote="quote" :trip-index="tripIndex" />
      <v-flex xs10>
        <QuotesAutomatedPricingTable
          :toggle-selected="toggleSelected"
          :quote="quote"
          :trip-index="tripIndex"
          :set-fare="setFare"
          :clear-pricing-table="clearPricingTable"
          :use-table="useTable"
          @pricing-table-cleared="$emit('pricing-table-cleared')"
          @pricing-data="$emit('pricing-data', $event)"
        />
      </v-flex>
    </v-layout>
  </v-layout>
</template>
<script>
import QuotesAutomatedPricingMarket from './QuotesAutomatedPricingMarket.vue'
import QuotesAutomatedPricingTable from './QuotesAutomatedPricingTable.vue'

export default {
  components: {
    QuotesAutomatedPricingMarket,
    QuotesAutomatedPricingTable,
  },
  inject: {
    form: { default: null },
  },
  props: {
    quote: { type: Object, default: () => ({}) },
    useTable: { type: Boolean },
    tripIndex: { type: Number, default: 0 },
    setFare: { type: Function, default: null },
    shouldValidate: { type: Boolean },
    dataPresent: {
      type: Boolean,
    },
    rules: { type: Array, default: () => [] },
    clearPricingTable: {
      type: Boolean,
    },
  },
  data() {
    return {
      selected: false,
      errorMessage: false,
      validating: false,
    }
  },
  watch: {
    selected() {
      if (!this.validating) return

      if (this.selected) {
        this.errorMessage = false
        return
      }

      this.errorMessage = true
    },
  },
  created() {
    if (this.form) {
      this.form.register(this)
    }
  },
  beforeDestroy() {
    if (this.form) {
      this.form.unregister(this)
    }
  },
  methods: {
    toggleSelected(state) {
      this.selected = state
    },
    validate() {
      this.validating = true

      setTimeout(() => {
        this.validating = false
      }, 1000)

      if (!this.selected) {
        this.errorMessage = true
        this.$emit('update:error', true)
        return false
      }
      this.errorMessage = false
      this.$emit('update:error', true)
      return true
    },
  },
}
</script>
<style lang="scss" scoped>
.error-message-trip {
  color: $error !important;
}
</style>
