<template>
  <v-container id="shuttle-quote-form-stop-detail" fluid class="pa-0 mt-2 mb-2">
    <v-layout xs12 row wrap class="pt-2 pb-2 pl-3 pr-3 address-detail">
      <v-flex xs12 lg4>
        <v-layout column>
          <v-flex xs12 class="mr-2">
            <div
              style="
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 30px;
              "
            >
              {{
                `Address #${addressIndex + 1} ${
                  tripAddress.address.title
                    ? `- ${tripAddress.address.title}`
                    : ''
                }`
              }}
            </div>
            <div class="d-flex align-center">
              <div v-if="!tripAddress.address.addressName">
                <CRAddressSelectorV2
                  v-if="isKnownAddressEditor"
                  class="mt-1"
                  placeholder="Type to search"
                  :disabled="isModeView"
                  legacy-border
                  @change:place="
                    editAddress($event.place, $event.defaultAddressTitle)
                  "
                />
                <CRAddressSelector
                  v-else
                  class="mt-1"
                  prepend-inner-icon="location_on"
                  placeholder="Type to search"
                  :disabled="isModeView"
                  @place-selected="
                    editAddress($event.place, $event.defaultAddressTitle)
                  "
                />
              </div>
              <div v-else>
                <CRInput
                  class="mt-1 no-grow w-full"
                  :value="tripAddress.address.addressName"
                  type="text"
                  clear-icon="replay"
                  :clearable="!isModeView"
                  :readonly="true"
                  :disabled="isModeView"
                  prepend-inner-icon="location_on"
                  @click:clear="clearAddress"
                />
              </div>
              <CRIcon
                v-if="
                  !isModeView &&
                  isKnownAddressEditor &&
                  tripAddress &&
                  tripAddress.address &&
                  tripAddress.address.addressName
                "
                :key="`known-address-icon-${addressIndex}-${tripAddress.address.knownAddressId}`"
                color="primary"
                class="max-w-24 ml-2 mb-4"
                view-box="0 0 24 24"
                @click.native="editKnownAddress"
              >
                {{
                  tripAddress.address.knownAddressId
                    ? 'place_filled'
                    : 'place_outline'
                }}
              </CRIcon>
            </div>
          </v-flex>
          <v-flex
            xs12
            order-xs5
            order-sm4
            order-lg3
            class="pb-3"
            align-self-start
          >
            <v-btn
              v-if="
                !isModeView &&
                (!tripAddress.tripAddressNotes ||
                  !tripAddress.tripAddressNotes[0] ||
                  (!tripAddress.tripAddressNotes[0].html &&
                    !tripAddress.tripAddressNotes[0].note))
              "
              color="primary"
              class="ml-0"
              flat
              small
              @click="isSidebarActive = true"
            >
              Add Notes
            </v-btn>
            <v-flex v-else xs12 class="mt-2 ml-1">
              <p class="mb-1">
                Notes
                <span
                  v-if="!isModeView"
                  class="edit-button pa-2"
                  @click="isSidebarActive = true"
                >
                  Edit
                </span>
              </p>
              <div>
                <p
                  v-if="tripAddress.tripAddressNotes[0]"
                  v-html="
                    tripAddress.tripAddressNotes[0].html ||
                    tripAddress.tripAddressNotes[0].note
                  "
                />
              </div>
            </v-flex>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 lg8>
        <v-layout column>
          <QuoteFormAddressDetailDateSelector :trip-address="tripAddress" />
        </v-layout>
      </v-flex>
      <CRSidebarDialog v-model="isSidebarActive">
        <template #title>
          <p>Address Notes</p>
        </template>
        <StopInfoSidebar
          v-if="isSidebarActive"
          :key="`add-stop-note-sidebar-${addressIndex}`"
          :active-stop="activeStop"
          note-type="address"
          @edit-address-note="
            editAddressNote({
              addressIndex,
              note: $event,
            })
          "
          @close="isSidebarActive = false"
        />
      </CRSidebarDialog>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { authComputed } from '@/state/helpers'
import QuoteFormAddressDetailDateSelector from '@/components/QuoteFormAddressDetailDateSelector.vue'
import StopInfoSidebar from '@/components/StopInfoSidebar.vue'
import { EventBus } from '@/utils/event-bus'

export default {
  components: {
    QuoteFormAddressDetailDateSelector,
    StopInfoSidebar,
  },
  props: {
    tripAddress: { type: Object, default: () => {} },
    addressIndex: { type: Number, default: 0 },
  },
  data() {
    return {
      isSidebarActive: false,
    }
  },
  computed: {
    ...authComputed,
    currentTripIndex() {
      return this.$store.getters['quoteForm/getCurrentTripIndex']
    },
    mode() {
      return this.$store.getters['quoteForm/getMode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    activeStop() {
      return {
        addressIndex: this.addressIndex,
        stopNotes: this.tripAddress.tripAddressNotes,
      }
    },
  },
  mounted() {
    EventBus.$on(
      `quote-form-trip-${this.currentTripIndex}-edit-stop-location`,
      (newAddress, stopIndex) => {
        this.editAddressModule({
          addressIndex: stopIndex,
          newAddress,
          addressTitle: newAddress.title,
        })
      }
    )
  },
  methods: {
    ...mapActions({
      editAddressNote: 'quoteForm/editAddressNote',
      editAddressModule: 'quoteForm/editAddress',
      clearAddressModule: 'quoteForm/clearAddress',
    }),
    editAddress(newAddress, addressTitle) {
      this.editAddressModule({
        addressIndex: this.addressIndex,
        newAddress,
        addressTitle,
      })
      EventBus.$emit('get-estimations')
    },
    clearAddress() {
      this.clearAddressModule({ addressIndex: this.addressIndex })
      EventBus.$emit('get-estimations')
    },
    editKnownAddress() {
      let title = 'Edit Stop Location'
      const component = () => import('./KnownAddressSidebar.vue')
      this.$store.dispatch(
        'app/openSidebarDialog',
        {
          data: {
            address: this.tripAddress.address,
            stopIndex: this.addressIndex,
            title,
            tripIndex: this.currentTripIndex,
          },
          component,
        },
        true
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.address-detail {
  border: 1px rgba(211, 211, 211, 0.5) solid;
  border-radius: 4px;
}
.edit-button {
  cursor: pointer;
  color: $primary;
  font-size: 13px;
  font-weight: bold;
}
</style>
