<template>
  <v-layout column class="mt-4">
    <v-layout row style="align-items: center">
      <CRIcon view-box="0 0 24 24">list</CRIcon>
      <h3 class="ml-1">Summary</h3>
      <v-flex class="elevation-0 ma-0 summary-buttons">
        <v-btn
          id="`quote-form-summary-by-stops`"
          flat
          class="ma-0"
          :class="{ primary: selectedSummaryIndex === 0 }"
          @click="selectedSummaryIndex = 0"
        >
          By Stops
        </v-btn>
        <v-btn
          id="quote-form-summary-by-vehicle"
          flat
          style="border-radius: 0"
          class="ma-0"
          :class="{ primary: selectedSummaryIndex === 1 }"
          @click="selectedSummaryIndex = 1"
        >
          By Vehicles
        </v-btn>
        <v-btn
          :id="`quote-form-summary-by-time`"
          flat
          class="ma-0"
          :class="{ primary: selectedSummaryIndex === 2 }"
          @click="selectedSummaryIndex = 2"
        >
          By Time
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout v-if="recurrences.length > 0" class="recurrence-description mt-2">
      {{ recurrenceDescription }}
    </v-layout>

    <QuoteFormTripSummaryByStops
      v-if="selectedSummaryIndex === 0"
      :trip-addresses="tripAddresses"
      :trip-vehicle-groups="tripVehicleGroups"
    />

    <QuoteFormTripSummaryByVehicle
      v-if="selectedSummaryIndex === 1"
      :trip-addresses="tripAddresses"
      :trip-vehicle-groups="tripVehicleGroups"
    />

    <QuoteFormTripSummaryByTime
      v-if="selectedSummaryIndex === 2"
      :trip-addresses="tripAddresses"
      :trip-vehicle-groups="tripVehicleGroups"
    />
  </v-layout>
</template>

<script>
import QuoteFormTripSummaryByVehicle from './QuoteFormTripSummaryByVehicle.vue'
import QuoteFormTripSummaryByTime from './QuoteFormTripSummaryByTime.vue'
import QuoteFormTripSummaryByStops from './QuoteFormTripSummaryByStops.vue'
import { DateTime } from 'luxon'
import { daysMap } from '@/utils/time'

export default {
  components: {
    QuoteFormTripSummaryByVehicle,
    QuoteFormTripSummaryByTime,
    QuoteFormTripSummaryByStops,
  },
  props: {
    tripAddresses: { type: Array, default: () => [] },
    tripVehicleGroups: { type: Array, default: () => [] },
    recurrences: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedSummaryIndex: 0,
    }
  },
  computed: {
    recurrenceDescription() {
      const recurrence = this.recurrences[0]
      const startDate = DateTime.fromISO(recurrence?.startDate).toFormat(
        'MM/dd/yyyy'
      )
      const endDate = DateTime.fromISO(recurrence?.endDate).toFormat(
        'MM/dd/yyyy'
      )
      if (recurrence?.recurrenceTypeId !== 6) {
        return `Repeats ${recurrence?.recurrenceType?.label?.toLowerCase()} from ${startDate} to ${endDate}`
      } else if (recurrence?.recurrenceTypeId === 6) {
        let recurrenceDays = []
        daysMap.forEach((day) => {
          if (recurrence[`repeatOn${day.description}`]) {
            recurrenceDays.push(day.description)
          }
        })
        let description = 'Repeats every'
        recurrenceDays.forEach((day, index) => {
          if (index == recurrenceDays.length - 1 && recurrenceDays.length > 1) {
            description += ` and ${day}`
          } else {
            description += ` ${day}${recurrenceDays.length > 2 ? ',' : ''}`
          }
        })
        description += ` from ${startDate} to ${endDate}`
        return description
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-buttons {
  border-radius: 5px !important;
  display: flex;
  justify-content: flex-end;
  .v-btn {
    border: 1px solid $gray-light;
    border-radius: 0 !important;
  }
  :first-child {
    border-radius: 5px 0 0 5px !important;
  }
  :last-child {
    border-radius: 0 5px 5px 0 !important;
  }
}
.recurrence-description {
  font-size: 13px;
  color: $gray-medium-dark;
}
</style>
