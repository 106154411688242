var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"collect-payment",class:{
    'collect-payment--column': _vm.isInvoice && _vm.$vuetify.breakpoint.xs,
    'collect-payment--row': _vm.isInvoice && _vm.$vuetify.breakpoint.smAndUp,
  },style:(_vm.isInvoice ? 'width: 100%;' : '')},[_c('div',{class:{
      'collect-payment--content--half-mobile': _vm.isInvoice && _vm.$vuetify.breakpoint.xs,
      'collect-payment--content--half': _vm.isInvoice && _vm.$vuetify.breakpoint.smAndUp,
      'collect-payment--content': !_vm.isInvoice,
    }},[_c('v-form',{ref:"payment-form",class:{
        'collect-payment--form--invoice': _vm.isInvoice && _vm.$vuetify.breakpoint.smAndUp,
        'collect-payment--form-invoice-mobile': _vm.isInvoice && _vm.$vuetify.breakpoint.xs,
        'collect-payment--form': !_vm.isInvoice,
      },attrs:{"color":"primary"}},[(!_vm.isInvoice)?_c('div',{staticClass:"collect-payment--amount"},[_c('CRInput',{key:"collect-payment-partial-payment-amount-input",staticClass:"collect-payment--input",attrs:{"id":`-collect-payment-payment-form-partial-payment-amount`,"type":"number","prefix":"$","label":"Amount","disabled":_vm.isInvoice,"tooltip":_vm.tooltipText,"tooltip-style":_vm.tooltipStyle,"rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Amount Is Required',
              error: 'Amount Is Required',
            }),
            (val) => _vm.validatePartialPayment(val),
          ]},model:{value:(_vm.partialPaymentAmount),callback:function ($$v) {_vm.partialPaymentAmount=$$v},expression:"partialPaymentAmount"}}),_vm._t("amountExplainer")],2):_vm._e(),(!_vm.isInvoice)?_c('div',[(!_vm.isCustomerCancel)?_c('CRSelect',{attrs:{"label":"Payment Method","items":_vm.paymentMethodOptions,"item-value":"value","item-text":"label","height":40,"solo":"","flat":"","dense":"","hide-details":""},model:{value:(_vm.selectedPaymentMethod),callback:function ($$v) {_vm.selectedPaymentMethod=$$v},expression:"selectedPaymentMethod"}}):_vm._e()],1):_vm._e(),(_vm.isCreditCardPaymentMethod)?_c('div',{staticClass:"collect-payment--credit mt-0"},[_c('CreditCardSelector',{ref:"credit-card-selector",staticStyle:{"margin":"30px 0"},attrs:{"is-customer-cancel":_vm.isCustomerCancel,"default-selected-card-index":_vm.defaultSelectedCardIndex,"saved-cards":_vm.paymentProfiles},on:{"new-card-selected":_vm.newCardSelected},model:{value:(_vm.creditCard),callback:function ($$v) {_vm.creditCard=$$v},expression:"creditCard"}})],1):(_vm.isCheckPaymentMethod || _vm.isWirePaymentMethod)?_c('div',{staticStyle:{"margin":"21px 0 6px"}},[_c('CRInput',{staticClass:"collect-payment--input",attrs:{"id":`-collect-payment-payment-form-check-number`,"type":"text","floatinglabel":_vm.checkWireNumberLabel,"rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: `${_vm.checkWireNumberLabel} Is Required`,
              error: `${_vm.checkWireNumberLabel} Is Required`,
            }),
          ]},model:{value:(_vm.manualPaymentNumber),callback:function ($$v) {_vm.manualPaymentNumber=$$v},expression:"manualPaymentNumber"}})],1):(_vm.isOtherPaymentMethod)?_c('div',{staticStyle:{"margin":"21px 0 6px"}},[_c('CRInput',{staticClass:"collect-payment--input",attrs:{"id":`-collect-payment-payment-form-other-payment-number`,"type":"text","floatinglabel":"Payment Number","rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Payment Number Is Required',
              error: 'Payment Number Is Required',
            }),
          ]},model:{value:(_vm.manualPaymentNumber),callback:function ($$v) {_vm.manualPaymentNumber=$$v},expression:"manualPaymentNumber"}})],1):_vm._e(),(_vm.shouldShowLowerDetails)?_c('div',[(!_vm.isInvoice && !_vm.isCustomerCancel)?_c('div',{staticClass:"mt-2"},[_c('label',[_vm._v("Office Notes")]),_c('v-textarea',{staticClass:"collect-payment--input",attrs:{"solo":"","flat":"","auto-grow":"","placeholder":"Office notes for internal use only (customers will not see this).","validate-on-blur":""},model:{value:(_vm.officeNotes),callback:function ($$v) {_vm.officeNotes=$$v},expression:"officeNotes"}})],1):_vm._e(),(!_vm.isInvoice && !_vm.isCustomerCancel)?_c('div',[_c('label',[_vm._v("Payment Notes")]),_c('v-textarea',{staticClass:"collect-payment--input",attrs:{"solo":"","flat":"","auto-grow":"","placeholder":"Payment notes for you and the customer.","validate-on-blur":""},model:{value:(_vm.customerNotes),callback:function ($$v) {_vm.customerNotes=$$v},expression:"customerNotes"}})],1):_vm._e(),(!_vm.isInvoice && !_vm.isOperatorCancel)?_c('v-checkbox',{staticClass:"send-email-checkbox",attrs:{"color":"primary","label":`Send Payment Receipt Email`},model:{value:(_vm.sendEmail),callback:function ($$v) {_vm.sendEmail=$$v},expression:"sendEmail"}}):_vm._e()],1):_vm._e()])],1),(!_vm.isInvoice)?_c('div',{staticClass:"collect-payment--button-spacer"}):_vm._e(),(_vm.shouldShowLowerDetails && !_vm.isInvoice && !_vm.isOperatorCancel)?_c('div',{class:{
      'collect-payment--submit-btn': !_vm.isInvoice,
      'collect-payment--submit-btn--disabled': !_vm.silentValidatePaymentForm || _vm.disableSubmit,
    },attrs:{"id":"collect-payment--submit-btn"},on:{"click":_vm.submit}},[(!_vm.loading)?_c('span',[_vm._v(_vm._s(_vm.submitBtnText))]):_c('CRProgressCircular',{attrs:{"size":27}})],1):(_vm.isInvoice)?_c('span',{staticClass:"collect-payment--right-half margin-t-12"},[_c('p',{staticClass:"grand-total total-due-today"},[_c('span',[_vm._v("Balance Due")]),_c('span',[_vm._v(" "+_vm._s(_vm.currencyFilter(_vm.balance))+" ")])]),_c('v-checkbox',{staticClass:"send-email-checkbox",attrs:{"color":"primary","label":`I agree to the Terms and Conditions`},model:{value:(_vm.termsSigned),callback:function ($$v) {_vm.termsSigned=$$v},expression:"termsSigned"}}),_c('v-layout',{attrs:{"justify-center":""}},[_c('v-btn',{staticClass:"btn-primaryaction",attrs:{"id":`collect-payment--invoice--submit-btn`,"loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Submit ")])],1)],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }