<template>
  <div class="pay-provider">
    <div class="pay-provider--content">
      <v-form ref="payment-form" :lazy-validation="true">
        <CRInput
          :id="`-pay-provider-input-payment-amount`"
          v-model="amount"
          class="pay-provider--input"
          label="Payment Amount"
          type="number"
          :background-color="$cr.theme.inputBackgroundGray"
          prefix="$"
          validate-on-blur
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Amount Is Required',
              error: 'Amount Is Required',
            }),
          ]"
        />
        <CRSelect
          :id="`-pay-provider-select-payment-type`"
          v-model="paymentType"
          class="pay-provider--input"
          :items="paymentTypes"
          label="Payment Type"
          item-text="label"
          validate-on-blur
          item-value="paymentTypeId"
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Payment Type Required',
              error: 'Payment Type Required',
            }),
          ]"
        />
        <CRInput
          :id="`-pay-provider-input-reference-number`"
          v-model="refNumber"
          class="pay-provider--input"
          label="Reference Number"
          :background-color="$cr.theme.inputBackgroundGray"
          validate-on-blur
          :rules="[
            isRequired(true, isNotEmpty, {
              req: 'Reference Number Is Required',
              error: 'Reference Number Is Required',
            }),
          ]"
        />
        <CRInput
          :id="`-pay-provider-input-notes`"
          v-model="notes"
          class="pay-provider--input"
          multi-line
          label="Notes"
        />
        <v-checkbox
          :id="`-pay-provider-checkbox-send-email`"
          v-model="sendEmail"
          class="send-email-checkbox pay-provider--input"
          label="Send Email"
        />
      </v-form>
    </div>
    <div class="pay-provider--btn-spacer" style="height: 71px"></div>
    <div
      id="pay-provider--submit-btn"
      class="pay-provider--submit-btn"
      @click="submit"
    >
      <span v-if="!loading">Record Payment</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import { isRequired, isNotEmpty } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import * as logger from '@/utils/logger'
import { round } from '@/utils/round'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      paymentTypes: [],
      paymentType: '',
      refNumber: '',
      amount: null,
      notes: '',
      sendEmail: true,
      attachReceipt: false,
      loading: false,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    balance() {
      let balanceOut = 0
      if (this.rows.length && !Object.keys(this.row).length) {
        // multi-select version
        this.rows.forEach((selection) => {
          if (balanceOut)
            balanceOut = Number(balanceOut) + Number(selection.item.balance)
          else balanceOut = Number(selection.item.balance)
        })
      } else {
        // detail version
        balanceOut = this.row.balance
      }

      return balanceOut ? balanceOut.toFixed(2) : (0.004).toFixed(2)
    },
  },
  async mounted() {
    // get payment method types
    const { data: paymentTypes } = await this.$store.dispatch(
      'types/getBillingPaymentTypes'
    )
    this.paymentTypes = paymentTypes
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    remove(item) {
      this.$emit('remove-item', item)
    },
    cancel() {
      this.$emit('row-collapse')
      this.$emit('close-modal')
    },
    async submit() {
      if (this.loading) {
        return;
      }

      this.loading = true
      await this.$forceUpdate()
      const isValid = await this.$refs['payment-form'].validate()
      if (!isValid) {
        this.loading = false
        return
      }

      const payload = {
        paymentType: this.paymentType,
        // TODO: change the payment system when we want to do payments via authorize.net/plaid/etc
        paymentSystem: 2,
        userId: this.currentUser.userId,
        reservationIds: [],
        reservationToVersionMap: {},
        companyId: this.currentUser.companyId,
        referenceNumber: this.refNumber,
        amount: round(this.amount, 2),
        sendEmail: this.sendEmail,
        notes: {
          note: this.notes,
        },
      }
      payload.reservationIds = Object.keys(this.row).length
        ? [this.row.reservationId]
        : this.rows.map((selection) => {
            return selection.item.reservationId
          })

      if (Object.keys(this.row).length) {
        payload.reservationToVersionMap[this.row.reservationId.toString()] = this.row.version;
      } else {
        this.rows.forEach((selection) => payload.reservationToVersionMap[selection.item.reservationId.toString()] = selection.item.version)
      }

      let res
      try {
        res = await this.$store.dispatch('payments/addReferralPayment', payload)
      } catch (err) {
        this.showAlert({
          type: 'error',
          message: 'Error Submitting payment, Please try again',
        })
        this.loading = false
        logger.error(err)
        return
      }

      if (!res || !res.data.successful) {
        this.showAlert({
          type: 'error',
          message: res.data?.message
            ? res.data.message
            : 'Error Submitting Payment, Please try again',
        })
        logger.error(res)
        this.loading = false
      } else {
        this.showAlert({
          message: 'Provider successfully paid!',
        })
        this.loading = false
        this.cancel()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.pay-provider {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 500px;

  ::v-deep .v-text-field .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
  }

  &--content {
    flex: 1;
    padding: 0 48px;
  }

  &--submit-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    height: 71px;
    width: inherit;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }

  &--btn-spacer {
    width: 100%;
    min-height: 71px;
  }
}

.action-box {
  width: 50%;
  margin: auto;
  border: 1px solid $gray-light;
  border-radius: 4px;
}

h3 {
  margin-bottom: 15px;
}

.actions-row .v-btn:last-of-type {
  margin-right: 0;
}

.reservation-chip {
  margin: 4px 15px 20px 0;
}

::v-deep .v-textarea .v-text-field__details {
  display: none;
}

.send-email-checkbox {
  margin-top: 0;
}
</style>
