import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'

const httpService: HttpService = new HttpService()

export default {
  activateReservation(reservationId: number) {
    const url = `https://${baseUrl()}/reservations/${reservationId}/setIsActive`
    return httpService.post(url, {})
  },
  deactivateReservation(reservationId: number) {
    const url = `https://${baseUrl()}/reservations/${reservationId}/deactivate`
    return httpService.post(url, {})
  },
  updateReservationBookedBy(payload) {
    const url = `https://${baseUrl()}/reservations/updateReservationBookedBy`
    return httpService.post(url, payload)
  },
  updateQuoteCreatedBy(payload) {
    const url = `https://${baseUrl()}/quotes/updateQuoteCreatedBy`
    return httpService.post(url, payload)
  },
  updateReservationCustomerId(payload) {
    const url = `https://${baseUrl()}/customers/updateReservationCustomerId`
    return httpService.patch(url, payload)
  },
  updateConvertedQuoteCustomerId(payload) {
    const url = `https://${baseUrl()}/customers/updateConvertedQuoteCustomerId`
    return httpService.patch(url, payload)
  },
  updateReservationStatus(payload) {
    const url = `https://${baseUrl()}/reservations/updateReservationStatus`
    return httpService.patch(url, payload)
  },
  duplicateQuoteEnMasse(payload) {
    const url = `https://${baseUrl()}/quotes/duplicateQuoteEnMasse`
    return httpService.post(url, payload)
  },
  convertQuotesEnMasse(payload) {
    const url = `https://${baseUrl()}/quotes/convertQuotesEnMasse`
    return httpService.post(url, payload)
  },
  setFirstAndLastStop(payload) {
    const url = `https://${baseUrl()}/quotes/setFirstAndLastStop`
    return httpService.post(url, payload)
  },
  setReferralStatus(payload) {
    const url = `https://${baseUrl()}/reservation/setReferralStatus`
    return httpService.post(url, payload)
  },
  createReferralsEnMasse(payload) {
    const url = `https://${baseUrl()}/reservations/createReferralsEnMasse`
    return httpService.post(url, payload)
  },
  acceptReferralsEnMasse(payload) {
    const url = `https://${baseUrl()}/reservations/acceptReferralsEnMasse`
    return httpService.post(url, payload)
  },
  rejectReferralsEnMasse(payload) {
    const url = `https://${baseUrl()}/reservations/rejectReferralsEnMasse`
    return httpService.post(url, payload)
  },
  import(params) {
    const url = `https://${baseUrl()}/import/${params.entityType}`
    return httpService.post(url, params.file)
  },
}
