<template>
  <v-layout>
    <v-flex
      xs12
      style="display: flex; justify-content: end; align-items: center"
    >
      <v-tabs :value="selectedTripVehicleGroupIndex">
        <template
          v-for="(tripVehicleGroup, tripVehicleGroupIndex) in tripVehicleGroups"
        >
          <v-tab
            :id="`trip-vehicle-group-tab-${tripVehicleGroupIndex}`"
            :key="tripVehicleGroupIndex"
            style="display: flex; align-items: center"
            @click="setSelectedTripVehicleGroup(tripVehicleGroup)"
          >
            <div style="display: flex; align-items: center">
              <div
                style="
                  height: 9px;
                  width: 9px;
                  margin-right: 8px;
                  border-radius: 25px;
                "
                :style="`background: ${tripVehicleGroup.color}`"
              ></div>
              <p class="margin-a-0" style="font-weight: 600">
                {{ tripVehicleGroup.name }}
              </p>
              <v-menu
                v-if="tripVehicleGroup.selected && !isModeView"
                v-model="vehicleActionsOpen"
                :close-on-content-click="false"
                offset-y
                bottom
                left
                origin="top right"
              >
                <template #activator="{ on: vehicleActionsOn }">
                  <div class="d-flex" v-on="vehicleActionsOn">
                    <CRIcon
                      id="vehicle-actions-vert"
                      class="margin-l-2"
                      color="primary"
                      :view-box="'0 0 24 24'"
                    >
                      more_vert
                    </CRIcon>
                  </div>
                </template>
                <v-list class="list" style="width: 240px">
                  <v-menu
                    :close-on-content-click="false"
                    offset-x
                    bottom
                    right
                    origin="top left"
                  >
                    <template #activator="{ on: copyTimesOn }">
                      <v-list-tile
                        style="cursor: pointer"
                        @click="openEditTripVehicleSidebar(tripVehicleGroup)"
                      >
                        <v-list-tile-title>
                          <span>Edit Group</span>
                        </v-list-tile-title>
                      </v-list-tile>
                      <v-list-tile
                        :id="`trip-details-vehicle-actions-copy-times-${tripVehicleGroup.tripVehicleGroupHash}`"
                        :key="`trip-details-vehicle-actions-copy-times-${tripVehicleGroup.tripVehicleGroupHash}`"
                        style="cursor: pointer"
                        v-on="copyTimesOn"
                        @click="
                          setCopyGroups(tripVehicleGroup.tripVehicleGroupHash)
                        "
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                          "
                        >
                          <v-list-tile-title>
                            <span>Copy times from</span>
                          </v-list-tile-title>
                          <CRIcon class="margin-l-2" :view-box="'0 0 24 24'">
                            arrow_move_right
                          </CRIcon>
                        </div>
                      </v-list-tile>
                    </template>
                    <v-list class="list">
                      <v-radio-group v-model="selectedCopy">
                        <template
                          v-for="(copyGroup, copyGroupIndex) in copyGroups"
                        >
                          <v-radio
                            :id="`trip-details-copy-radio-${copyGroupIndex}`"
                            :key="`trip-details-copy-radio-${copyGroupIndex}`"
                            :value="copyGroup.tripVehicleGroupHash"
                            color="primary"
                            hide-details
                            :label="copyGroup.name"
                            style="padding: 10px"
                          />
                        </template>
                      </v-radio-group>

                      <div class="menu-btn">
                        <CRButton
                          :id="`trip-details-copy-btn`"
                          color="primary"
                          :disabled="!selectedCopy"
                          @click="
                            copyToVehicle(tripVehicleGroup.tripVehicleGroupHash)
                          "
                        >
                          Apply
                        </CRButton>
                      </div>
                    </v-list>
                  </v-menu>
                  <v-menu
                    :close-on-content-click="false"
                    offset-x
                    bottom
                    right
                    origin="top left"
                  >
                    <template #activator="{ on: combineTimesOn }">
                      <v-list-tile
                        :id="`trip-details-vehicle-actions-combine-times-${tripVehicleGroup.tripVehicleGroupHash}`"
                        :key="`trip-details-vehicle-actions-combine-times-${tripVehicleGroup.tripVehicleGroupHash}`"
                        style="cursor: pointer"
                        @click="
                          setCombineGroups(
                            tripVehicleGroup.tripVehicleGroupHash
                          )
                        "
                        v-on="combineTimesOn"
                      >
                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            width: 100%;
                          "
                        >
                          <v-list-tile-title>
                            <span>Combine vehicle time with</span>
                          </v-list-tile-title>
                          <CRIcon class="margin-l-2" :view-box="'0 0 24 24'">
                            arrow_move_right
                          </CRIcon>
                        </div>
                      </v-list-tile>
                    </template>
                    <v-list class="list">
                      <template
                        v-for="(combineGroup,
                        combineGroupIndex) in combineGroups"
                      >
                        <v-checkbox
                          :id="`trip-details-combine-checkbox-${combineGroupIndex}`"
                          :key="`trip-details-combine-checkbox-radio-${combineGroupIndex}`"
                          v-model="combineGroup.checked"
                          color="primary"
                          hide-details
                          style="padding: 10px"
                          :label="combineGroup.group.name"
                        />
                      </template>

                      <div class="menu-btn">
                        <CRButton
                          :id="`trip-details-combine-btn`"
                          color="primary"
                          :disabled="
                            combineGroups.every((group) => !group.checked)
                          "
                          @click="
                            combineTripVehicleGroups(
                              tripVehicleGroup.tripVehicleGroupHash
                            )
                          "
                        >
                          Apply
                        </CRButton>
                      </div>
                    </v-list>
                  </v-menu>
                  <v-list-tile
                    style="cursor: pointer"
                    @click="removeTripVehicleGroup(tripVehicleGroup)"
                  >
                    <v-list-tile-title>
                      <span>Remove Vehicle Group</span>
                    </v-list-tile-title>
                  </v-list-tile>
                </v-list>
              </v-menu>
            </div>
          </v-tab>
        </template>
      </v-tabs>
      <v-btn
        v-if="!isModeView"
        icon
        dark
        color="primary"
        @click="openAddTripVehicleSidebar"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      vehicleActionsOpen: false,
      selectedCopy: null,
      copyGroups: [],
      combineGroups: [],
    }
  },
  computed: {
    mode() {
      return this.$store.getters['quoteForm/getMode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    trip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    currentTripIndex() {
      return this.$store.getters['quoteForm/getCurrentTripIndex']
    },
    tripVehicleGroups() {
      return this.trip.tripVehicleGroups
    },
    selectedTripVehicleGroupIndex() {
      if (!this.tripVehicleGroups) {
        return -1
      }
      return this.tripVehicleGroups.findIndex(
        (tripVehicleGroup) => tripVehicleGroup.selected
      )
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      unselectAllTripVehicleGroupsModule:
        'quoteForm/unselectAllTripVehicleGroups',
      setSelectedTripVehicleGroupModule:
        'quoteForm/setSelectedTripVehicleGroup',
      copyDatesFromGroupToGroupModule: 'quoteForm/copyDatesFromGroupToGroup',
      combineGroupsModule: 'quoteForm/combineGroups',
      removeTripVehicleGroupModule: 'quoteForm/removeTripVehicleGroup',
    }),
    setSelectedTripVehicleGroup(
      tripVehicleGroup,
      tripIndex = this.currentTripIndex
    ) {
      const tripVehicleGroupIndex = this.tripVehicleGroups.findIndex(
        (group) => group == tripVehicleGroup
      )
      this.selectedCopy = null
      this.unselectAllTripVehicleGroupsModule({ tripIndex })
      this.setSelectedTripVehicleGroupModule({
        tripVehicleGroupIndex,
        tripIndex,
        selected: true,
      })
    },
    removeTripVehicleGroup(tripVehicleGroup) {
      const tripVehicleGroupIndex = this.tripVehicleGroups.findIndex(
        (group) => group == tripVehicleGroup
      )
      this.removeTripVehicleGroupModule({
        tripVehicleGroupIndex,
        tripIndex: this.currentTripIndex,
      })

      this.vehicleActionsOpen = false
    },
    openAddTripVehicleSidebar() {
      const component = () => import('@/components/TripVehicleSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Add Group',
          mode: 'add',
        },
        component,
      })
      this.vehicleActionsOpen = false
    },
    openEditTripVehicleSidebar(tripVehicleGroup) {
      const tripVehicleGroupIndex = this.tripVehicleGroups.findIndex(
        (group) => group == tripVehicleGroup
      )
      const component = () => import('@/components/TripVehicleSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Edit Group',
          mode: 'edit',
          tripVehicleGroupIndex,
        },
        component,
      })
      this.vehicleActionsOpen = false
    },
    setCopyGroups(currentHash) {
      this.copyGroups = this.tripVehicleGroups.filter(
        (group) => group.tripVehicleGroupHash !== currentHash
      )
    },
    setCombineGroups(currentHash) {
      this.combineGroups = this.tripVehicleGroups
        .filter((group) => group.tripVehicleGroupHash !== currentHash)
        .map((group) => {
          return {
            checked: false,
            group,
          }
        })
    },
    copyToVehicle(toHash) {
      this.vehicleActionsOpen = false
      const fromHash = this.selectedCopy
      this.copyGroups = []
      this.copyDatesFromGroupToGroupModule({
        tripIndex: this.currentTripIndex,
        fromHash,
        toHash,
      })
    },
    combineTripVehicleGroups(toHash) {
      this.vehicleActionsOpen = false
      const fromHashes = this.combineGroups
        .filter((group) => group.checked)
        .map((group) => group.group.tripVehicleGroupHash)
      this.combineGroupsModule({
        tripIndex: this.currentTripIndex,
        toHash,
        fromHashes,
      })
      this.combineGroups = []
    },
  },
}
</script>

<style lang="scss" scoped>
.list {
  padding: 0;
}
.menu-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
}
::v-deep .v-tabs__div {
  max-width: fit-content !important;
}
</style>
