import { render, staticRenderFns } from "./QuoteFormTripVehicleSelector.vue?vue&type=template&id=5fa18e8c&scoped=true"
import script from "./QuoteFormTripVehicleSelector.vue?vue&type=script&lang=js"
export * from "./QuoteFormTripVehicleSelector.vue?vue&type=script&lang=js"
import style0 from "./QuoteFormTripVehicleSelector.vue?vue&type=style&index=0&id=5fa18e8c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa18e8c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VFlex,VLayout,VSelect})
