import { render, staticRenderFns } from "./QuoteFormFooter.vue?vue&type=template&id=d2c2dba6&scoped=true"
import script from "./QuoteFormFooter.vue?vue&type=script&lang=js"
export * from "./QuoteFormFooter.vue?vue&type=script&lang=js"
import style0 from "./QuoteFormFooter.vue?vue&type=style&index=0&id=d2c2dba6&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2c2dba6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VFlex,VForm,VLayout,VSpacer})
