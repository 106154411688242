var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-0 mt-3 summary-by-vehicle"},[_c('v-layout',{ref:"header",staticClass:"mb-2 pt-2 pb-2",attrs:{"row":""}},[_c('v-layout',{staticClass:"header-vehicle",attrs:{"column":""}},[_vm._v("Vehicle")]),_c('v-layout',{staticClass:"header-date",attrs:{"column":""}},[_vm._v("Date")]),_vm._l((_vm.tripAddresses),function(tripAddress,tripAddressIndex){return _c('v-layout',{key:tripAddressIndex,staticClass:"header-address",attrs:{"column":""}},[_vm._v(" "+_vm._s(tripAddress.address.title || tripAddress.address.addressName || `Address #${tripAddressIndex + 1}`)+" ")])})],2),_vm._l((_vm.tripVehicleGroups),function(tripVehicleGroup,tripVehicleGroupIndex){return _c('v-layout',{key:tripVehicleGroupIndex,attrs:{"column":""}},[_c('v-layout',{staticClass:"vehicle-row pb-1 pt-1",style:(`border-color: ${tripVehicleGroup.color}`),attrs:{"row":""}},[_c('v-layout',{staticClass:"ml-2 vehicle-group-name",attrs:{"column":""}},[_vm._v(" "+_vm._s(tripVehicleGroup.name || 'All Vehicle Groups')+" ")]),_c('v-layout',{attrs:{"column":""}},_vm._l((_vm.getSortedDates(tripVehicleGroup)),function(date,dateIndex){return _c('v-layout',{key:dateIndex,attrs:{"row":""}},[_c('v-layout',{staticClass:"trip-date",attrs:{"column":""}},[_vm._v(" "+_vm._s(_vm.formatDate(date))+" ")]),_c('v-layout',{staticClass:"address-column",attrs:{"column":""}},_vm._l((_vm.getNumberOfRows(
                tripVehicleGroup,
                date
              )),function(row,rowIndex){return _c('v-layout',{key:rowIndex,ref:"time-row",refInFor:true,staticClass:"time-row",attrs:{"row":""}},_vm._l((_vm.getTimesForRow(
                  tripVehicleGroup,
                  date,
                  rowIndex
                )),function(time,timeIndex){return _c('v-layout',{key:timeIndex,staticClass:"time",style:({
                  'background-color': _vm.shouldShowBackgroundColor(
                    tripVehicleGroup,
                    dateIndex,
                    rowIndex
                  )
                    ? _vm.lightenColor(tripVehicleGroup.color)
                    : '',
                }),attrs:{"column":""}},[_vm._v(" "+_vm._s(`${ _vm.isFinalDropoffDate(tripVehicleGroup, date, time) ? 'Final DO: ' : '' }${_vm.formatTime(time)}`)+" ")])}),1)}),1)],1)}),1)],1),(tripVehicleGroupIndex < _vm.tripVehicleGroups.length - 1)?_c('v-layout',{style:(`width: ${_vm.dividerWidth}`),attrs:{"row":""}},[_c('v-divider',{staticClass:"mt-2 mb-2"})],1):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }