
import { Component, Prop, Vue, Watch, Inject } from 'vue-property-decorator'
import { currencyFilter } from '@/utils/currency'
import { CreditCardType, PaymentMethodTypeKey, SplitFeatureFlag } from '@/utils/enum'
import { snakeToTitleCase } from '@/utils/string'
import CreditCardSelector from '@/components/CreditCardSelector.vue'
import { isNotEmpty, isRequired } from '@/utils/validators'
import * as logger from '@/utils/logger'
import { filter } from '@/utils/filter'
import { EventBus } from '@/utils/event-bus'
import { mask } from 'vue-the-mask'
import { nonMarketplaceCheckoutDetailByHash } from '@/services/checkoutDetail'
import { StagePaymentMethod } from '@/models/dto'

const DEFAULT_PAYMENT_METHOD_OPTIONS = [
  { label: 'Credit Card', value: PaymentMethodTypeKey.CreditCard },
  { label: 'ACH', value: PaymentMethodTypeKey.ACH },
  { label: 'Check', value: PaymentMethodTypeKey.Check },
  { label: 'Other', value: PaymentMethodTypeKey.Other },
]

@Component({
  components: {
    CreditCardSelector,
  },
  directives: {
    mask,
  },
})
export default class CollectPaymentNew extends Vue {
  @Inject({ default: false }) readonly isInvoice!: boolean

  @Prop({ default: null }) readonly id!: string | null
  @Prop({ default: () => ({}) }) readonly row!: Record<string, any>
  @Prop({ default: () => [] }) readonly rows!: Array<any>
  @Prop({ required: true }) readonly balance!: number
  @Prop({ default: '' }) readonly quoteHash!: string
  @Prop({ default: undefined }) readonly quoteId!: number | undefined
  @Prop({ default: undefined }) readonly quoteMessage!: string | undefined
  @Prop() readonly disableEmail!: boolean
  @Prop() readonly collectPaymentDialog!: boolean
  @Prop() readonly formatFromTableRow!: boolean
  @Prop({ default: () => ({}) }) readonly invoice!: Record<string, any>
  @Prop({ default: null }) readonly defaultSelectedPaymentMethod!: PaymentMethodTypeKey
  @Prop({ default: false }) readonly allowHoldFuturePayment!: boolean
  @Prop({ type: Boolean , default: false }) readonly isOperatorCancel!: boolean
  @Prop({ type: Boolean , default: false }) readonly isCustomerCancel!: boolean
  @Prop({ default: false }) readonly disableSubmit!: boolean
  @Prop({ default: () => [] }) readonly operatorPaymentProfiles!: Array<any>
  @Prop({ default: -1 }) readonly minAmount!: number
  @Prop({ default: null }) readonly defaultSelectedCard!: Record<string, any> | null
  @Prop({ default: null }) readonly defaultSelectedCardIndex!: number | null
  @Prop({ default: null }) readonly tooltipText!: string | null
  @Prop({ default: null }) readonly tooltipStyle!: Record<string, any> | null

  currencyFilter = currencyFilter
  isNotEmpty = isNotEmpty
  isRequired = isRequired
  loading = false
  partialPaymentAmount = null
  paymentProfiles = []
  selectedPaymentMethod = null
  paymentMethodOptions = []
  paymentMethodTypeIdToKey = {
    1: PaymentMethodTypeKey.CreditCard,
    2: PaymentMethodTypeKey.ACH,
    3: PaymentMethodTypeKey.Check,
  }
  singlePrefixToCardType = {
    4: CreditCardType.Visa,
    5: CreditCardType.Mastercard,
    2: CreditCardType.Mastercard,
    6: CreditCardType.Discover,
  }
  doublePrefixToCardType = {
    34: CreditCardType.AmericanExpress,
    37: CreditCardType.AmericanExpress,
    30: CreditCardType.Diners,
    36: CreditCardType.Diners,
    38: CreditCardType.Diners,
  }
  defaultPaymentGateway = undefined
  officeNotes = ''
  customerNotes = ''
  sendEmail = true
  manualPaymentNumber = null
  creditCard = null
  quote: any = null
  termsSigned = false

  @Watch('balance')
  onBalanceChanged(newBalance: number) {
    this.partialPaymentAmount = newBalance
  }

  @Watch('quoteHash', { immediate: true })
  onQuoteHashChanged(hash: string) {
    if (!hash) return;
    nonMarketplaceCheckoutDetailByHash(hash).then((quoteResponse) => {
      this.quote = quoteResponse?.data;
    });
  }

  @Watch('row', { immediate: true })
  onRowChanged() {
    this.customerPaymentProfiles();
  }

  @Watch('rows', { immediate: true })
  onRowsChanged() {
    this.customerPaymentProfiles();
  }

  @Watch('operatorPaymentProfiles', { deep: true })
  onOperatorPaymentProfilesChanged(newOperatorPaymentProfiles: any[]) {
    if (newOperatorPaymentProfiles?.length) {
      this.paymentProfiles = newOperatorPaymentProfiles;
    }
  }

  @Watch('defaultSelectedCard')
  onDefaultSelectedCardChanged(newSelectedCard: any) {
    if (newSelectedCard) {
      this.creditCard = newSelectedCard;
    }
  }

  @Watch('creditCard')
  onCreditCardChanged(newCreditCard: any) {
    this.$emit('selected-card', newCreditCard);
  }

  @Watch('partialPaymentAmount')
  onPartialPaymentAmountChanged(newPartialPaymentAmount: any) {
    this.$emit('amount-updated', newPartialPaymentAmount);
  }

  get cardType(): string {
    const { cardNumber } = this.creditCard?.newCard

    if (!cardNumber) {
      return ''
    }

    const firstDigit = cardNumber[0]
    if (this.singlePrefixToCardType.hasOwnProperty(firstDigit)) {
      return this.singlePrefixToCardType[firstDigit]
    } else {
      return this.getSpecialCardType(cardNumber.substring(0, 2))
    }
  }

  get cardNumberRawValue(): string {
    if (this.creditCard?.newCard?.cardNumber) {
      return this.creditCard.newCard.cardNumber.split(' ').join('')
    }
    return null
  }

  get shouldShowLowerDetails(): boolean {
    if (this.selectedPaymentMethod === PaymentMethodTypeKey.Hold) {
      return true
    }

    if (this.isOperatorCancel) {
      return false
    }

    if (!this.selectedPaymentMethod) {
      return false
    }

    return true
  }

  get companyId(): number {
    return this.row?.companyId || this.$attrs.companyId || this.invoice.company?.companyId
  }

  get checkoutPageId(): number {
    return [
      this.row.checkoutPageId,
      this.row.companyCheckoutPageId,
      this.rows?.[0]?.item?.checkoutPageId,
      this.rows?.[0]?.item?.companyCheckoutPageId,
    ].find((e) => e)
  }

  get reservationIds(): number[] {
    if (Object.keys(this.row).length) {
      return [this.row.reservationId]
    }

    return this.rows.map((row) =>
      row.item ? row.item.reservationId : row.reservationId
    )
  }

  get managedIds(): number[] {
    if (Object.keys(this.row).length) {
      return [this.row.managedId]
    }

    return this.rows.map((row) =>
      row.item ? row.item.managedId : row.managedId
    )
  }

  get reservationToVersionMap(): Record<number, number> {
    const map = {}

    if (Object.keys(this.row).length) {
      map[this.row.reservationId.toString()] = this.row.version
    } else {
      this.rows.forEach(
        (selection) =>
          (map[selection.item.reservationId] = selection.item.version)
      )
    }

    return map
  }

  get submitBtnText(): string {
    if (this.isCustomerCancel) {
      return `Complete Cancellation (${currencyFilter(this.partialPaymentAmount)})`
    }

    if ([PaymentMethodTypeKey.ACH,
        PaymentMethodTypeKey.Check,
        PaymentMethodTypeKey.Other,].includes(this.selectedPaymentMethod)) {
      return `Collect ${currencyFilter(this.partialPaymentAmount)}`
    }

    return `Charge ${currencyFilter(this.partialPaymentAmount)}`
  }

  // Validate form without triggering errors--used for determining if the
  // submit button is active
  get silentValidatePaymentForm() {
    if (typeof this.validatePartialPayment(this.partialPaymentAmount) === 'string') {
      return false
    }

    switch (this.selectedPaymentMethod) {
      case PaymentMethodTypeKey.CreditCard:
        if (this.creditCard?.newCard) {
          let { address } = this.creditCard.newCard
          if (!address.postalCode) {
            return false
          }
        } else {
          if (!this.creditCard?.customerPaymentProfileId) {
            return false
          }
        }
        return true
      case PaymentMethodTypeKey.Check:
      case PaymentMethodTypeKey.ACH:
      case PaymentMethodTypeKey.Other:
        if (!this.manualPaymentNumber) {
          return false
        }
        return true
      default:
        return true
    }
  }

  get previewRows(): any[] {
    return this.rows.slice(0, 3)
  }

  get allowedBalancePaymentMethod(): StagePaymentMethod {
    if (this.row?.balancePaymentMethods.length === 0) {
      return null
    }
    return this.row?.balancePaymentMethods.find(
      (method: StagePaymentMethod) => method.isAllowed === 1
    )
  }

  get isCreditCardPaymentMethod(): boolean {
    return this.selectedPaymentMethod === PaymentMethodTypeKey.CreditCard
  }

  get isCheckPaymentMethod(): boolean {
    return this.selectedPaymentMethod === PaymentMethodTypeKey.Check
  }

  get isWirePaymentMethod(): boolean {
    return this.selectedPaymentMethod === PaymentMethodTypeKey.ACH
  }

  get isOtherPaymentMethod(): boolean {
    return this.selectedPaymentMethod === PaymentMethodTypeKey.Other
  }

  get checkWireNumberLabel(): string {
    if (this.isCheckPaymentMethod) {
      return 'Check Number'
    }
    return 'Configuration Number'
  }

  async mounted(): Promise<void> {
    this.partialPaymentAmount = this.balance

    if (this.disableEmail) {
      this.sendEmail = false
    }

    this.setSelectedPaymentMethod()
  }

  setSelectedPaymentMethod() {
    this.paymentMethodOptions = []

    if (this.isInvoice || this.isOperatorCancel || this.isCustomerCancel) {
      this.paymentMethodOptions.push(DEFAULT_PAYMENT_METHOD_OPTIONS[0])
      if (this.allowHoldFuturePayment) {
        this.paymentMethodOptions.push({ label: 'Hold', value: PaymentMethodTypeKey.Hold })
      }
      this.selectedPaymentMethod = PaymentMethodTypeKey.CreditCard
      return
    }

    this.paymentMethodOptions = DEFAULT_PAYMENT_METHOD_OPTIONS
    const allowedPaymentMethodTypeId = this.allowedBalancePaymentMethod?.paymentMethodId

    if (this.defaultSelectedPaymentMethod) {
      this.selectedPaymentMethod = this.defaultSelectedPaymentMethod
    } else if (allowedPaymentMethodTypeId) {
      this.selectedPaymentMethod = this.paymentMethodTypeIdToKey[allowedPaymentMethodTypeId]
    }
  }

  newCardSelected(e: Event): void {
    this.$emit('new-card-selected', e)
  }

  getSpecialCardType(firstTwoDigits: string) {
    if (this.doublePrefixToCardType.hasOwnProperty(firstTwoDigits)) {
      return this.doublePrefixToCardType[firstTwoDigits]
    }
    return null
  }

  validateCreditCardSelector(): boolean {
    return (this.$refs['credit-card-selector'] as any).validate()
  }

  remove(item): void {
    this.$emit('remove-item', item)
    if (this.rows.length < 2) {
      this.cancel()
    }
  }

  cancel(): void {
    this.loading = false
    this.$emit('row-collapse')
    this.$emit('close-modal')
    this.resetPaymentForm()
  }

  resetPaymentForm(): void {
    this.selectedPaymentMethod = null
    this.creditCard = null
    this.sendEmail = true
    this.manualPaymentNumber = null
    this.officeNotes = ''
    this.customerNotes = ''
    if (this.$refs['credit-card-selector']) {
      (this.$refs['credit-card-selector'] as any).clearNewCard()
    }
  }

  validatePartialPayment(payment: string): string | boolean {
    let paymentAmount = parseFloat(payment)
    let balanceAmount = this.balance

    if (paymentAmount <= 0) {
      return 'Payment Must Be Greater Than Zero'
    }
    if (paymentAmount > balanceAmount && this.isCustomerCancel) {
      return 'Payment can’t be more than cancellation fees due'
    }
    if (paymentAmount > balanceAmount && !this.isOperatorCancel) {
      return 'Payment Must Not Be Greater Than Balance'
    }
    return true
  }

  validatePaymentForm(): boolean {
    if (this.isCreditCardPaymentMethod) {
      let cardIsValid = (this.$refs['credit-card-selector'] as any).validate()
      if (!cardIsValid) {
        this.$store.dispatch('app/showAlert', {
          type: 'error',
          message: `Error collecting payment: Please Enter Card Information`,
        })
        this.loading = false
        this.$forceUpdate()
        return false
      }
    }

    let isValid = (this.$refs['payment-form'] as any).validate()
    if (!isValid) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: `Error collecting payment: Payment Form Is Not Valid`,
      })
      this.loading = false
      return false
    }

    if (this.isInvoice && !this.termsSigned) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: `Error collecting payment: Terms Must Be Agreed To`,
      })
      this.loading = false
      return false
    }

    return true
  }

  async submit(e: Event): Promise<void> {
    if (this.loading) {
      return
    }

    if (this.disableSubmit) {
      return
    }

    if (!this.silentValidatePaymentForm) {
      return
    }

    this.loading = true
    let isValid = this.validatePaymentForm()
    if (!isValid) {
      return
    }

    if (this.isCustomerCancel) {
      const customerPaymentProfileId = this.creditCard?.customerPaymentProfileId
      const payload = {
        cancellationPenaltyAmount: parseFloat(this.partialPaymentAmount) || 0,
        paymentProfileId: customerPaymentProfileId,
        sendPaymentEmail: this.sendEmail,
      }
      this.$emit('confirm-cancellation', payload)
      return
    }

    if (this.isCreditCardPaymentMethod) {
      if (this.creditCard?.newCard) {
        this.collectPaymentWithNewCard()
      } else {
        this.collectPaymentWithExistingCard()
      }
      return
    } else {
      this.collectPaymentManually()
      return
    }
  }

  async collectPaymentWithNewCard(): Promise<void> {
    this.loading = true

    const cardForm = this.$refs['credit-card-selector'] as any
    const tokenizeResponse = await cardForm.tokenizeCard()
    const { paymentGateway, tokens, formattedPaymentInfo } = tokenizeResponse

    // Build payload for payments/collectPayment
    if (paymentGateway && tokens) {
      let payload = {
        ...formattedPaymentInfo,
        payment_gateway: paymentGateway,
        nonces: tokens,
        payment_method: 'credit_card',
        reservationIds: this.reservationIds,
        reservationToVersionMap: this.reservationToVersionMap,
        sendEmail: this.sendEmail,
        notes: {
          note: this.officeNotes,
        },
        customerNotes: {
          note: this.customerNotes,
        },
        amount: this.partialPaymentAmount || 0,
      }

      const paymentAction = 'payments/collectPayment'
      const paymentResponse = await this.handlePayment(paymentAction, { payload })
      this.loading = false
      if (paymentResponse.successful) {
        this.$emit('submit')
      }
    } else {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: `Error collecting payment: Invalid card information`,
      })
      this.loading = false
      this.$forceUpdate()
    }
  }

  async collectPaymentWithExistingCard(): Promise<void> {
    this.loading = true
    await this.$forceUpdate()

    const {
      customerPaymentProfileId,
      mask,
      typeLabel,
      companyPaymentGateway,
    } = this.creditCard

    const params = {
      payload: {
        payment_method: 'credit_card',
        paymentProfileId: customerPaymentProfileId,
        payment_gateway: {
          id: companyPaymentGateway.companyPaymentGatewayId,
          key: companyPaymentGateway.paymentGatewayTypeKey,
        },
        reservationIds: this.reservationIds,
        reservationToVersionMap: this.reservationToVersionMap,
        notes: {
          note: this.officeNotes
        },
        customerNotes: {
          note: this.customerNotes
        },
        sendEmail: this.sendEmail,
        amount: this.partialPaymentAmount || 0,
      },
    }

    try {
      await this.handlePayment('payments/collectPayment', params)
    } catch (err) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'Error collecting payment, please try again.',
      })
      this.loading = false
      logger.error(err)
    }
    this.loading = false
  }

  async collectPaymentManually(): Promise<void> {
    const payload = {
      payment_method: this.selectedPaymentMethod,
      billing: {
        check_number: this.manualPaymentNumber,
      },
      notes: {
        note: this.officeNotes,
      },
      customerNotes: {
        note: this.customerNotes,
      },
      amount: this.partialPaymentAmount || 0,
      sendEmail: this.sendEmail,
      reservationIds: this.reservationIds,
      reservationToVersionMap: this.reservationToVersionMap,
    }

    const params = {
      payload,
    }

    await this.handlePayment('payments/collectPayment', params)
    this.loading = false
  }

  async handlePayment(action, params): Promise<any> {
    const addPaymentResponse = await this.$store
      .dispatch(action, params)
      .catch((e) => e.response)

    if (!addPaymentResponse.data.successful) {
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: `Error collecting payment: ${addPaymentResponse.data.message}`,
      })
      logger.error(addPaymentResponse.data)
      this.loading = false
      this.$forceUpdate()
      return addPaymentResponse.data
    } else {
      this.$store.dispatch('app/showAlert', {
        message: this.quoteMessage || 'Payment successfully collected!',
      })
      this.$forceUpdate()
      this.$emit('refresh-query-request')
      this.cancel()

      EventBus.$emit('global-table-view-refresh')
      return addPaymentResponse.data
    }
  }

  async customerPaymentProfiles(): Promise<void> {
    const filters = filter()

    let customerId = this.row?.customerId

    if (!customerId) {
      const resDetail = await this.$store.dispatch(
        'reservations/reservationv2ById',
        {
          reservationId: this.managedIds[0] || this.reservationIds[0],
        }
      )
      customerId = resDetail.data.customerId
    }

    if (!customerId) {
      this.paymentProfiles = []
      return
    }

    const parentFilter = filters.createParent('and')
    const customerIdFilter = {
      column: {
        _t_id: 'customerIdFilter_filter',
        prop: 'customerId',
        filterType: 'eq',
      },
      value: customerId,
    }
    const showToCustomerFilter = {
      column: {
        _t_id: 'showToCustomerFilter_filter',
        prop: 'showToCustomer',
        filterType: 'eq',
      },
      value: true,
    }
    filters.add(parentFilter, customerIdFilter)
    filters.add(parentFilter, showToCustomerFilter)

    const params = {
      pageSize: -1,
      filters: filters.asQueryParams(),
    }
    const customerPaymentProfilesResponse = await this.$store.dispatch('payments/getCustomerPaymentProfiles', params)
    const paymentProfilesResults = customerPaymentProfilesResponse?.data?.resultList || []
    this.paymentProfiles = paymentProfilesResults.map((p) => {
      const {
        customerPaymentProfileId,
        mask,
        label,
        address,
        accountHolderName,
        expiration,
        companyPaymentGateway,
      } = p
      return {
        customerPaymentProfileId,
        mask,
        typeLabel: label,
        address,
        accountHolderName,
        expiration,
        companyPaymentGateway,
      }
    })
  }
}
