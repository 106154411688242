var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-layout',{staticClass:"action-links",attrs:{"justify-end":"","row":""}},[_vm._l((_vm.menu.filter((m) => m.quickAccess)),function(item,idx){return _c('div',{key:`quotes-table-actions-col-${_vm.rowProps.index}-action-link-${idx}-${
      _vm.rowProps.item.quoteId ? _vm.rowProps.item.quoteId : ''
    }`,staticClass:"action-link-container",attrs:{"id":`quotes-table-actions-col-${_vm.rowProps.index}-action-link-${idx}`}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(action){return [_c('span',_vm._g({staticStyle:{"max-height":"24px"},on:{"click":function($event){$event.stopPropagation();return (() => _vm.handleClick(item)).apply(null, arguments)}}},action.on),[(item.click)?_c('CRIcon',{attrs:{"id":`action-require-wrapper-${
              item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
            }`,"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox,"width":item.width,"height":item.height}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e(),(item.path)?_c('router-link',{staticClass:"action-link",attrs:{"id":`action-require-wrapper-${
              item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
            }`,"to":{ path: item.path() },"target":item.target ? item.target : '_self'}},[_c('span',{staticStyle:{"max-height":"24px"}},[_c('CRIcon',{attrs:{"color":(item.color && item.color()) || 'primary',"icon-name":item.id,"view-box":item.viewBox,"width":item.width,"height":item.height}},[_vm._v(" "+_vm._s(item.icon())+" ")])],1)]):_vm._e(),(item.href)?_c('a',{staticClass:"action-link",attrs:{"id":`action-require-wrapper-${
              item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
            }`,"href":item.href(),"target":item.target ? item.target : '_self'}},[_c('CRIcon',{attrs:{"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox,"width":item.width,"height":item.height}},[_vm._v(" "+_vm._s(item.icon())+" ")])],1):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title()))])])],1)}),_c('div',{staticClass:"more-vert-parent"},[_c('v-menu',{attrs:{"bottom":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: { click } }){return [_c('CRIcon',{staticClass:"cursor-pointer",staticStyle:{"max-height":"24px"},attrs:{"id":`quote-form-${_vm.rowProps.index}-table-row-kebab-menu`,"color":"primary","view-box":"0 0 24 24"},nativeOn:{"click":function($event){return click.apply(null, arguments)}}},[_vm._v(" more_vert ")])]}}])},[_c('v-list',[_vm._l((_vm.menu.filter((m) =>
            m.condition ? m.condition() : true
          )),function(item,i){return [(item.condition ? item.condition() : true)?_c('v-list-tile',{key:`action-list-tile-${i}-${item.title ? item.title : ''}`,attrs:{"id":`action-require-wrapper-${
              item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : i
            }`},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('v-list-tile-title',[_c('div',{staticClass:"action-link"},[(item.icon)?_c('CRIcon',{attrs:{"color":(item.color && item.color()) || 'primary',"view-box":item.viewBox,"width":item.width,"height":item.height}},[_vm._v(" "+_vm._s(item.icon())+" ")]):_vm._e(),_c('span',{staticClass:"action-text"},[_vm._v(_vm._s(item.title()))])],1)])],1):_vm._e()]})],2)],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }