import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Affiliate, CreateAffiliateRequest, SaveAffiliateRequest } from '@/models/dto/Affiliate'
import { EldCredential } from '@/models/EldCredential'
import { Vehicle } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getAffiliates(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Affiliate>>> {
    const { pageSize = 100, page = 1, sorts = null, filters = null, filterAuthForHire = false, filterAuthForPassenger = false } = params
    let query = `page=${page}&pageSize=${pageSize}` // this is a temp
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query += `&filterAuthorizedForHire=${filterAuthForHire}&filterAuthorizedForPassenger=${filterAuthForPassenger}`
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/affiliates?${query}`
    return httpService.get(url)
  },
  getAffiliatesMap(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Affiliate>>> {
    const { pageSize = 100, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}` // this is a temp
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = baseUrl()
    const url = `https://${host}/tables/affiliates/maps?${query}`
    return httpService.get(url)
  },
  getAffiliatesMapVehicles(companyId: number): Promise<AxiosResponse<Vehicle[]>> {
    const host = baseUrl()
    const url = `https://${host}/tables/affiliates/maps/vehicles/${companyId}`

    return httpService.get(url)
  },
  saveAffiliateNotes(params: { id: number, payload: SaveAffiliateRequest }): Promise<AxiosResponse> {
    const host = baseUrl('api')
    const url = `https://${host}/affiliates/${params.id}`
    return httpService.patch(url, params.payload)
  },
  createAffiliate(params: { payload: CreateAffiliateRequest }): Promise<AxiosResponse> {
    const host = baseUrl('api')
    const url = `https://${host}/affiliates`
    return httpService.post(url, params.payload)
  },
  getAffiliateEldCredentialDetails(params: { id: number }): Promise<AxiosResponse<EldCredential>> {
    const host = baseUrl('api')
    const url = `https://${host}/eldCredentials/${params.id}`
    return httpService.get(url)
  },
  getAllAffiliateEldCredentialDetails(params: { payload: any }): Promise<AxiosResponse<EldCredential[]>> {
    const host = baseUrl('api')
    const url = `https://${host}/eldCredentials/all`
    return httpService.post(url, params.payload)
  },
}
