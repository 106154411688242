<template>
  <div>
    <v-layout justify-start align-center>
      <v-flex shrink class="text-sm-left text-xs-center">
        <v-btn class="btn-secondaryaction" @click="calendarValue = today">
          TODAY
        </v-btn>
      </v-flex>
      <v-icon
        color="primary"
        class="month-arrow"
        left
        @click="$refs.calendar.prev()"
      >
        keyboard_arrow_left
      </v-icon>
      <v-icon
        color="primary"
        class="month-arrow"
        right
        @click="$refs.calendar.next()"
      >
        keyboard_arrow_right
      </v-icon>
      <v-flex>
        <div class="recurrences-calendar-trip-count">
          Trip Count: {{ 1 + recurrenceEventDateList.length }}
        </div>
      </v-flex>
      <v-spacer grow />
    </v-layout>

    <br />

    <v-layout>
      <v-flex>
        <v-sheet height="500">
          <v-calendar
            ref="calendar"
            v-model="calendarValue"
            color="primary"
            type="month"
            :now="now"
          >
            <template #day="{ date }">
              <template
                v-for="(recurrenceEvent,
                recurrenceEventIndex) in recurrenceEventMap[date]"
              >
                <v-menu
                  :key="recurrenceEvent.name"
                  v-model="recurrenceEvent.isOpen"
                  full-width
                  offset-x
                >
                  <template #activator="{ on }">
                    <div
                      :id="`recurrences-calendar-event-${recurrenceEventIndex}-item`"
                      v-ripple
                      class="recurrence-event"
                      v-on="on"
                    >
                      {{ recurrenceEventTitle }}
                    </div>
                  </template>
                  <v-card class="cr-modal" min-width="350px" flat>
                    <v-card-title @click.self.stop="() => {}">
                      {{ recurrenceEventTitle }}
                    </v-card-title>
                    <v-card-actions @click.self.stop="() => {}">
                      <v-spacer />
                      <v-btn
                        :id="`recurrences-calendar-event-${recurrenceEventIndex}-close-btn`"
                        class="btn-secondaryaction"
                      >
                        Close
                      </v-btn>
                      <v-btn
                        v-if="!disabled && value.length"
                        :id="`recurrences-calendar-event-${recurrenceEventIndex}-delete-btn`"
                        class="btn-delete"
                        @click.prevent="
                          handleRecurrenceEventDelete(recurrenceEvent)
                        "
                      >
                        Delete
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </template>
            </template>
          </v-calendar>
        </v-sheet>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import recurrences from '@/services/recurrences'
import { EventBus } from '@/utils/event-bus'

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    originalRecurrenceEvent: {
      type: Object,
      default: null,
    },
    timeZone: {
      type: String,
      default: () => DateTime.local().zoneName,
    },
    disabled: {
      type: Boolean,
    },
  },
  data() {
    return {
      recurrences: null,
      recurrenceEventDateList: [],
      calendarValue: DateTime.local().setZone(this.timeZone).toISODate(),
      now: DateTime.local().setZone(this.timeZone).toISODate(),
    }
  },
  computed: {
    recurrenceEventMap() {
      const map = {}

      if (!this.originalRecurrenceEvent?.startDatetime) {
        return map
      }

      const originalStartDate = this.originalRecurrenceEvent.startDatetime
      const recurrenceEventDateCombinedList = [
        originalStartDate,
        ...this.recurrenceEventDateList,
      ]

      recurrenceEventDateCombinedList.forEach((recurrenceEventDate) => {
        let date
        if (recurrenceEventDate !== originalStartDate) {
          date = DateTime.fromISO(recurrenceEventDate)
            .setZone(this.timeZone)
            .toISODate()
        } else {
          date = DateTime.fromISO(recurrenceEventDate).toISODate()
        }

        const event = {
          date,
          ...this.originalRecurrenceEvent,
          isOpen: false,
        }
        ;(map[date] = map[date] || []).push(event)
      })

      return map
    },
    recurrenceEventTitle() {
      return (
        `${DateTime.fromISO(this.originalRecurrenceEvent.startDatetime, {
          zone: this.timeZone,
        }).toLocaleString(DateTime.TIME_SIMPLE)}` +
        (this.originalRecurrenceEvent.endDatetime
          ? ` - ${DateTime.fromISO(this.originalRecurrenceEvent.endDatetime, {
              zone: this.timeZone,
            }).toLocaleString(DateTime.TIME_SIMPLE)}`
          : '') +
        `\n ${this.originalRecurrenceEvent.name}`
      )
    },
  },
  watch: {
    value: {
      handler: 'updateRecurrenceEventList',
      deep: true,
    },
    originalRecurrenceEvent: {
      immediate: true,
      handler() {
        this.updateRecurrenceEventList(this.value)
      },
    },
  },
  mounted() {
    EventBus.$on('global-alert-action-undo', (alertData) => {
      this.handleRecurrenceExclusionUndo(alertData)
    })
  },
  methods: {
    handleRecurrenceExclusionUndo(alertData) {
      const recurrenceExclusionToUndelete = alertData.data
      const recurrenceExclusions = this.recurrences[0].recurrenceExclusions
      this.recurrences[0].recurrenceExclusions = recurrenceExclusions.filter(
        (exclusion) => {
          return (
            exclusion.exclusionDate !==
            recurrenceExclusionToUndelete.exclusionDate
          )
        }
      )
      this.$emit('update-exclusions', this.recurrences[0].recurrenceExclusions)
      this.$emit('input', [
        Object.assign({}, this.value[0], {
          recurrenceExclusions: this.recurrences[0].recurrenceExclusions,
        }),
      ])
    },
    async updateRecurrenceEventList(value) {
      if (this.value.some((recurrence) => !recurrence.startDate)) {
        this.recurrenceEventDateList = []
        return
      }

      // TODO: Storing recurrences so that exclusions can be made on any recurrence, not just the first one
      this.recurrences = value

      const validRecurrences =
        this.value?.filter((recurrence) => {
          return (
            !!recurrence &&
            recurrence.startDate &&
            recurrence.endDate &&
            recurrence.recurrenceType?.recurrenceTypeId
          )
        }) || []

      let recurrenceEventDateListResult
      try {
        recurrenceEventDateListResult = await recurrences.getRecurrenceEventDateList(
          validRecurrences
        )
      } catch (error) {
        this.$store.dispatch('app/showAlert', {
          message: 'There was an error getting event dates',
          type: 'error',
        })
      }
      this.recurrenceEventDateList =
        recurrenceEventDateListResult.data?.recurrenceEventDateList
    },
    handleRecurrenceEventDelete(recurrenceEvent) {
      if (this.disabled) {
        return
      }
      const recurrenceExclusions = [...this.value[0].recurrenceExclusions]
      const recurrenceExclusionFormatted = {
        exclusionDate: DateTime.fromISO(recurrenceEvent.date)
          .setZone(this.timeZone, { keepLocalTime: true })
          .toISO(),
        recurrenceId: this.value[0].recurrenceId,
      }
      recurrenceExclusions.push(recurrenceExclusionFormatted)

      this.$store.dispatch(
        'app/showAlert',
        {
          message: 'Single trip successfully deleted.',
          undoable: true,
          timeout: 12000,
          data: recurrenceExclusionFormatted,
        },
        { root: true }
      )

      this.$emit('update-exclusions', recurrenceExclusions)
      this.$emit('input', [
        Object.assign({}, this.value[0], { recurrenceExclusions }),
      ])
    },
  },
}
</script>

<style lang="scss" scoped>
.recurrence-event {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
  border-radius: 5px;
  background-color: $primary;
  color: $white;
  border: 1px solid $primary;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
  padding: 3px 6px;
  cursor: pointer;
  margin-bottom: 1px;
}
.recurrences-calendar-trip-count {
  display: inline-block;
  padding: 8px 16px;
  border: 1px solid $border-gray;
}
::v-deep .v-calendar-weekly__day-label {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
}
::v-deep .v-calendar-weekly__day.v-present .v-calendar-weekly__day-label {
  background: $primary;
  color: $white !important;
}
.v-menu__content {
  border-radius: 5px !important;
}
.cr-modal {
  &.v-card {
    .v-card__title {
      border-top-left-radius: 5px !important;
      border-top-right-radius: 5px !important;
    }
    .v-card__actions {
      padding: 24px;
      background: $white;
    }
  }
}

.month-arrow {
  margin: 0 8px;
}
</style>
