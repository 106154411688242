<template>
  <div :class="modeClass" class="invoice">
    <template v-if="!customizingEmail">
      <div class="invoice__main">
        <div class="invoice__main__options">
          <div class="invoice__main__options__block">
            <v-layout justify-space-between align-center>
              <v-checkbox
                v-model="invoiceOptions.pdf"
                class="invoice__main__options__radio-button invoice__main__options__radio-button--broker"
                label="Attach PDF to Email"
              />
              <a class="action-link" @click="generatePDF">
                <CRIcon
                  material
                  class="mr-2"
                  color="primary"
                  :width="24"
                  :height="24"
                >
                  remove_red_eye
                </CRIcon>
                View
              </a>
            </v-layout>
          </div>
        </div>
      </div>
      <div class="invoice__button-spacer"></div>
      <div
        id="invoice--submit-btn"
        class="invoice__send-email-button"
        :class="emailSent ? 'invoice__send-email-button--sent' : ''"
        @click="sendEmail"
      >
        <span v-if="!sending && !emailSent">Send Invoice Email</span>
        <span v-else-if="emailSent">Invoice Sent</span>
        <CRProgressCircular v-else :size="27" />
      </div>
    </template>

    <template v-else>
      <InvoiceCustomizeEmailNew
        v-model="email"
        :row="row"
        :rows="rows"
        :pdf="invoiceOptions.pdf"
        :invoice-url="invoiceOptions.invoiceUrl"
        @close-email="customizingEmail = false"
        @email-customized="isEmailCustomized = $event"
      />
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import companies from '@/services/companies'
import invoices from '@/services/invoices'
import { authComputed } from '@/state/helpers'
import { baseUrl } from '@/utils/env'

import InvoiceCustomizeEmailNew from '@/components/InvoiceCustomizeEmailNew.vue'

export default {
  components: {
    InvoiceCustomizeEmailNew,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    approveQuote: {
      type: Boolean,
      default: false,
    },
    quoteHash: {
      type: String,
      default: '',
    },
    quoteId: {
      type: Number,
      default: undefined,
    },
    quoteMessage: {
      type: String,
      default: undefined,
    },
    disableEmail: {
      type: Boolean,
    },
  },
  data() {
    return {
      invoiceOptions: {
        itinerary: {
          label: 'Show trip itinerary',
          value: true,
        },
        terms: {
          label: 'Show terms and conditions',
          value: true,
        },
        payment: {
          label: 'Show payment summary and collection fields',
          value: true,
        },
        signature: {
          label: 'Show signature fields',
          value: true,
        },
        pdf: true,
        invoiceUrl: false,
      },
      email: {},
      customizingEmail: false,
      isEmailCustomized: false,
      allowEInvoice: false,
      sending: false,
      emailSent: false,
    }
  },
  computed: {
    ...authComputed,
    invoiceParamsString() {
      let paramsString = ''
      for (const key in this.invoiceOptions) {
        paramsString = `${paramsString}&${key}=${this.invoiceOptions[key].value}`
      }
      return paramsString
    },
    modeClass() {
      return this.customizingEmail ? 'invoice--email' : 'invoice--options'
    },
    pathToInvoice() {
      if (Object.keys(this.row).length !== 0) {
        return `/invoice/${this.row.hash}`
      }

      let hashString = this.rows
        .filter((row) => !!row.item?.hash)
        .map((row) => row.item.hash)
        .join(',')
      return `/invoice/${hashString}`
    },
  },
  watch: {
    email: {
      deep: true,
      handler() {
        this.emailSent = false
      },
    },
    invoiceOptions: {
      deep: true,
      handler() {
        this.emailSent = false
      },
    },
    rows: {
      deep: true,
      handler() {
        this.emailSent = false
      },
    },
    customizingEmail() {
      const mode = this.customizingEmail ? 'customizeEmail' : 'invoice'
      this.$emit('mode', mode)
    },
  },
  async created() {},
  async mounted() {
    let companyResponse
    if (Object.keys(this.row).length !== 0) {
      companyResponse = await companies.getCompany(this.row.companyId)
    } else {
      companyResponse = await companies.getCompany(this.rows[0].item.companyId)
    }
    const companyData = companyResponse?.data?.company
    if (companyData.checkoutTypeId === 1) {
      //echeckout
      this.allowEInvoice = true
      this.invoiceOptions.invoiceUrl = true
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    remove(item) {
      this.$emit('remove-item', item)
      if (this.rows.length < 2) {
        this.cancel()
      }
    },
    cancel() {
      this.sending = false
      this.emailSent = false
      for (const key in this.invoiceOptions) {
        this.invoiceOptions[key].value = true
      }
      this.$emit('row-collapse')
      this.$emit('close-modal')
    },
    async sendEmail() {
      if (this.emailSent || this.sending) {
        return
      }
      this.sending = true

      const ids = Object.keys(this.row).length
        ? [this.row.reservationId]
        : this.rows.map((item) => item.item.reservationId)
      await invoices.sendInvoiceEmail(ids)

      this.emailSent = true
      this.showAlert({
        message: 'Invoice successfully sent!',
      })
      this.sending = false
    },
    async generatePDF() {
      let resOwnerId = null
      let checkoutId = null
      if (Object.keys(this.row).length !== 0) {
        resOwnerId = this.row.companyId
        checkoutId = this.row.companyCheckoutPageId
        if (typeof resOwnerId === 'undefined') {
          resOwnerId = this.row.company.companyId
        }
      } else {
        resOwnerId = this.rows[0].item.companyId
        checkoutId = this.rows[0].item.checkoutPageId
      }

      const hash = Object.keys(this.row).length
        ? this.row.hash
        : this.rows.map((item) => item.item.hash).join(',')

      const pdfType =
        checkoutId === null && resOwnerId === 2
          ? 'charterup-invoice'
          : 'invoice'
      const params = resOwnerId !== 2 ? this.invoiceParamsString : ''
      const pdfUrl = `https://${baseUrl(
        'pdf'
      )}/pdf/${pdfType}/reservations?reservationHashes=${encodeURIComponent(
        hash
      )}${params}`

      window.open(pdfUrl)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-dialog__content {
  display: flex;
  justify-content: flex-end !important;
}

::v-deep .v-dialog {
  border-radius: 0 !important;
  height: 100%;
  max-height: 100%;
  margin: 0;
  background: white;
}

.invoice {
  border-radius: 0 !important;
  background: white;
  transition: width 0.5s ease-in-out;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;

  &--options {
    width: 500px;
  }

  &--email {
    width: 1000px;
  }

  &__main,
  &__send-email-button {
    display: flex;
    flex-direction: column;
  }

  &__main {
    flex: 1;

    &__options {
      padding: 24px 48px;

      &__block {
        margin: 31px 0;
        border-radius: 3px;
        padding: 0 10px;
        background-color: $blue-light;
      }

      &__radio-button {
        padding: 0 !important;
        margin: 0 !important;
        &--broker {
          vertical-align: middle;
          display: inline-block;
        }
      }

      &__title {
        font-size: 18px;
        font-weight: bold;
        margin-top: 5px;
        margin-bottom: -12px;

        &--broker {
          display: inline-block;
          vertical-align: middle;
          padding-bottom: 17px;
          line-height: 3px;
        }
      }

      &__checkbox {
        margin: -10px 0 -10px 24px;
      }

      &__download-button {
        margin-left: 26px;
        margin-top: 24px;

        &--broker {
          margin-left: 115px;
          margin-top: 1px;
          vertical-align: middle;
          display: inline-block;
          line-height: 0;
        }
      }
    }

    &__customize-email-button {
      display: flex;
      cursor: pointer;
      align-content: center;
      justify-content: space-between;
      margin: 48px 48px 48px;
      border: 1px solid $border-gray;
      padding: 16px;
      border-radius: 5px;

      &__details {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        &__updated-chip {
          margin-top: 1px;
          font-size: 10px;
          border: 1px solid $primary;
          color: $primary;
          padding: 2px 6px 2px;
          height: 20px;
          border-radius: 3px;
        }
        &__icon {
          margin-left: 8px;
          margin-top: 2px !important;
          .cr-icon {
          }
        }
      }
    }
  }

  &__button-spacer {
    min-height: 71px;
    width: 100%;
  }

  &__send-email-button {
    background: $primary;
    position: fixed;
    font-size: 18px;
    color: white;
    bottom: 0;
    height: 71px;
    width: inherit;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    &--sent {
      transition: 0.5s ease;
      background: $success;
      cursor: default;
    }
  }
}

//checkbox overrides until cr checkbox is implemented
::v-deep .v-input__control {
  .v-input__slot {
    margin-bottom: 0px !important;
    label {
      font-size: 14px;
    }
    .v-input--selection-controls__input {
      .v-icon.accent--text {
        color: $primary !important;
        caret-color: $primary !important;
      }
    }
  }
  .v-messages {
    display: none;
  }
}

.action-link {
  text-decoration: none;
  display: flex;
}
</style>
