var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-refund"},[_c('div',{staticClass:"add-refund--content"},[_c('v-form',{ref:"refund-form"},[_c('div',{staticClass:"add-refund--amount"},[_c('CRInput',{staticClass:"add-refund--input",attrs:{"id":`add-refund-form-input-amount`,"type":"number","prefix":"$","min":"0","label":Object.keys(_vm.row).length && !_vm.rows.length
              ? 'Amount'
              : 'Amount Per Reservation',"rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Amount Is Required',
              error: 'Amount Is Required',
            }),
            (val) => val >= 0 || 'Amount Must Be Positive',
          ]},model:{value:(_vm.amount),callback:function ($$v) {_vm.amount=$$v},expression:"amount"}}),_c('CRSelect',{attrs:{"id":`-add-refund-select-refund-type`,"items":_vm.refundTypes,"label":"Type","item-text":"label","item-value":"refundTypeId","rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Refund Type Required',
              error: 'Refund Type Required',
            }),
          ]},model:{value:(_vm.refundType),callback:function ($$v) {_vm.refundType=$$v},expression:"refundType"}}),_c('CRRichText',{staticClass:"mb-3",attrs:{"id":`-add-refund-input-payment-notes`,"multi-line":"","label":"Office Notes","placeholder":"Payment notes for internal use only (customers will not see this)."},on:{"htmlchange":_vm.updateNotesHtml,"textchange":_vm.updateNotesText}}),_c('CRSelect',{attrs:{"id":`-add-refund-select-refund-payment-method`,"items":_vm.refundPaymentMethods,"label":"Payment Method","item-text":"label","item-value":"id","rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Refund Payment Method Required',
              error: 'Refund Payment Method Required',
            }),
          ]},model:{value:(_vm.refundPaymentMethod),callback:function ($$v) {_vm.refundPaymentMethod=$$v},expression:"refundPaymentMethod"}}),_c('v-checkbox',{staticClass:"send-email-checkbox",attrs:{"color":"primary","label":"Send Email"},model:{value:(_vm.sendEmail),callback:function ($$v) {_vm.sendEmail=$$v},expression:"sendEmail"}})],1)])],1),_c('div',{staticClass:"add-refund--button-spacer"}),_c('div',{staticClass:"add-refund--submit-btn",attrs:{"id":"add-refund--submit-btn"},on:{"click":_vm.submit}},[(!_vm.loading)?_c('span',[_vm._v("Refund")]):_c('CRProgressCircular',{attrs:{"size":27}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }