<template>
  <div class="add-charge">
    <div class="add-charge--content">
      <v-form ref="charge-form">
        <div class="add-charge--amount">
          <CRInput
            :id="`add-charge-form-input-amount`"
            v-model="amount"
            class="add-charge--input"
            type="number"
            prefix="$"
            min="0"
            :label="
              Object.keys(row).length && !rows.length
                ? 'Amount'
                : 'Amount Per Reservation'
            "
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Amount Is Required',
                error: 'Amount Is Required',
              }),
              (val) => (val <= 0 ? 'Charge Must Be Greater Than 0' : true),
            ]"
          />
          <CRSelect
            :id="`-add-charge-select-charge-type`"
            v-model="chargeType"
            :items="chargeTypes"
            label="Type"
            item-text="label"
            item-value="id"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Charge Type Required',
                error: 'Charge Type Required',
              }),
            ]"
          />
          <CRRichText
            :id="`-add-charge-input-payment-customer-notes`"
            multi-line
            label="Payment Notes"
            placeholder="Payment notes for you and the customer."
            :color="'primary'"
            @htmlchange="updateCustomerNoteHtml"
            @textchange="updateCustomerNoteText"
          />
          <br />
          <CRRichText
            :id="`-add-charge-input-payment-notes`"
            multi-line
            label="Office Notes"
            :color="'primary'"
            placeholder="Payment notes for internal use only (customers will not see this)."
            class="mb-3"
            @htmlchange="updateOfficeNoteHtml"
            @textchange="updateOfficeNoteText"
          />
          <ClassificationSelector
            id="-add-charge-classification-selector"
            ref="classification-selector"
            v-model="classificationId"
            :classification-type-id="3"
          />
          <v-checkbox
            v-model="sendEmail"
            color="primary"
            class="send-email-checkbox"
            label="Send Email"
          />
        </div>
      </v-form>
    </div>
    <div class="add-charge--button-spacer"></div>
    <div
      id="add-charge--submit-btn"
      class="add-charge--submit-btn"
      @click="submit"
    >
      <span v-if="!loading">Add Charge</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import charges from '@/services/charges'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import * as logger from '@/utils/logger'
import ClassificationSelector from '@/components/ClassificationSelector.vue'

export default {
  components: {
    ClassificationSelector,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    reservationDetailVersion: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      chargeTypes: [],
      chargeType: null,
      classificationId: null,
      amount: null,
      notes: '',
      notesHtml: '',
      customerNotes: '',
      customerNotesHtml: '',
      sendEmail: true,
      showAllRows: false,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    previewRows() {
      return this.rows.slice(0, 3)
    },
  },
  async mounted() {
    this.setChargeTypes()
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    cancel() {
      this.$emit('row-collapse')
      this.$emit('close-modal')
    },
    containerClass() {
      if (this.reservationDetailVersion) {
        return ''
      }
      return Object.keys(this.row).length && !this.rows.length
        ? 'action-box'
        : ''
    },
    updateCustomerNoteHtml(value) {
      this.customerNotesHtml = value
    },
    updateCustomerNoteText(value) {
      this.customerNotes = value
    },
    updateOfficeNoteHtml(value) {
      this.notesHtml = value
    },
    updateOfficeNoteText(value) {
      this.notes = value
    },
    async submit() {
      if (!this.loading) {
        this.loading = true
        await this.$forceUpdate()
        const isValid =
          (await this.$refs['charge-form'].validate()) &&
          (await this.$refs['classification-selector'].validate())
        if (!isValid) {
          this.loading = false
          return
        }

        if (Object.keys(this.row).length) {
          // detail version
          const payload = [
            {
              chargeType: this.chargeType,
              userId: this.currentUser.userId,
              reservationId: this.row.reservationId,
              reservationVersion: this.row.version,
              companyId: this.currentUser.companyId,
              amount: this.amount,
              sendEmail: this.sendEmail,
              customerNotes: {
                note: this.customerNotes,
                html: this.customerNotesHtml,
              },
              notes: {
                note: this.notes,
                html: this.notesHtml,
              },
              classificationId: this.classificationId,
            },
          ]
          let res
          try {
            if (this.row.reservationType) {
              res = await charges.addReferralCharges(payload)
            } else {
              res = await charges.addReservationCharges(payload)
            }
          } catch (err) {
            this.showAlert({
              type: 'error',
              message: 'Error Submitting charge, Please try again',
            })
            this.loading = false
            logger.error(err)
            return
          }

          if (!res || !res.data.successful) {
            this.loading = false
            this.showAlert({
              type: 'error',
              message: res.data?.message
                ? res.data.message
                : 'Error Submitting charge, Please try again',
            })
            logger.error(res)
          } else {
            this.showAlert({
              message: 'Charge successfully added!',
            })
            this.loading = false
            this.cancel()
          }
        } else {
          // multi-select version
          const payload = this.rows.map((res) => {
            return {
              chargeType: this.chargeType,
              userId: this.currentUser.userId,
              reservationId: res.item.reservationId,
              reservationVersion: res.item.version,
              companyId: this.currentUser.companyId,
              amount: this.amount,
              sendEmail: this.sendEmail,
              customerNotes: {
                note: this.customerNotes,
                html: this.customerNotesHtml,
              },
              notes: {
                note: this.notes,
                html: this.notesHtml,
              },
              classificationId: this.classificationId,
            }
          })
          let res
          try {
            if (this.rows[0].item.reservationType) {
              res = await charges.addReferralCharges(payload)
            } else {
              res = await charges.addReservationCharges(payload)
            }
          } catch (err) {
            this.showAlert({
              type: 'error',
              message: 'Error Submitting charge, Please try again',
            })
            this.loading = false
            logger.error(err)
            return
          }

          if (!res || !res.data.successful) {
            this.loading = false
            this.showAlert({
              type: 'error',
              message: res.data?.message
                ? res.data.message
                : 'Error Submitting charge, Please try again',
            })
            logger.error(res)
          } else {
            this.showAlert({
              message: 'Charge successfully added!',
            })
            this.loading = false
            this.cancel()
          }
        }
      }
    },
    async setChargeTypes() {
      let { data: chargeTypes } = await this.$store.dispatch(
        'types/getChargeTypes'
      )
      chargeTypes = chargeTypes.filter(
        (charge) => charge.key !== 'cancellation_penalty'
      )
      this.chargeTypes = chargeTypes
    },
  },
}
</script>

<style lang="scss" scoped>
.add-charge {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  position: relative;

  ::v-deep .v-text-field .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
  }

  .add-charge--content {
    flex: 1;
  }

  .add-charge--amount {
    padding: 24px 48px;
  }

  .add-charge--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  .add-charge--submit-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
}

::v-deep .v-textarea .v-text-field__details {
  display: none;
}

.send-email-checkbox {
  margin-top: 0;
}
</style>
