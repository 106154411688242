<template>
  <div class="change-due-date">
    <div class="change-due-date--content">
      <div class="change-due-date--change-date">
        <CRInput
          v-if="dueDate"
          :id="`${id}-reservation-due-date-form-change-current-due-date`"
          v-model="currentDueDate"
          label="Current Due Date"
          type="date"
          :disabled="true"
        />
        <CRInput
          :id="`${id}-reservation-due-date-form-new-due-date`"
          v-model="newDueDate"
          :name="v4()"
          label="New Due Date"
          type="date"
        />
      </div>
    </div>
    <div class="change-due-date--button-spacer"></div>
    <div
      id="change-due-date--submit-btn"
      class="change-due-date--submit-btn"
      @click="saveTheDate"
    >
      <span v-if="!loading">Change Due Date</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import charges from '@/services/charges'
import { currencyFilter } from '@/utils/currency'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import { DateTime } from 'luxon'
import { v4 } from 'uuid'

export default {
  props: {
    reservationId: { type: Number, default: undefined },
    row: { type: Object, default: undefined },
    rows: { type: Array, default: () => [] },
    dueDate: { type: String, default: '' },
    id: { type: String, default: null },
  },
  data() {
    return {
      v4,
      currencyFilter,
      currentDueDate: undefined,
      newDueDate: undefined,
      loading: false,
    }
  },
  computed: {
    ...authComputed,
    reservationIdsList() {
      if (this.rows.length) {
        return this.rows?.map((row) => row.item?.reservationId) || []
      } else if (this.row) {
        return [this.row.reservationId]
      }
      return []
    },
  },
  mounted() {
    if (this.dueDate) {
      this.currentDueDate = this.formattedDatetime(this.dueDate)
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),
    async saveTheDate() {
      this.loading = true

      const payload = {
        dueDate: DateTime.fromISO(this.newDueDate).toISO(),
        reservationIds: this.reservationIdsList,
        userId: this.currentUser.userId,
      }
      const changeDueDateResponse = await charges
        .changeChargeDueDate(payload)
        .catch((e) => e)
      if (changeDueDateResponse && changeDueDateResponse.status === 200) {
        this.showAlert({
          type: 'success',
          message: 'Due Date successfully changed.',
        })
      } else {
        this.showAlert({
          type: 'error',
          message: 'There was an error changing due date.',
        })
      }
      this.loading = false
      this.close()
    },
    close() {
      this.$emit('row-collapse')
      this.$emit('close-modal')
    },
    formattedDatetime(date) {
      return DateTime.fromISO(date).toFormat('yyyy-MM-dd')
    },
  },
}
</script>
<style lang="scss" scoped>
.change-due-date {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 500px;
  position: relative;

  ::v-deep .v-text-field .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
  }

  .change-due-date--content {
    flex: 1;
  }

  .change-due-date--change-date {
    padding: 24px 48px;
  }

  .change-due-date--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  .change-due-date--submit-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
}
</style>
