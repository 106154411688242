import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { CheckoutDetailResult } from '@/models/dto'

const httpService: HttpService = new HttpService()

export const nonMarketplaceCheckoutDetailByHash = async (quoteHash: string): Promise<AxiosResponse<CheckoutDetailResult>>  =>
  httpService.get(`https://${baseUrl()}/checkoutDetail/${quoteHash}`)

export const marketplaceCheckoutDetailByQuoteIdAndProviderId = async (
  quoteId: number,
  providerId: number
): Promise<AxiosResponse<CheckoutDetailResult>> => httpService.get(`https://${baseUrl()}/checkoutDetail/${quoteId}/${providerId}`)

export const guestCheckoutDetailByHash = async (quoteHash: string): Promise<AxiosResponse<CheckoutDetailResult>> =>
  httpService.get(`https://${baseUrl()}/checkoutDetail/guest/${quoteHash}`)
