import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { Quote, QuoteResult } from '@/models/dto/Quote'
import { RiderTrackingPayload } from '@/models/dto'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

const contractUrl = `${baseUrl('api')}/contract`
const contractsUrl = `${baseUrl('api')}/contracts`

export default {
  createContractQuote(payload: Quote): Promise<AxiosResponse<QuoteResult>> {
    const url = `https://${contractsUrl}/createContract`
    return httpService.post(url, payload)
  },
  getContract(contractId: number): Promise<AxiosResponse<QuoteResult>> {
    const url = `https://${contractUrl}/${contractId}`
    return httpService.get(url)
  },
  editContract(payload: Quote): Promise<AxiosResponse<QuoteResult>> {
    const url = `https://${contractsUrl}/${payload.contractId}`
    return httpService.patch(url, payload)
  },
  getBillingMethodTypes(): Promise<AxiosResponse> {
    const url = `https://${contractsUrl}/billingMethodTypes`
    return httpService.get(url)
  },
  getBillingFrequencyTypes(): Promise<AxiosResponse> {
    const url = `https://${contractsUrl}/billingFrequencyTypes`
    return httpService.get(url)
  },
  markContractSigned(contractId: number): Promise<AxiosResponse> {
    const url = `https://${contractsUrl}/sign/${contractId}`
    return httpService.patch(url, null)
  },
  closeContract(contractId: number): Promise<AxiosResponse> {
    const url = `https://${contractsUrl}/close/${contractId}`
    return httpService.patch(url, null)
  },
  checkoutByHash(hash: string): Promise<AxiosResponse> {
    const url = `https://${contractsUrl}/checkout/hash/${hash}`
    return httpService.get(url)
  },
  vanityUrlByUriMapId(uriMapId: number): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/tracking/getVanityUrl/${uriMapId}`
    return httpService.get(url)
  },
  createVanityUrl(payload: { quoteId: number, vanityUrlName: string, enabled: boolean }): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/tracking/createVanityUrl`
    return httpService.post(url, payload)
  },
  updateVanityUrl(payload: { uriMapId:number, quoteId: number, vanityUrlName: string, enabled: boolean }): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/tracking/updateVanityUrl`
    return httpService.post(url, payload)
  },
  updateRiderTracking(payload: RiderTrackingPayload): Promise<AxiosResponse> {
    const url = `https://${contractsUrl}/updateRiderTracking`
    return httpService.post(url, payload)
  },
}
