var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reduce-charge"},[_c('div',{staticClass:"reduce-charge--content"},[_c('v-form',{ref:"charge-form"},[_c('div',{staticClass:"reduce-charge--amount"},[_c('CRInput',{key:"reduce-charge-form-payment-amount",staticClass:"reduce-charge--input",attrs:{"id":`reduce-charge-form-payment-amount`,"type":"number","min":"0","prefix":"-$","label":Object.keys(_vm.row).length && !_vm.rows.length
              ? 'Amount'
              : 'Amount Per Reservation',"rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Amount Is Required',
              error: 'Amount Is Required',
            }),
            (val) => (val <= 0 ? 'Reduction Must Be Greater Than 0' : true),
          ]},model:{value:(_vm.reductionAmount),callback:function ($$v) {_vm.reductionAmount=$$v},expression:"reductionAmount"}}),_c('CRSelect',{attrs:{"id":`-reduce-charge-select-charge-type`,"items":_vm.chargeTypes,"label":"Type","item-text":"label","item-value":"id","rules":[
            _vm.isRequired(true, _vm.isNotEmpty, {
              req: 'Charge Type Required',
              error: 'Charge Type Required',
            }),
          ]},model:{value:(_vm.chargeType),callback:function ($$v) {_vm.chargeType=$$v},expression:"chargeType"}}),_c('CRRichText',{attrs:{"id":`-reduce-charge-input-payment-customer-notes`,"multi-line":"","label":"Payment Notes","placeholder":"Payment notes for you and the customer."},on:{"htmlchange":_vm.updateCustomerNoteHtml,"textchange":_vm.updateCustomerNoteText}}),_c('br'),_c('CRRichText',{staticClass:"mb-3",attrs:{"id":`-reduce-charge-input-payment-notes`,"multi-line":"","label":"Office Notes","placeholder":"Payment notes for internal use only (customers will not see this)."},on:{"htmlchange":_vm.updateOfficeNoteHtml,"textchange":_vm.updateOfficeNoteText}}),_c('ClassificationSelector',{ref:"classification-selector",attrs:{"id":"-add-charge-classification-selector","classification-type-id":3},model:{value:(_vm.classificationId),callback:function ($$v) {_vm.classificationId=$$v},expression:"classificationId"}}),_c('v-checkbox',{staticClass:"send-email-checkbox",attrs:{"color":"primary","label":"Send Email"},model:{value:(_vm.sendEmail),callback:function ($$v) {_vm.sendEmail=$$v},expression:"sendEmail"}})],1)])],1),_c('div',{staticClass:"reduce-charge--button-spacer"}),_c('div',{staticClass:"reduce-charge--submit-btn",attrs:{"id":"reduce-charge--submit-btn"},on:{"click":_vm.submit}},[(!_vm.loading)?_c('span',[_vm._v("Reduce")]):_c('CRProgressCircular',{attrs:{"size":27}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }