import { render, staticRenderFns } from "./QuoteFormTripVehicleTabs.vue?vue&type=template&id=6f48da88&scoped=true"
import script from "./QuoteFormTripVehicleTabs.vue?vue&type=script&lang=js"
export * from "./QuoteFormTripVehicleTabs.vue?vue&type=script&lang=js"
import style0 from "./QuoteFormTripVehicleTabs.vue?vue&type=style&index=0&id=6f48da88&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f48da88",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListTile } from 'vuetify/lib/components/VList';
import { VListTileTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VBtn,VCheckbox,VFlex,VIcon,VLayout,VList,VListTile,VListTileTitle,VMenu,VRadio,VRadioGroup,VTab,VTabs})
