import Vue from 'vue'

const MenuDataMapper = function (row, rowProps, handleAction, bubbleAction) {
  const menuOut = []
  menuOut.push({
    icon: () => 'remove_red_eye',
    viewBox: '0 0 24 24',
    title: () => 'View Quote',
    target: '_blank',
    quickAccess: true,
    path: () => {
      return `/quotes/view/${row.quoteId}`
    },
  })

  menuOut.push({
    icon: () => 'copy',
    title: () => 'Duplicate Quote',
    quickAccess: true,
    click: () => bubbleAction('duplicate-quote', rowProps),
  })

  return menuOut
}
// collectionSingular
export default function (component) {
  return Vue.component('WrappedActions', {
    props: {
      row: {
        type: Object,
        default: () => ({}),
      },
      handleAction: {
        type: Function,
        default: () => null,
      },
      bubbleAction: {
        type: Function,
        default: () => null,
      },
      rowProps: {
        type: Object,
        default: () => ({}),
      },
    },
    render(createElement) {
      return createElement(component, {
        props: {
          row: this.row,
          handleAction: this.handleAction,
          bubbleAction: this.bubbleAction,
          rowProps: this.rowProps,
          menu: new MenuDataMapper(
            this.row,
            this.rowProps,
            this.handleAction,
            this.bubbleAction
          ),
        },
      })
    },
  })
}
