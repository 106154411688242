
import { Component, Prop, Vue } from 'vue-property-decorator'
import {
  StagePaymentMethod,
  PaymentMethodType,
  UpdatePaymentMethodPayload,
} from '@/models/dto'
import { PaymentMethodTypeKey } from '@/utils/enum'
import { updateBalancePaymentMethod } from '@/services/trips'

const VALID_PAYMENT_METHOD_TYPES = [
  PaymentMethodTypeKey.ACH,
  PaymentMethodTypeKey.CreditCard,
  PaymentMethodTypeKey.Check,
]

@Component
export default class ChangeReservationPaymentMethod extends Vue {
  @Prop({ type: Object, required: true }) readonly row: any

  balancePaymentMethods: StagePaymentMethod[] = []
  paymentMethodTypes: PaymentMethodType[] = []
  selectedBalancePaymentMethodTypeId: number = null
  isSending: boolean = false

  get tripId(): number {
    return this.row.tripId
  }

  async mounted(): Promise<void> {
    this.paymentMethodTypes = this.row?.paymentMethodTypes.filter(
      (method: PaymentMethodType) => VALID_PAYMENT_METHOD_TYPES.includes(method.key)
    )
    this.balancePaymentMethods = this.row?.balancePaymentMethods

    if (this.balancePaymentMethods?.length > 0) {
      const currentMethod = this.balancePaymentMethods.find(
        (method: StagePaymentMethod) => method.isAllowed === 1
      )
      if (currentMethod) {
        this.selectedBalancePaymentMethodTypeId = currentMethod.paymentMethodId
      }
    } else if (this.paymentMethodTypes?.length > 0) {
      this.balancePaymentMethods = this.paymentMethodTypes.map(
        (method: PaymentMethodType) => {
          return {
            paymentMethodId: method.id,
            isAllowed: 0,
          }
        }
      )
    }
  }

  getPaymentMethodLabel(paymentMethodTypeId: number): string {
    if (this.paymentMethodTypes) {
      const paymentMethod = this.paymentMethodTypes.find(
        (method) => method.id === paymentMethodTypeId
      )
      return paymentMethod?.label || ''
    }
    return ''
  }

  handlePaymentMethodSelection(paymentMethodTypeId: number): void {
    if (paymentMethodTypeId) {
      this.selectedBalancePaymentMethodTypeId = paymentMethodTypeId
    }
  }

  async submit(): Promise<void> {
    this.isSending = true

    const payload: UpdatePaymentMethodPayload = {
      paymentMethodTypeId: this.selectedBalancePaymentMethodTypeId,
      reservationId: this.row.reservationId,
    }

    try {
      const response = await updateBalancePaymentMethod(this.tripId, payload)
      if (response && response.status === 200) {
        this.$store.dispatch('app/showAlert', {
          type: 'success',
          message: 'Payment method successfully updated.',
        })
        this.close()
      }
    } catch (e) {
      console.error(e)
      this.$store.dispatch('app/showAlert', {
        type: 'error',
        message: 'There was an error updating the balance payment method.',
      })
    }

    this.isSending = false
  }

  close(): void {
    this.$emit('row-collapse')
    this.$emit('close-modal')
  }
}
