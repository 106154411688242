<template>
  <div>
    <div v-if="column.displayType === 'action-list'">
      <div
        :key="`quotes-actionable-column-${row.reservationId}-${
          column.t_id ? column.t_id : ''
        }`"
      >
        <div
          v-for="(item, key) in actionList"
          :id="`quotes-actionable-column-action-item-${key}-handler`"
          :key="`quotes-actionable-${key}-${row.reservationId}-`"
        >
          <a @click="() => handleAction(action, rowProps, item)">
            {{ op(item, column.listProp) }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="column.displayType === 'res-link'">
      <div
        v-for="(item, key) in row.reservations"
        :key="`res-link-${key}-${row.quoteId ? row.quoteId : ''}`"
      >
        <router-link :to="{ path: reservationViewPath(row) }">
          {{ item }}
        </router-link>
      </div>
    </div>
    <div v-if="column.displayType === 'res-multi'">
      <div
        v-for="(item, key) in row.reservationIds"
        :key="`res-multi-${key}-${row.quoteId ? row.quoteId : ''}`"
      >
        <router-link :to="{ path: `/reservations/${item}` }">
          {{ item }}
        </router-link>
      </div>
    </div>
    <div v-if="column.displayType === 'trip-type-multi'">
      {{ row.tripTypes }}
    </div>
    <div v-if="column.displayType === 'payment-type-multi'">
      {{ row.paymentTypes }}
    </div>
    <div v-if="column.displayType === 'payment-method-type-multi'">
      {{ row.paymentMethodTypes }}
    </div>
    <div v-if="column.displayType === 'clickable-link'">
      <div :key="`clickable-link-${row.reservationId}`">
        <router-link
          target="_blank"
          :to="{
            name: 'quotes.view',
            params: {
              id: row.quoteId,
            },
          }"
          class="no-underline"
          @click="(event) => event.stopPropagation()"
        >
          {{ row.quoteId }}
        </router-link>
      </div>
    </div>
    <div v-if="column.displayType === 'clickable-link-new'">
      <div :key="`clickable-link-${row.reservationId}`">
        <router-link
          target="_blank"
          :to="{
            name: row.contractName ? 'contracts.view' : 'quotes.view',
            params: {
              id: row.quoteId,
            },
          }"
          class="no-underline"
          @click="(event) => event.stopPropagation()"
        >
          {{
            row.reservationId
              ? ''
                : row.managedId || row.quoteId
          }}
        </router-link>
      </div>
    </div>
    <div v-if="column.displayType === 'clickable-link-res-quote-id'">
      <div :key="`clickable-link-${row.reservationId}`">
        <router-link
          target="_blank"
          :to="{
            name: row.contractId ? 'contracts.view' : 'quotes.view',
            params: {
              id: row.quoteId,
            },
          }"
          class="no-underline"
          @click="(event) => event.stopPropagation()"
        >
          {{
            row.reservationId
              ? ''
                : row.managedId || row.quoteId
          }}
        </router-link>
      </div>
    </div>
    <div v-if="column.displayType === 'action-item'">
      <div
        :key="`action-item-${row.reservationId}-${
          row.quoteId ? row.quoteId : ''
        }`"
      >
        <a @click="() => handleAction(action, rowProps, undefined)">
          {{ columnDisplay(row, column) }}
        </a>
      </div>
    </div>
    <div v-if="column.displayType === 'action-item-black'">
      <div
        :key="`action-item-${row.reservationId}-${
          row.quoteId ? row.quoteId : ''
        }`"
      >
        <div
          class="action-item-black"
          @click="() => handleAction(action, rowProps, undefined)"
        >
          {{ columnDisplay(row, column) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import op from 'simple-object-path'
import { columnDisplay } from '@/utils/columnDisplay'
import { reservationViewPath } from '@/utils/reservationPathBuilder'

export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    column: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      op,
      reservationViewPath,
      columnDisplay,
      actionList: [],
      action: '',
    }
  },
  mounted() {
    this.assignDefaults()
  },
  updated() {
    this.assignDefaults()
  },
  methods: {
    assignDefaults() {
      this.action = this.column.action
      if (this.column.list) {
        this.actionList = op(this.row, this.column.list) || []
      }
    },
  },
}
</script>

<style lang="scss">
.padded {
  padding: 4px;
}

.no-underline {
  text-decoration: none;
}

.action-item-black {
  cursor: pointer;
}
</style>
