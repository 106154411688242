<template>
  <div>
    <QuoteFormTripVehicleSelector />
    <v-divider />
    <v-layout>
      <v-flex xs3 style="display: flex">
        <h3>{{ routeName }}</h3>
        <CRIcon
          v-show="!isModeView"
          class="margin-l-2"
          color="primary"
          :view-box="'0 0 24 24'"
          @click.native="openRouteNameSidebar"
        >
          edit
        </CRIcon>
      </v-flex>
      <v-flex xs9>
        <QuoteFormTripVehicleTabs />
      </v-flex>
    </v-layout>
    <v-layout v-if="tripAddresses && tripAddresses.length > 0" column>
      <template v-for="(tripAddress, tripAddressIndex) in tripAddresses">
        <v-flex
          :id="`trip-address-${tripAddressIndex}`"
          :key="`trip-address-${tripAddressIndex}`"
          xs11
        >
          <v-layout row>
            <v-flex xs12>
              <QuoteFormAddressDetail
                :trip-address="tripAddress"
                :address-index="tripAddressIndex"
              />
            </v-flex>
            <v-flex class="delete-stop-icon-container">
              <v-btn
                v-show="!isModeView"
                icon
                class="mt-3 delete-stop-icon"
                @click="removeAddress(tripAddressIndex)"
              >
                <CRIcon>trash</CRIcon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
      </template>
    </v-layout>
    <v-layout row justify-end padded>
      <v-flex shrink>
        <CRButton
          v-if="!isModeView"
          color="primary"
          text-color="white"
          shrink
          @click="addAddress"
        >
          Add Address
        </CRButton>
      </v-flex>
      <v-spacer />
    </v-layout>
    <QuoteFormTripSummary
      :trip-addresses="tripAddresses"
      :trip-vehicle-groups="tripVehicleGroups"
      :recurrences="recurrences"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import QuoteFormTripVehicleSelector from './QuoteFormTripVehicleSelector.vue'
import QuoteFormAddressDetail from './QuoteFormAddressDetail.vue'
import QuoteFormTripVehicleTabs from './QuoteFormTripVehicleTabs.vue'
import QuoteFormTripSummary from './QuoteFormTripSummary.vue'
import { EventBus } from '@/utils/event-bus'

export default {
  name: 'QuoteFormTripDetailNew',
  components: {
    QuoteFormTripVehicleSelector,
    QuoteFormAddressDetail,
    QuoteFormTripVehicleTabs,
    QuoteFormTripSummary,
  },
  computed: {
    mode() {
      return this.$store.getters['quoteForm/getMode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    currentTrip() {
      return this.$store.getters['quoteForm/getCurrentTrip']
    },
    currentTripIndex() {
      return this.$store.getters['quoteForm/getCurrentTripIndex']
    },
    tripAddresses() {
      return this.currentTrip?.tripAddresses
    },
    tripVehicleGroups() {
      return this.currentTrip?.tripVehicleGroups
    },
    routeName() {
      return this.currentTrip?.routeName || `Trip ${this.currentTripIndex + 1}`
    },
    recurrences() {
      return this.currentTrip?.recurrences
    },
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
      addAddressModule: 'quoteForm/addAddress',
      removeAddressModule: 'quoteForm/removeAddress',
    }),
    openRouteNameSidebar() {
      const component = () => import('@/components/RouteNameSidebar.vue')
      this.$store.dispatch('app/openSidebarDialog', {
        data: {
          title: 'Edit Trip Name',
        },
        component,
      })
    },
    addAddress() {
      this.addAddressModule()
      EventBus.$emit('get-estimations')
    },
    removeAddress(tripAddressIndex) {
      this.removeAddressModule(tripAddressIndex)
      EventBus.$emit('get-estimations')
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-stop-icon-container {
  display: flex;
  justify-content: flex-end;
}
.delete-stop-icon {
  color: $gray-mid-light !important;

  &:hover {
    color: $error !important;
  }
}
</style>
