import { baseUrl } from '@/utils/env'
import { AxiosResponse } from 'axios'
import { HttpService } from '@/services/common/HttpService'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import { Company } from '@/models/dto'

const httpService: HttpService = new HttpService()

export default {
  getCompanies(params: TableViewParameters): Promise<AxiosResponse<TableViewResult<Company>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = baseUrl()
    const url = `https://${host}/tables/companies?${query}`
    return httpService.get(url)
  },
  getCompany(companyId: number): Promise<AxiosResponse<{ company: Company }>> {
    const host = baseUrl()
    const url = `https://${host}/companies/${companyId}`
    return httpService.get(url)
  },
  modifyCompany(params: { id: number, payload: Company}): Promise<AxiosResponse>  {
    const host = baseUrl()
    const url = `https://${host}/companies/${params.id}`
    return httpService.patch(url, params.payload)
  },
  uploadInsurancePdf(params: { companyId: number, payload: Company}): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/${params.companyId}/uploadInsurancePdf`
    return httpService.post(url, params.payload)
  },
  addCompany(params: { payload: Company }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/create`
    return httpService.post(url, params.payload)
  },
  deleteCompany(id: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/${id}`
    return httpService.delete(url)
  },
  uploadBrandingPhoto(payload: { id: number, type: string, payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/${payload.id}/companyBrandings/${payload.type}`
    return httpService.put(url, payload.payload)
  },
  uploadCompanyPhoto(payload: { id: number, payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/${payload.id}/companyPhotos`
    return httpService.post(url, payload.payload)
  },
  getReferralTerms(companyId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/referralTerms/${companyId}`

    return httpService.get(url)
  },
  modifyReferralTerms(params: { id: number, payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/referralTerms/${params.id}`

    return httpService.put(url, params.payload)
  },
  getCheckoutPage(params: { pageId: number }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/checkoutPages/${params.pageId}`
    return httpService.get(url)
  },
  modifyCheckoutPage(params: { pageId: number, payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/checkoutPages/${params.pageId}`
    return httpService.put(url, params.payload)
  },
  addCheckoutPage(params: { payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/checkoutPages`
    return httpService.post(url, params.payload)
  },
  enableCUPOPS(companyId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/enableCUPOPS/${companyId}`
    return httpService.get(url)
  },
  getCompanyNotes(companyId: number): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/getNote/${companyId}`
    return httpService.get(url)
  },
  createNote(params: { payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/createNote`
    return httpService.post(url, params.payload)
  },
  updateNote(params: { payload: any }): Promise<AxiosResponse> {
    const host = baseUrl()
    const url = `https://${host}/companies/updateNote`
    return httpService.post(url, params.payload)
  },
  getCompanyTerms(): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/company-terms`
    return httpService.get(url)
  },
  getDefaultCompanyTerms(companyId: number): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/companyTerms/default/${companyId}`
    return httpService.get(url)
  },
  getCharterUPOperatingStatuses(): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/companies/charterUpOperatingStatuses`
    return httpService.get(url)
  },
  getCompanyVehicleAmenities(companyId: number): Promise<AxiosResponse> {
    const url = `https://${baseUrl()}/companies/${companyId}/vehicleAmenities`
    return httpService.get(url)
  },
}
