var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"market-pricing-title"},[_vm._v("Please select a price for Base Fare")]),_c('v-data-table',{staticClass:"pricing",attrs:{"id":"pricing-table","headers":_vm.headers,"items":_vm.items,"hide-actions":""},scopedSlots:_vm._u([{key:"headers",fn:function(heads){return _vm._l((heads.headers),function(headerItem,headerIndex){return _c('th',{key:headerIndex,class:headerItem.class,attrs:{"role":"columnheader","scope":"col"}},[_c('div',[_vm._v(_vm._s(headerItem.text1))]),_c('div',[_vm._v(_vm._s(headerItem.text2))])])})}},{key:"items",fn:function(props){return [_c('td',{attrs:{"align":"center"}},[_c('div',{staticClass:"flex-center"},[_c('v-btn',{attrs:{"id":`quote-pricing-table-tripindex-${_vm.tripIndex}-select-${
              props.item.pricingMethod
            }-option-${props.item.present ? 'rate-present' : 'rate-absent'}`,"color":"primary","outlined":"","disabled":!props.item.present},on:{"click":(evt) =>
                _vm.suggestPrice(props.item.rateValue, props.item.pricingMethod)}},[_vm._v(" Select ")]),_c('div',{staticClass:"to-the-right"},[_vm._v(" "+_vm._s(props.item.type)+" ")])],1)]),(props.item.present)?_vm._l((props.item.rates),function(rate,rateIndex){return _c('td',{key:rateIndex,class:rate.class,attrs:{"align":"center"}},[_c('div',{style:(`display: flex; ${
              Array.isArray(rate.tooltip) && rate.tooltip.length > 0
                ? 'justify-content: flex-end; width: 80px;'
                : 'justify-content: center;'
            }`)},[_c('div',{style:(rate.autoselect ? 'font-weight: bold;' : ''),attrs:{"id":`quote-pricing-table-tripindex-${_vm.tripIndex}-price-${props.item.pricingMethod}`}},[_vm._v(" "+_vm._s(_vm.currencyFilter(rate.price))+" ")]),(Array.isArray(rate.tooltip) && rate.tooltip.length > 0)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"markup-tool-tip",attrs:{"id":`markup-tool-tip-icon-${rateIndex}`}},on),[_vm._v(" info ")])]}}],null,true)},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"}},_vm._l((rate.tooltip),function(markup,i){return _c('div',{key:i,attrs:{"id":`markup-tool-tip-tooltip-${i}`}},[_vm._v(" "+_vm._s(`${markup.name} - ${markup.markupPercentage}%`)+" ")])}),0)]):_vm._e()],1)])}):_c('td',[_vm._v("No Data Available. Select Pricing Method.")])]}}])}),(_vm.showPricingLogs)?_c('QuotesAutomatedPricingTableLogModal',{attrs:{"pricing-logs":_vm.pricingLogs}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }