<template>
  <v-flex>
    <v-layout
      v-for="(tripDate, tripDateIndex) in tripDates"
      :key="`pickup-dates-${tripDateIndex}`"
      row
    >
      <v-layout column class="date-selector mr-2">
        Date
        <CRInput
          :value="tripDates[tripDateIndex].date"
          type="date"
          placeholder="Select Date"
          append-icon="delete"
          class="mt-1"
          autocomplete="off"
          :disabled="isModeView"
          @click-append="removeDate(tripAddressHash, tripDateIndex)"
          @change="editDate(tripAddressHash, tripDateIndex, $event)"
        />
      </v-layout>
      <v-layout column>
        <QuoteFormAddressDetailDateTimeSelector
          :times="tripDate.times"
          @on-add-time="addTime(tripAddressHash, tripDateIndex, $event)"
          @on-remove-time="removeTime(tripAddressHash, tripDateIndex, $event)"
        />
      </v-layout>
    </v-layout>
    <CRButton
      v-if="!isModeView"
      color="white"
      text-color="primary"
      :disabled="!tripAddress.address.addressName"
      class="mb-4"
      :style="{ 'margin-top': tripDates.length === 0 ? '27px' : 0 }"
      @click="addDate(tripAddressHash)"
    >
      Add Date
    </CRButton>
    <v-layout row class="mr-2">
      <v-flex
        v-show="
          !isModeView &&
          !currentFinalDropoffDate.tripAddressHash &&
          !showFinalDropoffDate
        "
      >
        <CRButton
          color="white"
          text-color="primary"
          :disabled="!tripAddress.address.addressName"
          @click="showFinalDropoffDate = true"
        >
          Add Final Dropoff Date
        </CRButton>
        <p
          v-show="showFinalDropoffDateRequired && !finalDropoffDate.date"
          class="margin-a-0 margin-l-4 text-error"
        >
          Final Dropoff Date is Required
        </p>
      </v-flex>
      <v-flex
        v-show="showFinalDropoffDate || isCurrentFinalDropoffDateThisAddress"
      >
        <v-layout row>
          <v-flex class="date-selector mr-2">
            Final Dropoff Date
            <CRInput
              :id="`final-dropoff-date-selector-${tripAddressHash}`"
              v-model="finalDropoffDate.date"
              type="date"
              placeholder="Select Date"
              class="mt-1"
              autocomplete="off"
              hide-details
              :disabled="isModeView || currentFinalDropoffDate.tripAddressHash"
            />
          </v-flex>
          <v-flex
            v-if="!currentFinalDropoffDate.tripAddressHash"
            class="time-input mt-4 mr-2"
          >
            <CRInput
              :id="`final-dropoff-time-selector-${tripAddressHash}`"
              v-model="finalDropoffDate.time"
              class="mb-2"
              hide-details
              type="time"
            />
          </v-flex>
          <v-flex
            v-else
            class="time-label pl-1 pr-1 pt-2 pb-2 mb-2 mr-2 mt-4"
            :style="cssVars"
          >
            <div class="ml-3">
              {{ formatTime(currentFinalDropoffDate.time) }}
            </div>
          </v-flex>
          <v-flex class="mt-4 no-grow">
            <CRButton
              v-show="!currentFinalDropoffDate.tripAddressHash"
              :id="`final-dropoff-confirm-btn-${tripAddressHash}`"
              color="secondary"
              text-color="primary"
              class="mr-2"
              @click="setTripVehicleGroupFinalDropoffDate"
            >
              Confirm
            </CRButton>
          </v-flex>
          <v-flex class="no-grow">
            <CRIcon
              v-show="!isModeView"
              :id="`close-final-dropoff-btn-${tripAddressHash}`"
              class="mb-2 margin-t-8"
              color="primary"
              :view-box="'0 0 24 24'"
              @click.native="closeFinalDropoff"
            >
              close
            </CRIcon>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-flex>
</template>

<script>
import { mapActions } from 'vuex'
import QuoteFormAddressDetailDateTimeSelector from '@/components/QuoteFormAddressDetailDateTimeSelector.vue'
import { EventBus } from '@/utils/event-bus'
import { deepClone } from '@/utils/deepClone'
import CRColor from '@/cr/color/CRColor'
import { twentyFourHourToTwelveHourTime } from '@/utils/time'

export default {
  components: {
    QuoteFormAddressDetailDateTimeSelector,
  },
  props: {
    tripAddress: { type: Object, default: () => {} },
  },
  data() {
    return {
      showFinalDropoffDate: false,
      finalDropoffDate: {
        date: null,
        time: null,
        tripAddressHash: null,
      },
      showFinalDropoffDateRequired: false,
    }
  },
  computed: {
    mode() {
      return this.$store.getters['quoteForm/getMode']
    },
    isModeView() {
      return this.mode === 'view'
    },
    cssVars() {
      return {
        '--color': this.color,
        '--background-color': this.backgroundColor,
        '--cursor': this.isModeView ? 'default' : 'pointer',
      }
    },
    backgroundColor() {
      return new CRColor(this.color).lighten(30)
    },
    color() {
      return this.$store.getters['quoteForm/getCurrentColor']
    },
    tripDates() {
      return (
        this.$store.getters['quoteForm/getCurrentTripDatesForAddress'](
          this.tripAddressHash
        ) || []
      )
    },
    tripAddressHash() {
      return this.tripAddress.tripAddressHash
    },
    currentFinalDropoffDate() {
      return this.$store.getters['quoteForm/getCurrentFinalDropoffDate']
    },
    isCurrentFinalDropoffDateThisAddress() {
      return (
        this.currentFinalDropoffDate?.tripAddressHash === this.tripAddressHash
      )
    },
  },
  watch: {
    currentFinalDropoffDate: {
      immediate: true,
      handler(value) {
        this.showFinalDropoffDate = false
        if (
          value.tripAddressHash === this.tripAddressHash ||
          !value.tripAddressHash
        ) {
          this.finalDropoffDate = deepClone(value)
        }
      },
    },
  },
  mounted() {
    EventBus.$on(
      'show-dropoff-date-required',
      () => (this.showFinalDropoffDateRequired = true)
    )
  },
  methods: {
    ...mapActions({
      addDateModule: 'quoteForm/addDate',
      editDateModule: 'quoteForm/editDate',
      removeDateModule: 'quoteForm/removeDate',
      addTimeModule: 'quoteForm/addTime',
      removeTimeModule: 'quoteForm/removeTime',
      setTripVehicleGroupFinalDropoffDateModule:
        'quoteForm/setTripVehicleGroupFinalDropoffDate',
    }),
    formatTime(time) {
      return twentyFourHourToTwelveHourTime(time)
    },
    addDate(tripAddressHash) {
      this.addDateModule(tripAddressHash)
      EventBus.$emit('get-estimations')
    },
    editDate(tripAddressHash, tripDateIndex, newDate) {
      this.editDateModule({ tripAddressHash, tripDateIndex, newDate })
      EventBus.$emit('get-estimations')
    },
    removeDate(tripAddressHash, tripDateIndex) {
      this.removeDateModule({ tripAddressHash, tripDateIndex })
      EventBus.$emit('get-estimations')
    },
    addTime(tripAddressHash, tripDateIndex, time) {
      this.addTimeModule({ tripAddressHash, tripDateIndex, time })
      EventBus.$emit('get-estimations')
    },
    removeTime(tripAddressHash, tripDateIndex, timeIndex) {
      this.removeTimeModule({ tripAddressHash, tripDateIndex, timeIndex })
      EventBus.$emit('get-estimations')
    },
    setTripVehicleGroupFinalDropoffDate() {
      this.finalDropoffDate.tripAddressHash = this.tripAddress.tripAddressHash
      this.setTripVehicleGroupFinalDropoffDateModule({
        finalDropoffDate: this.finalDropoffDate,
      })
      this.showFinalDropoffDate = false
    },
    closeFinalDropoff() {
      if (this.showFinalDropoffDate) {
        this.finalDropoffDate = {
          date: null,
          time: null,
          tripAddressHash: null,
        }
        this.showFinalDropoffDate = false
      } else {
        this.setTripVehicleGroupFinalDropoffDateModule({
          finalDropoffDate: {
            date: null,
            time: null,
            tripAddressHash: null,
          },
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.date-selector {
  min-width: 200px;
  max-width: 200px;
}
.time-label {
  background: var(--background-color);
  border: 1px var(--color) solid;
  border-radius: 5px;
  max-width: 105px;
  min-width: 105px;
  color: $gray-dark;
  font-size: 15px;
  height: fit-content;
}
.time-input {
  min-width: 120px;
  max-width: 120px;
}
</style>
