import { CreditCardType } from "./enum"

/**
 * Determines the card type based on the given card number.
 *
 * @param cardNumber - The credit card number as a string.
 * @returns The card type as a string ('mastercard', 'visa', 'discover', 'amex', 'diners', or 'default').
 */
export const getCardType = (cardNumber: string | undefined): string => {
  if (cardNumber?.startsWith('5')) {
    return CreditCardType.Mastercard
  } else if (cardNumber?.startsWith('4')) {
    return CreditCardType.Visa
  } else if (cardNumber?.startsWith('6')) {
    return CreditCardType.Discover
  } else if (['34', '37'].includes(cardNumber?.substring(0, 2))) {
    return CreditCardType.AmericanExpress
  } else if (['30', '36', '38'].includes(cardNumber?.substring(0, 2))) {
    return CreditCardType.Diners
  } else {
    return 'default'
  }
}

/**
 * Provides the mask format for the card number based on the card type.
 *
 * @param cardType - The type of the credit card ('amex', 'diners', or any other).
 * @returns The mask format as a string.
 */
export const getCardNumberMask = (cardType: string): string => {
  if (cardType === CreditCardType.AmericanExpress) {
    return '#### ###### #####'
  }
  if (cardType === CreditCardType.Diners) {
    return '#### ###### ####'
  }
  return '#### #### #### ####'
}

/**
 * Determines the expiration length category based on the raw expiration value.
 *
 * @param cardExpirationRawValue - The raw expiration value as a string.
 * @returns The expiration length category as a string ('longer' or 'default').
 */
export const getCardExpirationLength = (cardExpirationRawValue: string | undefined): string => {
  if (cardExpirationRawValue?.startsWith('0')) {
    return 'longer'
  }
  if (cardExpirationRawValue?.startsWith('1')) {
    if (
      cardExpirationRawValue?.[1] === '0' ||
      cardExpirationRawValue?.[1] === '1'
    ) {
      return 'longer'
    }
    if (cardExpirationRawValue?.[1] === '2') {
      if (cardExpirationRawValue?.[2] !== '0') {
        return 'longer'
      }
    }
  }
  return 'default'
}

/**
 * Converts a card expiration date from MM/YY format to a raw string without separators.
 *
 * @param expiration - The card expiration date in MM/YY format.
 * @returns The raw expiration value as a string without separators, or null if input is undefined.
 */
export const getCardExpirationRawValue = (expiration: string | undefined): string | null => {
  if (expiration) {
    return expiration.split('/').join('')
  }
  return null
}

/**
 * Provides the mask format for the card expiration based on the expiration length category.
 *
 * @param cardExpirationLength - The expiration length category ('longer' or 'default').
 * @returns The mask format for the card expiration as a string.
 */
export const getCardExpirationMask = (cardExpirationLength: string): string => {
  const defaultMask = '#/####'
  const longerMask = '##/####'
  return cardExpirationLength === 'longer' ? longerMask : defaultMask
}
