import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { AxiosResponse } from 'axios'
import { Recurrence, RecurrenceEventDateListResult } from '@/models/dto/Recurrence'

const httpService: HttpService = new HttpService()

export default {
  getRecurrenceEventDateList(recurrences: Recurrence[]): Promise<AxiosResponse<RecurrenceEventDateListResult>> {
    const url = `https://${baseUrl()}/quotes/getRecurrenceEventDateList`
    return httpService.post(url, { recurrences })
  },
}
