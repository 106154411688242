<template>
  <div class="reduce-charge">
    <div class="reduce-charge--content">
      <v-form ref="charge-form">
        <div class="reduce-charge--amount">
          <CRInput
            :id="`reduce-charge-form-payment-amount`"
            key="reduce-charge-form-payment-amount"
            v-model="reductionAmount"
            class="reduce-charge--input"
            type="number"
            min="0"
            prefix="-$"
            :label="
              Object.keys(row).length && !rows.length
                ? 'Amount'
                : 'Amount Per Reservation'
            "
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Amount Is Required',
                error: 'Amount Is Required',
              }),
              (val) => (val <= 0 ? 'Reduction Must Be Greater Than 0' : true),
            ]"
          />
          <CRSelect
            :id="`-reduce-charge-select-charge-type`"
            v-model="chargeType"
            :items="chargeTypes"
            label="Type"
            item-text="label"
            item-value="id"
            :rules="[
              isRequired(true, isNotEmpty, {
                req: 'Charge Type Required',
                error: 'Charge Type Required',
              }),
            ]"
          />
          <CRRichText
            :id="`-reduce-charge-input-payment-customer-notes`"
            multi-line
            label="Payment Notes"
            placeholder="Payment notes for you and the customer."
            @htmlchange="updateCustomerNoteHtml"
            @textchange="updateCustomerNoteText"
          />
          <br />
          <CRRichText
            :id="`-reduce-charge-input-payment-notes`"
            multi-line
            label="Office Notes"
            placeholder="Payment notes for internal use only (customers will not see this)."
            class="mb-3"
            @htmlchange="updateOfficeNoteHtml"
            @textchange="updateOfficeNoteText"
          />
          <ClassificationSelector
            id="-add-charge-classification-selector"
            ref="classification-selector"
            v-model="classificationId"
            :classification-type-id="3"
          />
          <v-checkbox
            v-model="sendEmail"
            color="primary"
            class="send-email-checkbox"
            label="Send Email"
          />
        </div>
      </v-form>
    </div>
    <div class="reduce-charge--button-spacer"></div>
    <div
      id="reduce-charge--submit-btn"
      class="reduce-charge--submit-btn"
      @click="submit"
    >
      <span v-if="!loading">Reduce</span>
      <CRProgressCircular v-else :size="27" />
    </div>
  </div>
</template>

<script>
import charges from '@/services/charges'
import { isRequired, isNotEmpty } from '@/utils/validators'
import { authComputed } from '@/state/helpers'
import { mapActions } from 'vuex'
import * as logger from '@/utils/logger'
import ClassificationSelector from '@/components/ClassificationSelector.vue'

export default {
  components: {
    ClassificationSelector,
  },
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      chargeTypes: [],
      chargeType: '',
      classificationId: null,
      reductionAmount: null,
      notes: '',
      notesHtml: '',
      customerNotes: '',
      customerNotesHtml: '',
      sendEmail: true,
      showAllRows: false,
      isRequired,
      isNotEmpty,
    }
  },
  computed: {
    ...authComputed,
    previewRows() {
      return this.rows.slice(0, 3)
    },
  },
  async mounted() {
    // get charge types
    let { data: chargeTypes } = await this.$store.dispatch(
      'types/getChargeTypes'
    )
    chargeTypes = chargeTypes.filter(
      (charge) => charge.key !== 'cancellation_penalty'
    )
    this.chargeTypes = chargeTypes
  },
  methods: {
    ...mapActions({ showAlert: 'app/showAlert' }),
    cancel() {
      this.$emit('row-collapse')
      this.$emit('close-modal')
    },
    updateCustomerNoteHtml(value) {
      this.customerNotesHtml = value
    },
    updateCustomerNoteText(value) {
      this.customerNotes = value
    },
    updateOfficeNoteHtml(value) {
      this.notesHtml = value
    },
    updateOfficeNoteText(value) {
      this.notes = value
    },
    async submit() {
      if(this.loading) {
        return
      }

      this.loading = true
      await this.$forceUpdate()
      const isValid =
        (await this.$refs['charge-form'].validate()) &&
        (await this.$refs['classification-selector'].validate())
      if (!isValid) {
        this.loading = false
        return
      }

      if (Object.keys(this.row).length) {
        // detail version
        const payload = [
          {
            chargeType: this.chargeType,
            userId: this.currentUser.userId,
            reservationId: this.row.reservationId,
            reservationVersion: this.row.version,
            companyId: this.currentUser.companyId,
            reductionAmount: this.reductionAmount,
            sendEmail: this.sendEmail,
            customerNotes: {
              note: this.customerNotes,
              html: this.customerNotesHtml,
            },
            notes: {
              note: this.notes,
              html: this.notesHtml,
            },
            classificationId: this.classificationId,
          },
        ]
        let reservation
        try {
          if (this.row.reservationType) {
            reservation = await charges.reduceReferralCharges(payload)
          } else {
            reservation = await charges.reduceReservationCharges(payload)
          }
        } catch (err) {
          this.showAlert({
            type: 'error',
            message: 'Error Submitting reduction, Please try again',
          })
          this.loading = false
          logger.error(err)
          return
        }

        if (!reservation || !reservation.data.successful) {
          this.loading = false
          this.showAlert({
            type: 'error',
            message: reservation.data?.message
              ? reservation.data.message
              : 'Error Submitting reduction, Please try again',
          })
          logger.error(reservation)
        } else {
          this.showAlert({
            message: 'Charge reduction submitted!',
          })
          this.loading = false
          this.cancel()
        }
      } else {
        // multi-select version
        const payload = this.rows.map((reservation) => {
          return {
            chargeType: this.chargeType,
            userId: this.currentUser.userId,
            reservationId: reservation.item.reservationId,
            reservationVersion: reservation.item.version,
            companyId: this.currentUser.companyId,
            reductionAmount: this.reductionAmount,
            sendEmail: this.sendEmail,
            customerNotes: {
              note: this.customerNotes,
              html: this.customerNotesHtml,
            },
            notes: {
              note: this.notes,
              html: this.notesHtml,
            },
            classificationId: this.classificationId,
          }
        })
        let reservation
        try {
          if (this.rows[0].item.reservationType) {
            reservation = await charges.reduceReferralCharges(payload)
          } else {
            reservation = await charges.reduceReservationCharges(payload)
          }
        } catch (err) {
          this.showAlert({
            type: 'error',
            message: 'Error Submitting reduction, Please try again',
          })
          this.loading = false
          logger.error(err)
          return
        }

        if (!reservation || !reservation.data.successful) {
          this.loading = false
          this.showAlert({
            type: 'error',
            message: reservation.data?.message
              ? reservation.data.message
              : 'Error Submitting reduction, Please try again',
          })
          logger.error(reservation)
        } else {
          this.showAlert({
            message: 'Charge reduction submitted!',
          })
          this.loading = false
          this.cancel()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.reduce-charge {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 500px;

  ::v-deep .v-text-field .v-input__slot {
    background-color: $input-background-gray !important;
    border: 1px solid $input-border-gray !important;
  }

  .reduce-charge--content {
    flex: 1;
  }

  .reduce-charge--amount {
    padding: 24px 48px;
  }

  .reduce-charge--button-spacer {
    min-height: 71px;
    width: 100%;
  }

  .reduce-charge--submit-btn {
    display: flex;
    flex-direction: column;
    background: $primary;
    font-size: 18px;
    color: white;
    bottom: 0;
    height: 71px;
    width: inherit;
    padding: 24px 0;
    position: fixed;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
}

.action-box {
  width: 50%;
  margin: auto;
  border: 1px solid $gray-light;
  border-radius: 4px;
}

h3 {
  margin-bottom: 15px;
}

.actions-row .v-btn:last-of-type {
  margin-right: 0;
}

.reservation-chip {
  margin: 4px 15px 20px 0;
}

::v-deep .v-textarea .v-text-field__details {
  display: none;
}

.send-email-checkbox {
  margin-top: 0;
}
</style>
