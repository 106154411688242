<template>
  <div class="pa-0 mt-3 summary-by-vehicle">
    <v-layout ref="header" row class="mb-2 pt-2 pb-2">
      <v-layout column class="header-vehicle">Vehicle</v-layout>
      <v-layout column class="header-date">Date</v-layout>
      <v-layout
        v-for="(tripAddress, tripAddressIndex) in tripAddresses"
        :key="tripAddressIndex"
        class="header-address"
        column
      >
        {{
          tripAddress.address.title ||
          tripAddress.address.addressName ||
          `Address #${tripAddressIndex + 1}`
        }}
      </v-layout>
    </v-layout>
    <v-layout
      v-for="(tripVehicleGroup, tripVehicleGroupIndex) in tripVehicleGroups"
      :key="tripVehicleGroupIndex"
      column
    >
      <v-layout
        class="vehicle-row pb-1 pt-1"
        :style="`border-color: ${tripVehicleGroup.color}`"
        row
      >
        <v-layout class="ml-2 vehicle-group-name" column>
          {{ tripVehicleGroup.name || 'All Vehicle Groups' }}
        </v-layout>
        <v-layout column>
          <v-layout
            v-for="(date, dateIndex) in getSortedDates(tripVehicleGroup)"
            :key="dateIndex"
            row
          >
            <v-layout class="trip-date" column>
              {{ formatDate(date) }}
            </v-layout>
            <v-layout column class="address-column">
              <v-layout
                v-for="(row, rowIndex) in getNumberOfRows(
                  tripVehicleGroup,
                  date
                )"
                :key="rowIndex"
                ref="time-row"
                class="time-row"
                row
              >
                <v-layout
                  v-for="(time, timeIndex) in getTimesForRow(
                    tripVehicleGroup,
                    date,
                    rowIndex
                  )"
                  :key="timeIndex"
                  class="time"
                  :style="{
                    'background-color': shouldShowBackgroundColor(
                      tripVehicleGroup,
                      dateIndex,
                      rowIndex
                    )
                      ? lightenColor(tripVehicleGroup.color)
                      : '',
                  }"
                  column
                >
                  {{
                    `${
                      isFinalDropoffDate(tripVehicleGroup, date, time)
                        ? 'Final DO: '
                        : ''
                    }${formatTime(time)}`
                  }}
                </v-layout>
              </v-layout>
            </v-layout>
          </v-layout>
        </v-layout>
      </v-layout>
      <v-layout
        v-if="tripVehicleGroupIndex < tripVehicleGroups.length - 1"
        :style="`width: ${dividerWidth}`"
        row
      >
        <v-divider class="mt-2 mb-2" />
      </v-layout>
    </v-layout>
  </div>
</template>

<script>
import CRColor from '@/cr/color/CRColor'
import {
  yearMonthDayToMonthDayYear,
  twentyFourHourToTwelveHourTime,
} from '@/utils/time'

export default {
  props: {
    tripAddresses: { type: Array, default: () => [] },
    tripVehicleGroups: { type: Array, default: () => [] },
  },
  data() {
    return {
      dividerWidth: null,
    }
  },
  computed: {
    timesByVehicleGroup() {
      return this.$store.getters['quoteForm/getSummaryByVehicleGroup']
    },
  },
  created() {
    window.addEventListener('resize', this.getDividerWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getDividerWidth)
  },
  updated() {
    this.getDividerWidth()
  },
  mounted() {
    this.getDividerWidth()
  },
  methods: {
    getSortedDates(tripVehicleGroup) {
      return Object.keys(
        this.timesByVehicleGroup[tripVehicleGroup.tripVehicleGroupHash]
      )
        .filter((date) => date !== 'null')
        .sort()
    },
    getNumberOfRows(tripVehicleGroup, date) {
      return (
        this.timesByVehicleGroup[tripVehicleGroup.tripVehicleGroupHash][date]
          .length / this.tripAddresses.length
      )
    },
    getTimesForRow(tripVehicleGroup, date, rowIndex) {
      const startIndex = rowIndex * this.tripAddresses.length
      return this.timesByVehicleGroup[tripVehicleGroup.tripVehicleGroupHash][
        date
      ].slice(startIndex, startIndex + this.tripAddresses.length)
    },
    shouldShowBackgroundColor(tripVehicleGroup, dateIndex, rowIndex) {
      const dates = this.getSortedDates(tripVehicleGroup)
      let previousRows = 0
      for (let i = 0; i < dateIndex; i++) {
        previousRows += this.getNumberOfRows(tripVehicleGroup, dates[i])
      }
      if ((rowIndex + previousRows) % 2 === 0) {
        return true
      }
      return false
    },
    lightenColor(color) {
      return new CRColor(color).lighten(35)
    },
    formatDate(date) {
      return yearMonthDayToMonthDayYear(date)
    },
    formatTime(time) {
      return twentyFourHourToTwelveHourTime(time)
    },
    getDividerWidth() {
      this.dividerWidth = `${this.$refs.header.scrollWidth}px`
    },
    isFinalDropoffDate(tripVehicleGroup, date, time) {
      return (
        tripVehicleGroup?.finalDropoffDate?.date === date &&
        tripVehicleGroup?.finalDropoffDate?.time === time
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.summary-by-vehicle {
  overflow-x: scroll;
}
.header-vehicle {
  max-width: 225px;
  min-width: 225px;
  margin-left: 13px;
}
.header-date {
  max-width: 100px;
  min-width: 100px;
}
.header-address {
  align-items: center;
  flex: 0 1 100%;
  min-width: 150px;
}
.vehicle-row {
  border-left: 5px solid;
  border-radius: 5px;
}
.vehicle-group-name {
  min-width: 225px;
  max-width: 225px;
}
.trip-date {
  max-width: 100px;
  min-width: 100px;
}
.address-column {
  width: fit-content;
}
.time-row {
  width: 100%;
}
.time {
  flex: 0 1 100%;
  min-width: 150px;
  align-items: center;
}
</style>
