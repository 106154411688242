export const buildReservationPath = (reservation, forceUseParent = false) => {
  return `/reservations/${
    forceUseParent
      ? reservation.parentReservationId || reservation.reservationId
      : reservation.managedId
  }`
}
export const buildReservationRoute = (reservation, forceUseParent = false) => {
  return {
    name: 'reservation-detail',
    params: {
      id: forceUseParent
        ? reservation.parentReservationId || reservation.reservationId
        : reservation.managedId,
    },
  }
}

export const reservationViewPath = (reservation) => {
  return `/reservations/${reservation.managedId}`
}
