<template>
  <div class="customize-email">
    <div class="customize-email__form">
      <div class="customize-email__form__header">
        <div
          class="customize-email__form__header__close-email-button"
          @click="$emit('close-email')"
        >
          <CRIcon height="32" width="32">keyboard_arrow_left</CRIcon>
          <h3>Back to Invoice</h3>
        </div>
        <CRButton style="inline-block" outline color="primary" @click="reset">
          Reset
        </CRButton>
      </div>
      <div class="customize-email__form__body">
        <CRTextField v-model="customEmail.to" label="To" />
        <CRTextField v-model="customEmail.subject" label="Subject" />
        <CRTextArea v-model="customEmail.opener" auto-grow label="Opener" />
        <CRTextArea
          v-model="customEmail.requested"
          auto-grow
          label="Requested User Action"
        />
        <CRTextArea v-model="customEmail.closing" auto-grow label="Closing" />
        <CRTextField v-model="customEmail.attachment" label="Attached Name" />
      </div>
    </div>
    <div class="customize-email__preview">
      <h3 class="customize-email__preview__title">Email Preview</h3>
      <div class="customize-email__preview__body">
        <span class="customize-email__preview__body__field-label">To:</span>
        {{ customEmail.to }}
        <br />
        <span class="customize-email__preview__body__field-label">
          Subject:
        </span>
        {{ customEmail.subject }}
        <br />
        <v-divider />
        <pre>{{ opener }}</pre>
        <br />
        <pre v-if="customEmail.requested !== ''">{{ requested }}</pre>
        <pre v-else>{{ actionText }}</pre>
        <a v-if="invoiceUrl" :href="pathToInvoice" target="_blank">
          <strong class="link">{{ linkText }}</strong>
        </a>
        <br />
        <pre>{{ closing }}</pre>
      </div>
      <div class="customize-email__preview__save-button" @click="save">
        Save and Continue
      </div>
    </div>
  </div>
</template>

<script>
import invoices from '@/services/invoices'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    row: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Array,
      default: () => [],
    },
    pdf: {
      type: Boolean,
    },
    invoiceUrl: {
      type: Boolean,
    },
  },
  data() {
    return {
      customEmail: {
        to: null,
        subject: null,
        opener: null,
        requested: null,
        closing: null,
        attachment: null,
        checkoutText: null,
      },
      defaultEmail: {
        to: null,
        subject: null,
        opener: null,
        requested: null,
        closing: null,
        attachment: null,
        checkoutText: null,
      },
    }
  },
  computed: {
    ...authComputed,
    opener() {
      if (this.customEmail.opener !== null) {
        const opener = this.customEmail.opener.replace(/(?:\r\n|\r|\n)/g, '\n')
        return opener
      }
      return ''
    },
    requested() {
      if (this.customEmail.requested !== null) {
        const requested = this.customEmail.requested.replace(
          /(?:\r\n|\r|\n)/g,
          '\n'
        )
        return requested
      }
      return ''
    },
    closing() {
      if (this.customEmail.closing !== null) {
        const closing = this.customEmail.closing.replace(
          /(?:\r\n|\r|\n)/g,
          '\n'
        )
        return closing
      }
      return ''
    },
    actionText() {
      if (this.pdf && this.invoiceUrl) {
        return 'Please complete your payment through your payment link or find the attached invoice.'
      } else if (this.pdf) {
        return 'Please complete your payment through the attached invoice.'
      } else if (this.invoiceUrl) {
        return 'Please complete your payment through your payment link.'
      }
      return ''
    },
    linkText() {
      return 'Pay Online\n'
    },
    pathToInvoice() {
      if (Object.keys(this.row).length !== 0) {
        return `/invoice/${this.row.hash}`
      }

      let hashString = this.rows
        .filter((row) => !!row.item?.hash)
        .map((row) => row.item.hash)
        .join(',')
      return `/invoice/${hashString}`
    },
  },
  mounted() {
    this.loadDefaults()
  },
  methods: {
    async loadDefaults() {
      let row = this.row
      if (
        (!this.row || JSON.stringify(this.row) === '{}') &&
        this.rows.length
      ) {
        row = this.rows[0].item
      }

      const customizationsResponse = await invoices.getEmailCustomizations()
      const customizations = customizationsResponse.data
      this.defaultEmail.to = row?.customerEmail || ''
      this.defaultEmail.subject =
        customizations.subject ||
        `Your Invoice from ${this.currentUser.companyName} is available`
      this.defaultEmail.opener =
        customizations.opener ||
        `Hi ${
          row.customerName.split(' ')[0] || 'Sample Name'
        },\n\nThank you for booking your trip with ${
          this.currentUser.companyName
        }`
      this.defaultEmail.requested =
        customizations.requestedUserAction ||
        'Please fill out the invoice attached to this email and return to us promptly.'
      this.defaultEmail.closing =
        customizations.closing ||
        'We value your business and look forward to providing you with quality service.\n\nThanks,\n\n' +
          this.currentUser.companyName
      this.customEmail = { ...this.defaultEmail }
    },
    reset() {
      this.customEmail = { ...this.defaultEmail }
      this.$emit('email-customized', false)
    },
    save() {
      this.$emit('input', this.customEmail)
      this.$emit('email-customized', true)
      this.$emit('close-email')
    },
  },
}
</script>

<style lang="scss" scoped>
.customize-email {
  display: flex;
  flex-direction: row;
  flex: 1;

  &__form,
  &__preview {
    display: flex;
    flex-direction: column;
  }

  &__form {
    width: 50%;
    &__header {
      display: flex;
      justify-content: space-between;
      padding: 16px 48px 16px 16px;
      align-items: center;
      &__close-email-button {
        padding-top: 5px;
        display: flex;
        justify-content: left;
        cursor: pointer;
      }
    }
    &__body {
      padding: 0 48px 48px;
    }
  }
  &__preview {
    width: 50%;
    background: $secondary;
    padding: 48px 0;
    position: relative;
    &__title {
      margin: 0 48px 22px;
    }
    &__body {
      background: white;
      padding: 24px;
      margin: 0 48px;
      pre {
        font-family: Lato, sans-serif;
        white-space: pre-wrap;
      }
      &__field-label {
        color: $gray-medium-light;
      }
    }
    &__save-button {
      background: $primary;
      font-size: 18px;
      color: white;
      position: absolute;
      bottom: 0;
      padding: 24px 0;
      text-align: center;
      font-weight: bold;
      cursor: pointer;
      width: 100%;
    }
  }
}
</style>
