<template>
  <v-layout class="action-links" justify-end row>
    <div
      v-for="(item, idx) in menu.filter((m) => m.quickAccess)"
      :id="`quotes-table-actions-col-${rowProps.index}-action-link-${idx}`"
      :key="`quotes-table-actions-col-${rowProps.index}-action-link-${idx}-${
        rowProps.item.quoteId ? rowProps.item.quoteId : ''
      }`"
      class="action-link-container"
    >
      <v-tooltip top>
        <template #activator="action">
          <span
            style="max-height: 24px"
            v-on="action.on"
            @click.stop="() => handleClick(item)"
          >
            <CRIcon
              v-if="item.click"
              :id="`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
              }`"
              :color="(item.color && item.color()) || 'primary'"
              :view-box="item.viewBox"
              :width="item.width"
              :height="item.height"
            >
              {{ item.icon() }}
            </CRIcon>
            <router-link
              v-if="item.path"
              :id="`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
              }`"
              :to="{ path: item.path() }"
              class="action-link"
              :target="item.target ? item.target : '_self'"
            >
              <span style="max-height: 24px">
                <CRIcon
                  :color="(item.color && item.color()) || 'primary'"
                  :icon-name="item.id"
                  :view-box="item.viewBox"
                  :width="item.width"
                  :height="item.height"
                >
                  {{ item.icon() }}
                </CRIcon>
              </span>
            </router-link>
            <a
              v-if="item.href"
              :id="`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : idx
              }`"
              :href="item.href()"
              class="action-link"
              :target="item.target ? item.target : '_self'"
            >
              <CRIcon
                :color="(item.color && item.color()) || 'primary'"
                :view-box="item.viewBox"
                :width="item.width"
                :height="item.height"
              >
                {{ item.icon() }}
              </CRIcon>
            </a>
          </span>
        </template>
        <span>{{ item.title() }}</span>
      </v-tooltip>
    </div>
    <div class="more-vert-parent">
      <v-menu bottom transition="slide-x-transition">
        <template #activator="{ on: { click } }">
          <CRIcon
            :id="`quote-form-${rowProps.index}-table-row-kebab-menu`"
            class="cursor-pointer"
            color="primary"
            view-box="0 0 24 24"
            style="max-height: 24px"
            @click.native="click"
          >
            more_vert
          </CRIcon>
        </template>
        <v-list>
          <template
            v-for="(item, i) in menu.filter((m) =>
              m.condition ? m.condition() : true
            )"
          >
            <v-list-tile
              v-if="item.condition ? item.condition() : true"
              :id="`action-require-wrapper-${
                item.id ? item.id.replace(/\s+/g, '-').toLowerCase() : i
              }`"
              :key="`action-list-tile-${i}-${item.title ? item.title : ''}`"
              @click="handleClick(item)"
            >
              <v-list-tile-title>
                <div class="action-link">
                  <!-- MENU ACTIONS -->
                  <CRIcon
                    v-if="item.icon"
                    :color="(item.color && item.color()) || 'primary'"
                    :view-box="item.viewBox"
                    :width="item.width"
                    :height="item.height"
                  >
                    {{ item.icon() }}
                  </CRIcon>
                  <span class="action-text">{{ item.title() }}</span>
                </div>
              </v-list-tile-title>
            </v-list-tile>
          </template>
        </v-list>
      </v-menu>
    </div>
  </v-layout>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      default: () => ({}),
    },
    handleAction: {
      type: Function,
      default: () => null,
    },
    rowProps: {
      type: Object,
      default: () => ({}),
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(item) {
      if (item.path) {
        this.$router.push({ path: item.path() })
      } else if (item.href) {
        window.open(item.href(), '_self')
      } else if (item.click) {
        item.click()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.action-link,
.action-links a {
  display: flex;
  align-items: center;

  i.icon-credit-card {
    padding-right: 2px;
  }
}

.action-text {
  padding-left: 8px;
  font-size: 12px;
  text-decoration: none;
  color: $primary;
}

.action-link-container {
  margin-right: 2px;

  &:last-child {
    margin-right: 0;
  }

  display: flex;
  align-items: center;
}

.more-vert-parent {
  max-height: 26px;
}
</style>
