import { baseUrl } from '@/utils/env'
import { HttpService } from '@/services/common/HttpService'
import { ApiResult, Stop, UpdateStopNotesPayload } from '@/models/dto'
import { AxiosResponse } from 'axios'

const httpService: HttpService = new HttpService()

export const updateStopNotes = async (stopId: number, payload: UpdateStopNotesPayload): Promise<AxiosResponse<ApiResult>> =>
  httpService.patch(`https://${baseUrl()}/stops/${stopId}/note`, payload)

export const updateFlightInformation = async (stopId: number, payload: Stop) =>
  httpService.patch(`https://${baseUrl()}/stops/${stopId}/updateFlightInfo`, payload)
