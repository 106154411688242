<template>
  <CRSidebarDialog v-model="show">
    <template #title>
      {{ camelCaseToTitleCase(value) }}
    </template>

    <div
      v-if="showDetails && showReservationChips"
      class="reservation-chips"
      :style="`max-width: ${maxWidth}px;`"
    >
      <div style="padding: 10px 48px">
        <template v-if="rows.length > previewRows.length && !showAllRows">
          <v-chip
            v-for="selectedRow in previewRows"
            :key="selectedRow.item.managedId"
            class="reservation-chips__reservation-chip"
            close
            small
            text-color="primary"
            color="white"
            @input="remove(selectedRow.item)"
          >
            {{ selectedRow.item.managedId }}
          </v-chip>
          <v-chip
            class="reservation-chips__reservation-chip"
            small
            text-color="white"
            color="primary"
            @click="showAllRows = true"
          >
            +{{ rows.length - previewRows.length }}
          </v-chip>
        </template>
        <template v-else>
          <v-chip
            v-for="selectedRow in rows"
            :key="selectedRow.item.managedId"
            class="reservation-chips__reservation-chip"
            close
            small
            text-color="primary"
            color="white"
            @input="remove(selectedRow.item)"
          >
            {{ selectedRow.item.managedId }}
          </v-chip>
        </template>
      </div>
    </div>
    <div v-if="showDetails && value !== 'offerReferrals'" class="balance">
      <template v-if="value === 'approveQuote'">
        <h4>Total</h4>
        <h1>{{ currencyFilter(quoteTotal) }}</h1>
      </template>
      <template v-else>
        <h4>Balance</h4>
        <h1>{{ currencyFilter(balance) }}</h1>
      </template>
    </div>

    <ContractReservationQuickRefer
      v-if="value === 'offerReferrals'"
      :contract="contract"
      :reservations="rows"
      @close-modal="onCloseDialog"
      @remove-item="remove"
    />

    <CollectPaymentNew
      v-if="value === 'collectPayment' || value === 'approveQuote'"
      :id="`${id}-new-reservation-payments`"
      v-bind="$attrs"
      :row="row"
      :rows="rows"
      :balance="balance"
      :approve-quote="value === 'approveQuote'"
      :quote-id="quoteId"
      :quote-hash="quoteHash"
      :quote-message="quoteMessage"
      @mode="handleMode"
      @close-modal="onCloseDialog"
      @quote-approved="$emit('quote-approved')"
    />
    <AddCharge
      v-if="value === 'addCharge'"
      :id="`${id}-reservation-payments`"
      :row="row"
      :rows="rows"
      :res-detail-version="true"
      @close-modal="onCloseDialog"
    />
    <ReduceCharge
      v-if="value === 'reduceCharge'"
      :id="`${id}-reservation-payments`"
      :row="row"
      :rows="rows"
      :res-detail-version="true"
      @close-modal="onCloseDialog"
    />
    <AddRefund
      v-if="value === 'addRefund'"
      :id="`${id}-reservation-payments`"
      :row="row"
      :rows="rows"
      :res-detail-version="true"
      @close-modal="onCloseDialog"
    />
    <Invoice
      v-if="value === 'invoice' || value === 'customizeEmail'"
      :id="`${id}-new-reservation-invoice`"
      v-bind="$attrs"
      :row="row"
      :rows="rows"
      @mode="handleMode"
      @close-modal="onCloseDialog"
    />
    <ChangeReservationDueDate
      v-if="value === 'changeDueDate'"
      :id="`${id}-reservation-payments`"
      v-bind="$attrs"
      :due-date="dueDate"
      :reservation-id="reservationId"
      :row="row"
      :rows="rows"
      @close-modal="onCloseDialog"
    />
    <ChangeReservationPaymentMethod
      v-if="value === 'changePaymentMethod'"
      :id="`${id}-change-payment-method`"
      v-bind="$attrs"
      :row="row"
      @close-modal="onCloseDialog"
    />
    <PayProvider
      v-if="value === 'payProvider'"
      :id="`${id}-reservation-payments`"
      :row="row"
      :rows="rows"
      @close-modal="onCloseDialog"
    />
  </CRSidebarDialog>
</template>

<script>
import CollectPaymentNew from '@/components/CollectPaymentNew.vue'
import AddCharge from '@/components/AddCharge.vue'
import AddRefund from '@/components/AddRefund.vue'
import Invoice from '@/components/Invoice.vue'
import ReduceCharge from '@/components/ReduceCharge.vue'
import ChangeReservationDueDate from '@/components/ChangeReservationDueDate.vue'
import PayProvider from '@/components/PayProvider.vue'
import ContractReservationQuickRefer from '@/components/ContractReservationQuickRefer.vue'
import ChangeReservationPaymentMethod from '@/components/ChangeReservationPaymentMethod.vue'

import { camelCaseToTitleCase } from '@/utils/string'
import { currencyFilter } from '@/utils/currency'

export default {
  components: {
    CollectPaymentNew,
    Invoice,
    AddCharge,
    AddRefund,
    ReduceCharge,
    ChangeReservationDueDate,
    ChangeReservationPaymentMethod,
    PayProvider,
    ContractReservationQuickRefer,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    row: {
      type: Object,
      default: () => {},
    },
    rows: {
      type: Array,
      default: () => [],
    },
    quoteHash: {
      type: String,
      default: '',
    },
    quoteId: {
      type: Number,
      default: undefined,
    },
    quoteMessage: {
      type: String,
      default: undefined,
    },
    quoteTotal: {
      type: Number,
      default: 0,
    },
    contract: {
      type: Object,
      default: () => {},
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
  },
  data() {
    return {
      show: false,
      showAllRows: false,
    }
  },
  computed: {
    showReservationChips() {
      return this.rows.length
    },
    showDetails() {
      return this.value !== 'customizeEmail'
    },
    previewRows() {
      return this.rows.slice(0, 3)
    },
    balance() {
      let balanceOut = null
      if (this.rows.length) {
        this.rows.forEach((selection) => {
          if (balanceOut) {
            balanceOut = Number(balanceOut) + Number(selection.item.balance)
          } else {
            balanceOut = Number(selection.item.balance)
          }
        })
      } else if (this.row?.balance) {
        balanceOut = this.row.balance
      }
      return Number(balanceOut ? balanceOut.toFixed(2) : (0.004).toFixed(2))
    },
  },
  watch: {
    value() {
      if (this.value && this.value !== 'approveBASQuote') {
        this.show = true
      }
    },
    show() {
      if (!this.show) {
        this.showAllRows = false
        this.$emit('input', '')
      }
    },
    'rows.length'() {
      if (this.rows.length < 1) {
        this.show = false
        this.$emit('input', '')
      }
    },
  },
  mounted() {
    if (this.value) {
      this.show = true
    }
  },
  methods: {
    camelCaseToTitleCase,
    currencyFilter,
    onCloseDialog() {
      this.show = false
      this.showAllRows = false
      this.$emit('row-collapse')
      this.$emit('close-modal')
      this.$emit('refresh')
    },
    handleMode(mode) {
      this.$emit('input', mode)
    },
    remove(item) {
      this.$emit('remove-item', item)
    },
  },
}
</script>

<style lang="scss" scoped>
.reservation-chips {
  display: flex;
  flex-direction: column;
  background: $secondary;
  &__reservation-chip {
    border-radius: 50px;
  }
}

.balance {
  text-align: center;
  margin: 35px 0;
  padding: 0 48px;

  h4 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    color: gray;
    line-height: 1;
    margin: 5px 0;
  }
  h1 {
    font-weight: 800;
    font-size: 32px;
    margin: 0;
  }
}
</style>
