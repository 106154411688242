<template>
  <CRSelect
    :id="`${id}-multi-market-select-market-id`"
    ref="radiusMarket"
    v-model="marketSelections"
    :items="markets"
    chips
    flat
    hide-details
    item-text="marketName"
    item-value="marketId"
    multiple
    solo
    @input="set"
  />
</template>

<script>
import markets from '@/services/markets'
import { authComputed } from '@/state/helpers'

export default {
  props: {
    setFilter: {
      type: Function,
      default: () => {},
    },
    marketInit: {
      type: Array,
      default: () => {
        return []
      },
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      markets: [],
      allMode: false,
      selectAllTemplate: {
        marketId: 'aaa',
        marketName: 'All',
      },
      marketSelections: [],
      marketLocaitionFilter: {
        column: {
          _t_id: '1eb6452a',
          prop: 'nearestMarketId',
          filterType: 'eq',
        },
      },
      activeMarketLocationFilter: undefined,
    }
  },
  computed: {
    ...authComputed,
  },
  watch: {
    marketInit(args) {
      this.marketSelections = [...args]
    },
  },
  async mounted() {
    const marketsData = await markets.tableView({
      pageSize: -1,
    })

    this.markets = [
      this.selectAllTemplate,
      ...(marketsData?.data?.resultList || []),
    ] || [this.selectAllTemplate]

    this.marketSelections = [...this.marketInit]
  },
  methods: {
    toggleAll() {
      if (this.allMode) {
        this.marketSelections = []
        this.allMode = false
        this.setFilter([])
        return
      }

      this.marketSelections = [...this.markets]
      this.setFilter(
        this.marketSelections
          .map((mk) => mk.marketId)
          .filter((i) => i !== 'aaa')
      )
      this.allMode = true
    },
    set(e) {
      const allPresence = e.find((i) => i === 'aaa')

      if (Array.isArray(e)) {
        this.setFilter(e.filter((i) => i !== 'aaa'))

        if (allPresence && !this.allMode) {
          this.toggleAll()
        }

        if (!allPresence && this.allMode) {
          this.toggleAll()
        }
      }
    },
  },
}
</script>
