<template>
  <div>
    <div
      v-for="(classification, index) in classificationsList"
      :key="`classification-selector-autocomplete-classification-select-${index}`"
    >
      <label v-if="index === 0">{{ displayLabel }}</label>
      <label v-else>{{ displaySubLabel }}</label>
      <v-autocomplete
        :id="`classification-selector-autocomplete-classification-select-${index}`"
        v-model="classificationSelections[index]"
        :value="value"
        :disabled="isModeView"
        item-value="classificationId"
        item-text="label"
        solo
        flat
        :items="classification"
        :error-messages="validationError[index]"
        :menu-props="{ closeOnContentClick: true }"
        :loading="loading"
        return-object
        clearable
        append-icon="keyboard_arrow_down"
        placeholder="Classification"
        @click:clear="clearSelectedIndex(index)"
        @change="checkSelectedClassification(index, true)"
      ></v-autocomplete>
    </div>
  </div>
</template>
<script>
import classifications from '@/services/classifications'
import { toCamel } from '@/cr/utils/string'
export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    mode: {
      type: String,
      default: 'edit',
    },
    initialClassification: {
      type: Number,
      default: null,
    },
    classificationRequired: {
      type: Boolean,
    },
    classificationTypeId: {
      type: Number,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    subLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      classificationSelections: [],
      classificationsList: [],
      validationError: [],
      hasSubClassifications: false,
      loading: false,
      classificationTypes: [],
      selectedClassificationType: null,
    }
  },
  computed: {
    isModeView() {
      return this.mode === 'view'
    },
    displayLabel() {
      return this.label ? this.label : 'Classification'
    },
    displaySubLabel() {
      return this.subLabel ? `Sub-${this.subLabel}` : 'Sub-Classification'
    },
  },
  watch: {
    value() {
      this.$emit('input', this.value)
    },
    initialClassification() {
      if (
        this.initialClassification &&
        this.classificationSelections.length === 0
      ) {
        this.populateInitialClassifications()
      }
    },
  },
  async mounted() {
    await this.getClassificationTypes()
    this.selectedClassificationType = this.classificationTypes.find(
      (ct) => ct.classificationTypeId === this.classificationTypeId
    )
    if (!this.selectedClassificationType) {
      return
    }
    this.getAvailableClassifications()
    if (this.initialClassification) {
      this.populateInitialClassifications().then(
        this.$emit('input', this.initialClassification)
      )
    }
  },
  methods: {
    validate() {
      if (
        !this.hasSubClassifications &&
        (this.value || !this.classificationRequired)
      ) {
        return true
      }
      for (let i = 0; i < this.classificationsList.length - 1; i += 1) {
        this.validationError.push('')
      }
      if (this.classificationsList.length === 1) {
        this.validationError.push('Classification Required')
      } else {
        this.validationError.push('Sub Classification Required')
      }
      return false
    },
    clearSelectedIndex(index) {
      this.classificationsList.splice(index + 1)
      this.classificationSelections.splice(index)
      if (index > 0) {
        this.checkSelectedClassification(index - 1)
      }

      this.$emit('input', null)
    },
    async populateInitialClassifications() {
      if (!this.initialClassification) {
        return
      }
      this.loading = true
      const classificationResponse = await classifications[
        this.selectedClassificationType.detailMethod
      ](this.initialClassification)
      let classificationData = classificationResponse.data.classification
      this.classificationSelections.push(classificationData)

      while (classificationData.parentClassificationDTO) {
        this.classificationSelections.unshift(
          classificationData.parentClassificationDTO
        )
        classificationData = classificationData.parentClassificationDTO
      }

      for (const [index] of this.classificationSelections.entries()) {
        await this.checkSelectedClassification(index, false, true)
      }
      this.loading = false
      this.$forceUpdate()
    },
    async getAvailableClassifications() {
      this.loading = true
      const availableParentClassificationsData = await classifications[
        this.selectedClassificationType.getMethod
      ]('').then((data) => data.data)

      let availableParentClassifications =
        availableParentClassificationsData.subClassifications
      availableParentClassifications = availableParentClassifications.filter(
        (classification) => classification.parentClassificationId === null
      )
      this.classificationsList.push(availableParentClassifications)
      this.loading = false
    },
    async getClassificationTypes() {
      const classificationTypesData = await this.$store.dispatch(
        'types/getClassificationTypes'
      )
      this.classificationTypes = classificationTypesData.data.map((type) => {
        return {
          classificationTypeId: type.id,
          getMethod: toCamel(`get${type.label}Classifications`),
          detailMethod: toCamel(`get${type.label}ClassificationDetail`),
        }
      })
    },
    async checkSelectedClassification(
      selectionIndex,
      clearOld = false,
      initialPopulation = false
    ) {
      if (
        clearOld &&
        (this.classificationSelections[selectionIndex + 1] ||
          this.classificationsList[selectionIndex + 1])
      ) {
        this.clearSelectedIndex(selectionIndex + 1)
        return
      }
      const lastSelectedParent = this.classificationSelections[selectionIndex]
      if (!lastSelectedParent?.classificationId) {
        return
      }
      try {
        const subClassificationsResponse = await classifications[
          this.selectedClassificationType.getMethod
        ](lastSelectedParent.classificationId)
        const subClassifications =
          subClassificationsResponse.data?.subClassifications
        if (subClassifications !== null && subClassifications.length !== 0) {
          this.classificationsList[selectionIndex + 1] =
            subClassificationsResponse.data?.subClassifications
          this.hasSubClassifications = true
        } else {
          this.hasSubClassifications = false
        }
      } catch {
        // no sub-classifications found
      }

      if (!initialPopulation) {
        this.$emit(
          'input',
          this.classificationSelections[selectionIndex]?.classificationId
        )
        this.$emit(
          'is-other',
          this.classificationSelections[selectionIndex]?.isOther
        )
      }

      this.validationError = []
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .v-list__tile__title {
  overflow-x: auto;
  height: auto;
  white-space: break-spaces;
}

::v-deep .v-select.v-text-field input {
  text-overflow: ellipsis;
}
</style>
